export default function invalidCharacterValidation(data = {}) {
  // 1. is string
  // 2. is array of string
  // 3. is object recursive
  const result = {};
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      // if e is null/undefined skip
      if (!e) continue;
      else if (typeof e === 'string' && stringValidation(e)) {
        result[key] = 'These #$^{}<>;?\\`~ characters are not allowed.';
      } else if (Array.isArray(e)) {
        let hasError = false;
        // Testing only for array of string
        for (const iterator of e)
          if (typeof iterator === 'string' && stringValidation(iterator))
            hasError = true;
        if (hasError)
          result[key] = 'These #$^{}<>;?\\`~ characters are not allowed.';
      } else if (typeof e === 'object') {
        result[key] = invalidCharacterValidation(e);
      }
    }
  }
  return result;
}

function stringValidation(str) {
  const regEx = /[#$^{}<>;?\\`~]/gi;
  return regEx.test(str);
}
