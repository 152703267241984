import React from 'react';
export default function NoData() {
  return (
    <div className='text-center no-data'>
      <h6 style={{ color: '#babfc8' }} className='no-data'>
        No Data Found !
      </h6>
    </div>
  );
}
