export const TOTAL_REVENUE = 'TOTAL_REVENUE';
export const TOTAL_REVENUE_SUCCESS = 'TOTAL_REVENUE_SUCCESS';
export const TOTAL_REVENUE_ERROR = 'TOTAL_REVENUE_ERROR';

export const TOTAL_COST = 'TOTAL_COST';
export const TOTAL_COST_SUCCESS = 'TOTAL_COST_SUCCESS';
export const TOTAL_COST_ERROR = 'TOTAL_COST_ERROR';

export const TOTAL_EVENTS = 'TOTAL_EVENTS';
export const TOTAL_EVENTS_SUCCESS = 'TOTAL_EVENTS_SUCCESS';
export const TOTAL_EVENTS_ERROR = 'TOTAL_EVENTS_ERROR';


export const TOTAL_FRAUD_COUNTS = 'TOTAL_FRAUD_COUNTS';

export const TOTAL_FRAUD_COUNTS_SUCCESS = 'TOTAL_FRAUD_COUNTS_SUCCESS';

export const TOTAL_FRAUD_COUNTS_ERROR = 'TOTAL_FRAUD_COUNTS_ERROR';



export const TOTAL_FRAUD_PERCENTAGE = 'TOTAL_FRAUD_PERCENTAGE';

export const TOTAL_FRAUD_PERCENTAGE_SUCCESS = 'TOTAL_FRAUD_PERCENTAGE_SUCCESS';

export const TOTAL_FRAUD_PERCENTAGE_ERROR = 'TOTAL_FRAUD_PERCENTAGE_ERROR';

export const TOTAL_CLICKS = 'TOTAL_CLICKS';
export const TOTAL_CLICKS_SUCCESS = 'TOTAL_CLICKS_SUCCESS';
export const TOTAL_CLICKS_ERROR = 'TOTAL_CLICKS_ERROR';

export const TOTAL_PUBEVENTS = 'TOTAL_PUBEVENTS';
export const TOTAL_PUBEVENTS_SUCCESS = 'TOTAL_PUBEVENTS_SUCCESS';
export const TOTAL_PUBEVENTS_ERROR = 'TOTAL_PUBEVENTS_ERROR';

export const TOTAL_PUBCLICKS = 'TOTAL_PUBCLICKS';
export const TOTAL_PUBCLICKS_SUCCESS = 'TOTAL_PUBCLICKS_SUCCESS';
export const TOTAL_PUBCLICKS_ERROR = 'TOTAL_PUBCLICKS_ERROR';

export const TOTAL_OVERPUBCLICKS = 'TOTAL_OVERPUBCLICKS';
export const TOTAL_OVERPUBCLICKS_SUCCESS = 'TOTAL_OVERPUBCLICKS_SUCCESS';
export const TOTAL_OVERPUBCLICKS_ERROR = 'TOTAL_OVERPUBCLICKS_ERROR';

export const TOTAL_FRAUDPUBCLICKS = 'TOTAL_FRAUDPUBCLICKS';
export const TOTAL_FRAUDPUBCLICKS_SUCCESS = 'TOTAL_FRAUDPUBCLICKS_SUCCESS';
export const TOTAL_FRAUDPUBCLICKS_ERROR = 'TOTAL_FRAUDPUBCLICKS_ERROR';

export const TOTAL_EVENTPUBCLICKS = 'TOTAL_EVENTPUBCLICKS';
export const TOTAL_EVENTPUBCLICKS_SUCCESS = 'TOTAL_EVENTPUBCLICKS_SUCCESS';
export const TOTAL_EVENTPUBCLICKS_ERROR = 'TOTAL_EVENTPUBCLICKS_ERROR';

export const MTRACKREPORT_TOP_PUBLISHER_INIT = 'MTRACKREPORT_TOP_PUBLISHER_INIT';
export const MTRACKREPORT_TOP_PUBLISHER_SUCCESS = 'MTRACKREPORT_TOP_PUBLISHER_SUCCESS';
export const MTRACKREPORT_TOP_PUBLISHER_ERROR = 'MTRACKREPORT_TOP_PUBLISHER_ERROR';


