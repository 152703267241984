const USERMANAGEMENT = Object.freeze({
  GET_USER_INIT: 'GET_USER_INIT',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_ERROR: 'GET_USER_ERROR',
  //
  GET_ONE_USER_INIT: 'GET_ONE_USER_INIT',
  GET_ONE_USER_SUCCESS: 'GET_ONE_USER_SUCCESS',
  GET_ONE_USER_ERROR: 'GET_ONE_USER_ERROR',
  //
  ADD_USER_INIT: 'ADD_USER_INIT',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_ERROR: 'ADD_USER_ERROR',
  //
  EDIT_USER_INIT: 'EDIT_USER_INIT',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_ERROR: 'EDIT_USER_ERROR',
  //
  DELETE_USER_INIT: 'DELETE_USER_INIT',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  //
  //USER PACKAGE MAPPING GET POST ADD DELETE

  GET_ALL_USER_INIT: 'GET_USER_INIT',
  GET_ALL_USER_INIT_SUCCESS: 'GET_USER_INIT_SUCCESS',
  GET_ALL_USER_INIT_ERROR: 'GET_USER_INIT_ERROR',

  USERMAPPING_DETAILS_INIT: 'USERMAPPING_DETAILS_INIT',
  USERMAPPING_DETAILS_INIT_SUCCESS: 'USERMAPPING_DETAILS_INIT_SUCCESS',
  USERMAPPING_DETAILS_INIT_ERROR: 'USERMAPPING_DETAILS_INIT_ERROR',

  USERMAPPING_SAVE_INIT: 'USERMAPPING_SAVE_INIT',
  USERMAPPING_SAVE_INIT_SUCCESS: 'USERMAPPING_SAVE_INIT_SUCCESS',
  USERMAPPING_SAVE_INIT_ERROR: 'USERMAPPING_SAVE_INIT_ERROR',

  USERMAPPING_UPDATE_INIT: 'USERMAPPING_UPDATE_INIT',
  USERMAPPING_UPDATE_INIT_SUCCESS: 'USERMAPPING_UPDATE_INIT_SUCCESS',
  USERMAPPING_UPDATE_INIT_ERROR: 'USERMAPPING_UPDATE_INIT_ERROR',
  USERMAPPING_UPDATE_INIT_CLEAR: 'USERMAPPING_UPDATE_INIT_CLEAR',

  USERMAPPING_DELETE_INIT: 'USERMAPPING_DELETE_INIT',
  USERMAPPING_DELETE_INIT_SUCCESS: 'USERMAPPING_DELETE_INIT_SUCCESS',
  USERMAPPING_DELETE_INIT_ERROR: 'USERMAPPING_DELETE_INIT_ERROR',

  // ADD PAGE

  GET_MENU_SELECTION_INIT: 'GET_MENU_SELECTION_INIT',
  GET_MENU_SELECTION_INIT_SUCCESS: 'GET_MENU_SELECTION_INIT_SUCCESS',
  GET_MENU_SELECTION_INIT_ERROR: 'GET_MENU_SELECTION_INIT_ERROR',

  GET_FILTER_SELECTION_INIT: 'GET_FILTER_SELECTION_INIT',
  GET_FILTER_SELECTION_INIT_SUCCESS: 'GET_FILTER_SELECTION_INIT_SUCCESS',
  GET_FILTER_SELECTION_INIT_ERROR: 'GET_FILTER_SELECTION_INIT_ERROR',

  USER_PACKAGE_DROPDOWN_INIT: 'USER_PACKAGE_DROPDOWN_INIT',
  USER_PACKAGE_DROPDOWN_INIT_SUCCESS: 'USER_PACKAGE_DROPDOWN_INIT_SUCCESS',
  USER_PACKAGE_DROPDOWN_INIT_ERROR: 'USER_PACKAGE_DROPDOWN_INIT_ERROR',

  USER_GETONEAPI_INIT: 'USER_GETONEAPI_INIT',
  USER_GETONEAPI_INIT_SUCCESS: 'USER_GETONEAPI_INIT_SUCCESS',
  USER_GETONEAPI_INIT_ERROR: 'USER_GETONEAPI_INIT_ERROR',
  USER_GETONEAPI_INIT_CLEAR: 'USER_GETONEAPI_INIT_CLEAR',
});

export default USERMANAGEMENT;
