import { combineReducers } from 'redux';
import Reducer from '../../../Utils/Reducer';
//
import { PACKAGECONST, USER_MANAGEMENT, UPM } from './';

export const UserManagementReducer = combineReducers({
  packageManagement: (state = {}, action) =>
    Reducer(PACKAGECONST[action?.id], state, action),
  user: (state = {}, action) =>
    Reducer(USER_MANAGEMENT[action?.id], state, action),
  UPM: (state = {}, action) => Reducer(UPM[action?.id], state, action),
});
