export const EVENT_COUNT_LIST_INIT = 'EVENT_COUNT_LIST_INIT';
export const EVENT_COUNT_LIST_INIT_SUCCESS = 'EVENT_COUNT_LIST_INIT_SUCCESS';
export const EVENT_COUNT_LIST_INIT_ERROR = 'EVENT_COUNT_LIST_INIT_ERROR';

export const EVENT_FRAUD_CATEGORY_INIT = 'EVENT_FRAUD_CATEGORY_INIT';
export const EVENT_FRAUD_CATEGORY_INIT_SUCCESS =
  'EVENT_FRAUD_CATEGORY_INIT_SUCCESS';
export const EVENT_FRAUD_CATEGORY_INIT_ERROR = 'EVENT_FRAUD_CATEGORY_ERROR';

export const EVENT_CLEAN_PUBLISHER_NAME_INIT =
  'EVENT_CLEAN_PUBLISHER_NAME_INIT';
export const EVENT_CLEAN_PUBLISHER_NAME_INIT_SUCCESS =
  'EVENT_CLEAN_PUBLISHER_NAME_INIT_SUCCESS';
export const EVENT_CLEAN_PUBLISHER_NAME_INIT_ERROR =
  'EVENT_CLEAN_PUBLISHER_NAME_INIT_ERROR';

export const EVENT_FRAUD_PUBLISHER_NAME_INIT =
  'EVENT_FRAUD_PUBLISHER_NAME_INIT';
export const EVENT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS =
  'EVENT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS';
export const EVENT_FRAUD_PUBLISHER_NAME_INIT_ERROR =
  'EVENT_FRAUD_PUBLISHER_NAME_INIT_ERROR';

export const FRAUD_EVENT_BY_MONTH_INIT = 'FRAUD_EVENT_BY_MONTH_INIT';
export const FRAUD_EVENT_BY_MONTH_INIT_SUCCESS =
  'FRAUD_EVENT_BY_MONTH_INIT_SUCCESS';
export const FRAUD_EVENT_BY_MONTH_INIT_ERROR =
  'FRAUD_EVENT_BY_MONTH_INIT_ERROR';

export const FRAUD_EVENT_BY_DATE_INIT = 'FRAUD_EVENT_BY_DATE_INIT';
export const FRAUD_EVENT_BY_DATE_INIT_SUCCESS =
  'FRAUD_EVENT_BY_DATE_INIT_SUCCESS';
export const FRAUD_EVENT_BY_DATE_INIT_ERROR = 'FRAUD_EVENT_BY_DATE_INIT_ERROR';

export const FRAUD_PUBLISHER_WISE = 'FRAUD_EVENT_PUBLISHER_WISE';
export const FRAUD_PUBLISHER_WISE_SUCCESS =
  'FRAUD_EVENT_PUBLISHER_WISE_SUCCESS';
export const FRAUD_PUBLISHER_WISE_ERROR = 'FRAUD_EVENT_PUBLISHER_WISE_ERROR';
