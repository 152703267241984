import CONST from '../constants/ExternalEmbededConst';

const init = { loading: false, data: null, error: null };

export default function EXternalEmbededReducer(
  state = init,
  { type, response, error }
) {
  switch (type) {
    case CONST.INIT:
      return { loading: true, data: null, error: null };
    case CONST.ERROR:
      return { loading: false, data: null, error: error };
    case CONST.SUCCESS:
      return { loading: false, data: response, error: null };
    default:
      return state;
  }
}
