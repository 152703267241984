import React, { useMemo, useEffect } from 'react';
import objectPath from 'object-path';
import { useLocation } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import * as constant from '../../../../redux/constants/DashboardConstants';
import { useDispatch } from 'react-redux';
import { QuickUserToggler } from '../extras/QuiclUserToggler';
import { useSubheader } from '../../_core/MetronicSubheader';
import { setLocalStorage } from '../../../../app/utils/helpers';
import { FaFilter } from 'react-icons/fa';
import DATES_ENABLED_ROUTES from '../../../../config/date_filter.json';
import FILTER_ENABLED_ROUTES from '../../../../config/filter.json';

import DatePickerCustom from '../../../../app/views/app/shared-componets/DatePicker';
import { color } from '@material-ui/system';

export function Topbar() {
  const dispatch = useDispatch();
  const uiService = useHtmlClassService();
  const subheader = useSubheader();
  const location = useLocation();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        'extras.search.display'
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        'extras.notifications.display'
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        'extras.quick-actions.display'
      ),
      viewCartDisplay: objectPath.get(uiService.config, 'extras.cart.display'),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        'extras.quick-panel.display'
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        'extras.languages.display'
      ),
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        'aside.self.minimize.toggle'
      ),
    };
  }, [uiService]);

  useEffect(() => {
    if (location.pathname === '/incident-dashboard') {
      subheader.setTitle('Dashboard');
    }
    if (location.pathname === '/impression-dashboard') {
      subheader.setTitle('Impression Dashboard');
    }
    if (location.pathname === '/visit-dashboard') {
      subheader.setTitle('Visit Dashboard');
    }
    if (location.pathname === '/event-dashboard') {
      subheader.setTitle('Event Dashboard');
    }

    if (location.pathname === '/white-listing') {
      subheader.setTitle('White Listing');
    }

    if (location.pathname === '/install-dashboard') {
      subheader.setTitle('Install Dashboard');
    }

    if (location.pathname === '/app-reengagement-event-dashboard') {
      subheader.setTitle('Reengagement Event');
    }

    if (location.pathname === '/publisher-summary') {
      subheader.setTitle('Publisher Summary');
    }
    if (location.pathname === '/app/publisher-summary') {
      subheader.setTitle('Publisher Summary');
    }
    if (location.pathname === '/event-publisher-summary') {
      subheader.setTitle('Publisher Summary');
    }

    if (location.pathname === '/add-white-listing') {
      subheader.setTitle('White Listing Rule');
    }

    if (location.pathname === '/app-event-dashboard') {
      subheader.setTitle('Event Dashboard');
    }
    if (location.pathname === '/webfraud-custom-report') {
      subheader.setTitle('Event Report');
    }
    if (location.pathname === '/web/publisher-report') {
      subheader.setTitle('Publisher Configuration ');
    }
    if (location.pathname === '/click-dashboard') {
      subheader.setTitle('Click Dashboard');
    }
    if (location.pathname === '/report') {
      subheader.setTitle('Report');
    }
    if (location.pathname === '/app_summary_report') {
      subheader.setTitle('App Summary Report');
    }

    if (location.pathname === '/issues') {
      subheader.setTitle('Tickets');
    }
    if (location.pathname === '/bs-dashboard') {
      subheader.setTitle('Brand Safety Dashboard');
    }
    if (location.pathname === '/event-report') {
      subheader.setTitle('Event Report');
    }
    if (location.pathname === '/add-configuration') {
      subheader.setTitle('Add Configuration');
    }
    if (location.pathname === '/mtrackit_campaigns') {
      subheader.setTitle('Campaigns');
    }

    if (location.pathname === ' /adword_competitor') {
      subheader.setTitle('Adword Affiliate');
    }

    if (location.pathname === '/adword_affiliate') {
      subheader.setTitle('Adword Competitor');
    }
    if (location.pathname === '/coupons') {
      subheader.setTitle('Coupons');
    }
    if (location.pathname === '/external-embeded') {
      subheader.setTitle('');
    }
    if (location.pathname === '/alert-page') {
      subheader.setTitle('Alert');
    }
    if (location.pathname === '/app/alert-page') {
      subheader.setTitle('Alert');
    }
    if (location.pathname === '/brand-relevancy/brief/add') {
      subheader.setTitle('Add Requirement');
    }
    if (location.pathname === '/brand-relevancy/brief-config') {
      subheader.setTitle('Requirement');
    }

    let temp_time = new Date();
    // temp_time.setMinutes(temp_time.getMinutes() + 1);
    temp_time.setHours(temp_time.getHours() + 3);
    setLocalStorage('LOGIN_TIME', new Date());
    setLocalStorage('EXPIRED_TIME', temp_time);
  }, []);

  let handleFullScreenMode = () => {
    let screen = document.getElementById('embeddedDashboard');
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (screen && screen.requestFullscreen) {
        screen.requestFullscreen();
      } else if (screen && screen.msRequestFullscreen) {
        screen.msRequestFullscreen();
      } else if (screen && screen.mozRequestFullScreen) {
        screen.mozRequestFullScreen();
      } else if (screen && screen.webkitRequestFullscreen) {
        screen.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

  return (
    <div className='col-lg-12'>
      <div className='topbar'>
        <div className='col-lg-3'>
          <div className='d-flex justify-content-start flex-start'>
            <h5 className='text-dark font-weight-bold my-7 mr-5'>
              <>
              {subheader.title}
              </>
            </h5>
          </div>
        </div>
        <div className='col-6'>
          <div className='ml-20 w-50 d-flex justify-content-end flex-end my-3'>
            {Object.keys(DATES_ENABLED_ROUTES).includes(location.pathname) && (
              <DatePickerCustom
                blockFor={DATES_ENABLED_ROUTES[location.pathname].block}
                lockFor={DATES_ENABLED_ROUTES[location.pathname].lock}
              />
            )}
          </div>
        </div>
        <div className='col-lg-3'>
          <div className='d-flex justify-content-end flex-end my-3'>
            {[
              '/external-embeded',
              '/click-integrity/dashboard',
              '/impression-integrity/dashboard',
            ].includes(location.pathname) && (
              <span className='topbar-item mr-5' onClick={handleFullScreenMode}>
                <i
                  class='fa-solid fa-up-right-and-down-left-from-center fa-2xl'
                  style={{ color: '#A21094', cursor: 'pointer' }}></i>
              </span>
            )}
            {layoutProps.viewQuickPanelDisplay &&
              (FILTER_ENABLED_ROUTES.includes(location.pathname) ? (
                <OverlayTrigger
                  placement='bottom'
                  overlay={<Tooltip id='quick-panel-tooltip'>Filter</Tooltip>}>
                  <div
                    className='topbar-item mr-2'
                    data-toggle='tooltip'
                    title='Filter'
                    data-placement='right'>
                    {layoutProps.asideSelfMinimizeToggle && (
                      <div className='btn btn-icon btn-clean btn-lg mr-1'>
                        <button
                          className='brand-toggle btn btn-sm px-0'
                          onClick={() => {
                            dispatch({
                              type: constant.SET_SIDE_DRAWER,
                              setSideDrawer: true,
                            });
                          }}>
                          <span className='svg-icon svg-icon-xl svg-icon-primary'>
                            <FaFilter color='#A21094' />
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                </OverlayTrigger>
              ) : (
                ''
              ))}
            {layoutProps.viewUserDisplay && <QuickUserToggler />}
          </div>
        </div>
      </div>
    </div>
  );
}
