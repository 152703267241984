export const PACKAGENAME_INIT = 'PACKAGENAME_INIT';
export const PACKAGENAME_INIT_SUCCESS = 'PACKAGENAME_INIT_SUCCESS';
export const PACKAGENAME_INIT_ERROR = 'PACKAGENAME_INIT_ERROR';

export const MENULIST_INIT = 'MENULIST_INIT';
export const MENULIST_INIT_SUCCESS = 'MENULIST_INIT_SUCCESS';
export const MENULIST_INIT_ERROR = 'MENULIST_INIT_ERROR';

export const FILTERLIST_INIT = 'FILTERLIST_INIT';
export const FILTERLIST_INIT_SUCCESS = 'FILTERLIST_INIT_SUCCESS';
export const FILTERLIST_INIT_ERROR = 'FILTERLIST_INIT_ERROR';

export const CHANNEL_LIST_INIT = 'CHANNEL_LIST_INIT';
export const CHANNEL_LIST_INIT_SUCCESS = 'CHANNEL_LIST_INIT_SUCCESS';
export const CHANNEL_LIST_INIT_ERROR = 'CHANNEL_LIST_INIT_ERROR';

export const COUNTRY_LIST_INIT = 'COUNTRY_LIST_INIT';
export const COUNTRY_LIST_INIT_SUCCESS = 'COUNTRY_LIST_INIT_SUCCESS';
export const COUNTRY_LIST_INIT_ERROR = 'COUNTRY_LIST_INIT_ERROR';
//
export const CATEGORY_LIST_INIT = 'CATEGORY_LIST_INIT';
export const CATEGORY_LIST_INIT_SUCCESS = 'CATEGORY_LIST_INIT_SUCCESS';
export const CATEGORY_LIST_INIT_ERROR = 'CATEGORY_LIST_INIT_ERROR';
//
export const BRAND_LIST_INIT = 'BRAND_LIST_INIT';
export const BRAND_LIST_INIT_SUCCESS = 'BRAND_LIST_INIT_SUCCESS';
export const BRAND_LIST_INIT_ERROR = 'BRAND_LIST_INIT_ERROR';
//
export const PRIORITY_LIST_INIT = 'PRIORITY_LIST_INIT';
export const PRIORITY_LIST_INIT_SUCCESS = 'PRIORITY_LIST_INIT_SUCCESS';
export const PRIORITY_LIST_INIT_ERROR = 'PRIORITY_LIST_INIT_ERROR';
//
export const PUBLISHER_LIST_INIT = 'PUBLISHER_LIST_INIT';
export const PUBLISHER_LIST_INIT_SUCCESS = 'PUBLISHER_LIST_INIT_SUCCESS';
export const PUBLISHER_LIST_INIT_ERROR = 'PUBLISHER_LIST_INIT_ERROR';
//
export const STATUS_LIST_INIT = 'STATUS_LIST_INIT';
export const STATUS_LIST_INIT_SUCCESS = 'STATUS_LIST_INIT_SUCCESS';
export const STATUS_LIST_INIT_ERROR = 'STATUS_LIST_INIT_ERROR';
//

export const SET_GLOBAL_PACKAGE_NAME = 'SET_GLOBAL_PACKAGE_NAME';
export const SET_GLOBAL_REFRESH_RENDERING = 'SET_GLOBAL_REFRESH_RENDERING';

//web
export const WEB_PUBLISHER_ID_LIST_INIT = 'WEB_PUBLISHER_ID_LIST_INIT';
export const WEB_PUBLISHER_ID_LIST_INIT_SUCCESS =
  'WEB_PUBLISHER_ID_LIST_INIT_SUCCESS';
export const WEB_PUBLISHER_ID_LIST_INIT_ERROR =
  'WEB_PUBLISHER_ID_LIST_INIT_ERROR';

export const WEB_CHANNEL_LIST_INIT = 'WEB_CHANNEL_LIST_INIT';
export const WEB_CHANNEL_LIST_INIT_SUCCESS = 'WEB_CHANNEL_LIST_INIT_SUCCESS';
export const WEB_CHANNEL_LIST_INIT_ERROR = 'WEB_CHANNEL_LIST_INIT_ERROR';

export const WEB_CAMPAIGN_LIST_INIT = 'WEB_CAMPAIGN_LIST_INIT';
export const WEB_CAMPAIGN_LIST_INIT_SUCCESS = 'WEB_CAMPAIGN_LIST_INIT_SUCCESS';
export const WEB_CAMPAIGN_LIST_INIT_ERROR = 'WEB_CAMPAIGN_LIST_INIT_ERROR';

export const WEB_CREATIVE_LIST_INIT = 'WEB_CREATIVE_LIST_INIT';
export const WEB_CREATIVE_LIST_INIT_SUCCESS = 'WEB_CREATIVE_LIST_INIT_SUCCESS';
export const WEB_CREATIVE_LIST_INIT_ERROR = 'WEB_CREATIVE_LIST_INIT_ERROR';

export const WEB_SYSTEM_DOMAIN_LIST_INIT = 'WEB_SYSTEM_DOMAIN_LIST_INIT';
export const WEB_SYSTEM_DOMAIN_LIST_INIT_SUCCESS =
  'WEB_SYSTEM_DOMAIN_LIST_INIT_SUCCESS';
export const WEB_SYSTEM_DOMAIN_LIST_INIT_ERROR =
  'WEB_SYSTEM_DOMAIN_LIST_INIT_ERROR';

export const WEB_PLACEMENT_ID_LIST_INIT = 'WEB_PLACEMENT_ID_LIST_INIT';
export const WEB_PLACEMENT_ID_LIST_INIT_SUCCESS =
  'WEB_PLACEMENT_ID_LIST_INIT_SUCCESS';
export const WEB_PLACEMENT_ID_LIST_INIT_ERROR =
  'WEB_PLACEMENT_ID_LIST_INIT_ERROR';

export const WEB_FRAUD_CATEGORY_LIST_INIT = 'WEB_FRAUD_CATEGORY_LIST_INIT';
export const WEB_FRAUD_CATEGORY_LIST_INIT_SUCCESS =
  'WEB_FRAUD_CATEGORY_LIST_INIT_SUCCESS';
export const WEB_FRAUD_CATEGORY_LIST_INIT_ERROR =
  'WEB_FRAUD_CATEGORY_LIST_INIT_ERROR';

export const WEB_FRAUD_SUB_CATEGORY_LIST_INIT =
  'WEB_FRAUD_SUB_CATEGORY_LIST_INIT';
export const WEB_FRAUD_SUB_CATEGORY_LIST_INIT_SUCCESS =
  'WEB_FRAUD_SUB_CATEGORY_LIST_INIT_SUCCESS';
export const WEB_FRAUD_SUB_CATEGORY_LIST_INIT_ERROR =
  'WEB_FRAUD_SUB_CATEGORY_LIST_INIT_ERROR';

export const WEB_PLACEMENT_TYPE_LIST_INIT = 'WEB_PLACEMENT_TYPE_LIST_INIT';
export const WEB_PLACEMENT_TYPE_LIST_INIT_SUCCESS =
  'WEB_PLACEMENT_TYPE_LIST_INIT_SUCCESS';
export const WEB_PLACEMENT_TYPE_LIST_INIT_ERROR =
  'WEB_PLACEMENT_TYPE_LIST_INIT_ERROR';

//click

export const CLICK_FRAUD_CATEGORY_INIT = 'CLICK_FRAUD_CATEGORY_INIT';
export const CLICK_FRAUD_CATEGORY_INIT_SUCCESS =
  'CLICK_FRAUD_CATEGORY_INIT_SUCCESS';
export const CLICK_FRAUD_CATEGORY_INIT_ERROR =
  'CLICK_FRAUD_CATEGORY_INIT_ERROR';

export const CLICK_FRAUD_SUB_CATEGORY_INIT = 'CLICK_FRAUD_SUB_CATEGORY_INIT';
export const CLICK_FRAUD_SUB_CATEGORY_INIT_SUCCESS =
  'CLICK_FRAUD_SUB_CATEGORY_INIT_SUCCESS';
export const CLICK_FRAUD_SUB_CATEGORY_INIT_ERROR =
  'CLICK_FRAUD_SUB_CATEGORY_INIT_ERROR';

export const CLICK_PUBLISHER_NAME_INIT = 'CLICK_PUBLISHER_NAME_INIT';
export const CLICK_PUBLISHER_NAME_INIT_SUCCESS =
  'CLICK_PUBLISHER_NAME_INIT_SUCCESS';
export const CLICK_PUBLISHER_NAME_INIT_ERROR =
  'CLICK_PUBLISHER_NAME_INIT_ERROR';

export const CLICK_CAMPAIGN_ID_INIT = 'CLICK_CAMPAIGN_ID_INIT';
export const CLICK_CAMPAIGN_ID_INIT_SUCCESS = 'CLICK_CAMPAIGN_ID_INIT_SUCCESS';
export const CLICK_CAMPAIGN_ID_INIT_ERROR = 'CLICK_CAMPAIGN_ID_INIT_ERROR';

export const CLICK_DEVICE_TYPE_INIT = 'CLICK_DEVICE_TYPE_INIT';
export const CLICK_DEVICE_TYPE_INIT_SUCCESS = 'CLICK_DEVICE_TYPE_INIT_SUCCESS';
export const CLICK_DEVICE_TYPE_INIT_ERROR = 'CLICK_DEVICE_TYPE_INIT_ERROR';

// dashboard_type

export const WEB_DASHBOARD_TYPE_LIST_INIT = 'WEB_DASHBOARD_TYPE_LIST_INIT';
export const WEB_DASHBOARD_TYPE_LIST_INIT_SUCCESS =
  'WEB_DASHBOARD_TYPE_LIST_INIT_SUCCESS';
export const WEB_DASHBOARD_TYPE_LIST_INIT_ERROR =
  'WEB_DASHBOARD_TYPE_LIST_INIT_ERROR';

//event

export const WEB_SET_DASHBOARD_TYPE_LIST_INIT =
  'WEB_SET_DASHBOARD_TYPE_LIST_INIT';
export const WEB_SET_DASHBOARD_TYPE_LIST_INIT_SUCCESS =
  'WEB_SET_DASHBOARD_TYPE_LIST_INIT_SUCCESS';
export const WEB_SET_DASHBOARD_TYPE_LIST_INIT_ERROR =
  'WEB_SET_DASHBOARD_TYPE_LIST_INIT_ERROR';

export const WEB_PUBLISHER_LIST_INIT = 'WEB_PUBLISHER_LIST_INIT';
export const WEB_PUBLISHER_LIST_INIT_SUCCESS =
  'WEB_PUBLISHER_LIST_INIT_SUCCESS';
export const WEB_PUBLISHER_LIST_INIT_ERROR = 'WEB_PUBLISHER_LIST_INIT_ERROR';

export const WEB_SUB_PUBLISHER_LIST_INIT = 'WEB_SUB_PUBLISHER_LIST_INIT';
export const WEB_SUB_PUBLISHER_LIST_INIT_SUCCESS =
  'WEB_SUB_PUBLISHER_LIST_INIT_SUCCESS';
export const WEB_SUB_PUBLISHER_LIST_INIT_ERROR =
  'WEB_SUB_PUBLISHER_LIST_INIT_ERROR';

export const WEB_PAGE_ID_LIST_INIT = 'WEB_PAGE_ID_LIST_INIT';
export const WEB_PAGE_ID_LIST_INIT_SUCCESS = 'WEB_PAGE_ID_LIST_INIT_SUCCESS';
export const WEB_PAGE_ID_LIST_INIT_ERROR = 'WEB_PAGE_ID_LIST_INIT_ERROR';

//Mtrackit dashboard Filters
export const CAMPAIGN_LIST_INIT = 'CAMPAIGN_LIST_INIT';
export const CAMPAIGN_LIST_INIT_SUCCESS = 'CAMPAIGN_LIST_INIT_SUCCESS';
export const CAMPAIGN_LIST_INIT_ERROR = 'CAMPAIGN_LIST_INIT_ERROR';

export const EVENT_LIST_INIT = 'EVENT_LIST_INIT';
export const EVENT_LIST_INIT_SUCCESS = 'EVENT_LIST_INIT_SUCCESS';
export const EVENT_LIST_INIT_ERROR = 'EVENT_LIST_INIT_ERROR';

export const PUBLISHER_NAME_INIT = 'PUBLISHER_NAME_INIT';
export const PUBLISHER_NAME_INIT_SUCCESS = 'PUBLISHER_NAME_INIT_SUCCESS';
export const PUBLISHER_NAME_INIT_ERROR = 'PUBLISHER_NAME_INIT_ERROR';

export const FRAUD_LIST_INIT = 'FRAUD_LIST_INIT';
export const FRAUD_LIST_INIT_SUCCESS = 'FRAUD_LIST_INIT_SUCCESS';
export const FRAUD_LIST_INIT_ERROR = 'FRAUD_LIST_INIT_ERROR';

//Event Dashboard Filters

export const EVENT_CAMPAIGN_LIST_INIT = 'EVENT_CAMPAIGN_LIST_INIT';
export const EVENT_CAMPAIGN_LIST_INIT_SUCCESS =
  'EVENT_CAMPAIGN_LIST_INIT_SUCCESS';
export const EVENT_CAMPAIGN_LIST_INIT_ERROR = 'EVENT_CAMPAIGN_LIST_INIT_ERROR';

export const EVENT_PAGE_LIST_INIT = 'EVENT_PAGE_LIST_INIT';
export const EVENT_PAGE_LIST_INIT_SUCCESS = 'EVENT_PAGE_LIST_INIT_SUCCESS';
export const EVENT_PAGE_LIST_INIT_ERROR = 'EVENT_PAGE_LIST_INIT_ERROR';

export const EVENT_PUBLISHER_LIST_INIT = 'EVENT_PUBLISHER_LIST_INIT';
export const EVENT_PUBLISHER_LIST_INIT_SUCCESS =
  'EVENT_PUBLISHER_LIST_INIT_SUCCESS';
export const EVENT_PUBLISHER_LIST_INIT_ERROR =
  'EVENT_PUBLISHER_LIST_INIT_ERROR';

export const EVENT_CHANNEL_LIST_INIT = 'EVENT_CHANNEL_LIST_INIT';
export const EVENT_CHANNEL_LIST_INIT_SUCCESS =
  'EVENT_CHANNEL_LIST_INIT_SUCCESS';
export const EVENT_CHANNEL_LIST_INIT_ERROR = 'EVENT_CHANNEL_LIST_INIT_ERROR';

export const EVENT_EVENT_LIST_INIT = 'EVENT_EVENT_LIST_INIT';
export const EVENT_EVENT_LIST_INIT_SUCCESS = 'EVENT_EVENT_LIST_INIT_SUCCESS';
export const EVENT_EVENT_LIST_INIT_ERROR = 'EVENT_EVENT_LIST_INIT_ERROR';

// APP EVENT REPORT PAGE

export const APP_EVENT_REPORT_LIST_INIT = 'APP_EVENT_REPORT_LIST_INIT';
export const APP_EVENT_REPORT_LIST_INIT_SUCCESS =
  'APP_EVENT_REPORT_LIST_INIT_SUCCESS';
export const APP_EVENT_REPORT_LIST_INIT_ERROR =
  'APP_EVENT_REPORT_LIST_INIT_ERROR';
export const APP_EVENT_REPORT_REFETCH = 'APP_EVENT_REPORT_REFETCH';

export const APP_INSTALL_REPORT_LIST_INIT = 'APP_INSTALL_REPORT_LIST_INIT';
export const APP_INSTALL_REPORT_LIST_INIT_SUCCESS =
  'APP_INSTALL_REPORT_LIST_INIT_SUCCESS';
export const APP_INSTALL_REPORT_LIST_INIT_ERROR =
  'APP_INSTALL_REPORT_LIST_INIT_ERROR';
export const APP_INSTALL_REPORT_REFETCH = 'APP_INSTALL_REPORT_REFETCH';

//app genrate report pages

export const APP_EVENT_GENERATE_REPORT_INIT = 'APP_EVENT_GENERATE_REPORT_INIT';
export const APP_EVENT_GENERATE_REPORT_INIT_SUCCESS =
  'APP_EVENT_GENERATE_REPORT_INIT_SUCCESS';
export const APP_EVENT_GENERATE_REPORT_INIT_ERROR =
  'APP_EVENT_GENERATE_REPORT_INIT_ERROR';
export const APP_EVENT_GENERATE_REPORT_INIT_CLEAR =
  'APP_EVENT_GENERATE_REPORT_INIT_CLEAR';

export const INSTALL_GENERATE_REPORT_INIT = 'INSTALL_GENERATE_REPORT_INIT';
export const INSTALL_GENERATE_REPORT_INIT_SUCCESS =
  'INSTALL_GENERATE_REPORT_INIT_SUCCESS';
export const INSTALL_GENERATE_REPORT_INIT_ERROR =
  'INSTALL_GENERATE_REPORT_INIT_ERROR';
export const INSTALL_GENERATE_REPORT_INIT_CLEAR =
  'INSTALL_GENERATE_REPORT_INIT_CLEAR';

//event report

export const WEB_EVENT_REPORT_LIST_INIT = 'WEB_EVENT_REPORT_LIST_INIT';
export const WEB_EVENT_REPORT_LIST_INIT_SUCCESS =
  'WEB_EVENT_REPORT_LIST_INIT_SUCCESS';
export const WEB_EVENT_REPORT_LIST_INIT_ERROR =
  'WEB_EVENT_REPORT_LIST_INIT_ERROR';
export const WEB_EVENT_REPORT_REFETCH = 'WEB_EVENT_REPORT_REFETCH';

//event report page const

export const EVENT_REPORT_FEILDS_lIST_INIT = 'EVENT_REPORT_FEILDS_lIST_INIT';
export const EVENT_REPORT_FEILDS_lIST_INIT_SUCCESS =
  'EVENT_REPORT_FEILDS_lIST_INIT_SUCCESS';
export const EVENT_REPORT_FEILDS_lIST_INIT_ERROR =
  'EVENT_REPORT_FEILDS_lIST_INIT_ERROR';

export const EVENT_REPORT_PUBLISHER_lIST_INIT =
  'EVENT_REPORT_PUBLISHER_lIST_INIT';
export const EVENT_REPORT_PUBLISHER_lIST_INIT_SUCCESS =
  'EVENT_REPORT_PUBLISHER_lIST_INIT_SUCCESS';
export const EVENT_REPORT_PUBLISHER_lIST_INIT_ERROR =
  'EVENT_REPORT_PUBLISHER_lIST_INIT_ERROR';

export const EVENT_REPORT_FRAUD_CATEGORY_lIST_INIT =
  'EVENT_REPORT_FRAUD_CATEGORY_lIST_INIT';
export const EVENT_REPORT_FRAUD_CATEGORY_lIST_SUCCESS =
  'EVENT_REPORT_FRAUD_CATEGORY_lIST_SUCCESS';
export const EVENT_REPORT_FRAUD_CATEGORY_lIST_ERROR =
  'EVENT_REPORT_FRAUD_CATEGORY_lIST_ERROR';

// export const EVENT_REPORT_TABLE_INIT= 'EVENT_REPORT_TABLE_INIT';
// export const EVENT_REPORT_TABLE_lIST_SUCCESS = 'EVENT_REPORT_TABLE_lIST_SUCCESS';
// export const EVENT_REPORT_TABLE_lIST_ERROR = 'EVENT_REPORT_TABLE_lIST_ERROR';

export const EVENT_GENERATE_REPORT_INIT = 'EVENT_GENERATE_REPORT_INIT';
export const EVENT_GENERATE_REPORT_INIT_SUCCESS =
  'EVENT_GENERATE_REPORT_INIT_SUCCESS';
export const EVENT_GENERATE_REPORT_INIT_ERROR =
  'EVENT_GENERATE_REPORT_INIT_ERROR';
export const EVENT_GENERATE_REPORT_INIT_CLEAR =
  'EVENT_GENERATE_REPORT_INIT_CLEAR';

export const APP_F_PUBLISHER_INIT = 'APP_F_PUBLISHER_INIT';
export const APP_F_PUBLISHER_SUCCESS = 'APP_F_PUBLISHER_SUCCESS';
export const APP_F_PUBLISHER_ERROR = 'APP_F_PUBLISHER_ERROR';
export const APP_F_PUBLISHER_NEXT = 'APP_F_PUBLISHER_NEXT';
//
export const APP_F_PUBLISHER_TEST_INIT = 'APP_F_PUBLISHER_TEST_INIT';
export const APP_F_PUBLISHER_TEST_SUCCESS = 'APP_F_PUBLISHER_TEST_SUCCESS';
export const APP_F_PUBLISHER_TEST_ERROR = 'APP_F_PUBLISHER_TEST_ERROR';
export const APP_F_PUBLISHER_TEST_NEXT = 'APP_F_PUBLISHER_TEST_NEXT';
//
export const APP_F_COUNTRY_INIT = 'APP_F_COUNTRY_INIT';
export const APP_F_COUNTRY_SUCCESS = 'APP_F_COUNTRY_SUCCESS';
export const APP_F_COUNTRY_ERROR = 'APP_F_COUNTRY_ERROR';
export const APP_F_COUNTRY_NEXT = 'APP_F_COUNTRY_NEXT';
//
export const APP_F_CAMPAIGN_INIT = 'APP_F_CAMPAIGN_INIT';
export const APP_F_CAMPAIGN_SUCCESS = 'APP_F_CAMPAIGN_SUCCESS';
export const APP_F_CAMPAIGN_ERROR = 'APP_F_CAMPAIGN_ERROR';
export const APP_F_CAMPAIGN_NEXT = 'APP_F_CAMPAIGN_NEXT';
//
export const APP_F_VENDOR_INIT = 'APP_F_VENDOR_INIT';
export const APP_F_VENDOR_SUCCESS = 'APP_F_VENDOR_SUCCESS';
export const APP_F_VENDOR_ERROR = 'APP_F_VENDOR_ERROR';
export const APP_F_CHANNEL_NEXT = 'APP_F_CHANNEL_NEXT';
//
export const APP_F_EVENT_INIT = 'APP_F_EVENT_INIT';
export const APP_F_EVENT_SUCCESS = 'APP_F_EVENT_SUCCESS';
export const APP_F_EVENT_ERROR = 'APP_F_EVENT_ERROR';
export const APP_F_EVENT_NEXT = 'APP_F_EVENT_NEXT';
//
export const APP_F_SUBPUBLISHER_INIT = 'APP_F_SUBPUBLISHER_INIT';
export const APP_F_SUBPUBLISHER_SUCCESS = 'APP_F_SUBPUBLISHER_SUCCESS';
export const APP_F_SUBPUBLISHER_ERROR = 'APP_F_SUBPUBLISHER_ERROR';
export const APP_F_SUBPUBLISHER_NEXT = 'APP_F_SUBPUBLISHER_NEXT';
//
export const APP_F_FIELDS_INIT = 'APP_F_FIELDS_INIT';
export const APP_F_FIELDS_SUCCESS = 'APP_F_FIELDS_SUCCESS';
export const APP_F_FIELDS_ERROR = 'APP_F_FIELDS_ERROR';
export const APP_F_FIELDS_NEXT = 'APP_F_FIELDS_NEXT';
//
export const APP_F_FRAUD_INIT = 'APP_F_FRAUD_INIT';
export const APP_F_FRAUD_SUCCESS = 'APP_F_FRAUD_SUCCESS';
export const APP_F_FRAUD_ERROR = 'APP_F_FRAUD_ERROR';
export const APP_F_FRAUD_NEXT = 'APP_F_FRAUD_NEXT';

// export const EVENT_DOWNLOAD_INIT = 'EVENT_DOWNLOAD_INIT';
// export const EVENT_DOWNLOAD_SUCCESS = 'EVENT_DOWNLOAD_SUCCESS';
// export const EVENT_DOWNLOAD_ERROR = 'EVENT_DOWNLOAD_ERROR';
// export const EVENT_DOWNLOAD_CLEAR = 'EVENT_DOWNLOAD_CLEAR';

export const APP_REPORT_FEILDS_lIST_INIT = 'APP_REPORT_FEILDS_lIST_INIT';
export const APP_REPORT_FEILDS_lIST_INIT_SUCCESS =
  'APP_REPORT_FEILDS_lIST_INIT_SUCCESS';
export const APP_REPORT_FEILDS_lIST_INIT_ERROR =
  'APP_REPORT_FEILDS_lIST_INIT_ERROR';

//Mtrackit Report Api

export const MTRACKIT_REPORT_CAMPAIGN_LIST_INIT =
  'MTRACKIT_REPORT_CAMPAIGN_LIST_INIT';
export const MTRACKIT_REPORT_CAMPAIGN_LIST_INIT_SUCCESS =
  'MTRACKIT_REPORT_CAMPAIGN_LIST_INIT_SUCCESS';
export const MTRACKIT_REPORT_CAMPAIGN_LIST_INIT_ERROR =
  'MTRACKIT_REPORT_CAMPAIGN_LIST_INIT_ERROR';

export const MTRACKIT_REPORT_EVENT_LIST_INIT =
  'MTRACKIT_REPORT_EVENT_LIST_INIT';
export const MTRACKIT_REPORT_EVENT_LIST_INIT_SUCCESS =
  'MTRACKIT_REPORT_EVENT_LIST_INIT_SUCCESS';
export const MTRACKIT_REPORT_EVENT_LIST_INIT_ERROR =
  'MTRACKIT_REPORT_EVENT_LIST_INIT_ERROR';

export const MTRACKIT_REPORT_PUBLISHER_NAME_INIT =
  'MTRACKIT_REPORT_PUBLISHER_NAME_INIT';
export const MTRACKIT_REPORT_PUBLISHER_NAME_INIT_SUCCESS =
  'MTRACKIT_REPORT_PUBLISHER_NAME_INIT_SUCCESS';
export const MTRACKIT_REPORT_PUBLISHER_NAME_INIT_ERROR =
  'MTRACKIT_REPORT_PUBLISHER_NAME_INIT_ERROR';

export const MTRACKIT_REPORT_FRAUD_LIST_INIT =
  'MTRACKIT_REPORT_FRAUD_LIST_INIT';
export const MTRACKIT_REPORT_FRAUD_LIST_INIT_SUCCESS =
  'MTRACKIT_REPORT_FRAUD_LIST_INIT_SUCCESS';
export const MTRACKIT_REPORT_FRAUD_LIST_INIT_ERROR =
  'MTRACKIT_REPORT_FRAUD_LIST_INIT_ERROR';

//mtrackit campaign report api

export const MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT =
  'MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT';
export const MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT_SUCCESS =
  'MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT_SUCCESS';
export const MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT_ERROR =
  'MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT_ERROR';

export const MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT =
  'MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT';
export const MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT_SUCCESS =
  'MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT_SUCCESS';
export const MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT_ERROR =
  'MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT_ERROR';

//MTRACKIT POSTBACK REPORT API

export const MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT =
  'MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT';
export const MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT_SUCCESS =
  'MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT_SUCCESS';
export const MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT_ERROR =
  'MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT_ERROR';

export const MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT =
  'MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT';
export const MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT_SUCCESS =
  'MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT_SUCCESS';
export const MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT_ERROR =
  'MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT_ERROR';

export const MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT =
  'MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT';
export const MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT_SUCCESS =
  'MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT_SUCCESS';
export const MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT_ERROR =
  'MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT_ERROR';

// Impression Integrity

export const IMPRESSION_INTEGRITY_REPORT_INIT =
  'IMPRESSION_INTEGRITY_REPORT_INIT';
export const IMPRESSION_INTEGRITY_REPORT_SUCCESS =
  'IMPRESSION_INTEGRITY_REPORT_SUCESS';
export const IMPRESSION_INTEGRITY_REPORT_ERROR =
  'IMPRESSION_INTEGRITY_REPORT_ERROR';
