import moment from 'moment';
import { useEffect, useCallback } from 'react';

const TIMER = 3;

export default function useJWTSessionTimeOut() {
  const token = localStorage.getItem('token');
  const fn = useCallback(() => {
    // get expired time from token
    if (!token) return;
    try {
      const parsed = JSON.parse(atob(token.split('.')[1]));
      const expiredAt = moment(parsed.exp * 1000).subtract(5, 'minutes');
      if (moment().isSameOrAfter(expiredAt) || !parsed.exp) {
        // Session expired
        console.warn('Session expired');
        localStorage.clear();
        window.location = '/';
      }
    } catch (error) {
      console.warn('Session expired');
      localStorage.clear();
      window.location = '/';
    }
  }, [token]);

  useEffect(() => {
    fn();
    const SessionInterval = setInterval(() => fn(), TIMER * 60 * 1000); // Check every 3 minutes
    return () => clearInterval(SessionInterval);
  }, [token]);
}
