import { put } from 'redux-saga/effects';
import { API } from '../redux/setupAxios';

/**
 *
 * @param {
 * string |
 * {
 *  endpoint:string,
 *  method:string,
 *  domain:string
 * }} endpoint
 * @param {*} constant
 * @param {{defaultUrl:string}} options
 * @returns
 */
export default function sagaGenerator(endpoint, constant, { defaultUrl }) {
  if (!endpoint) throw Error(`Endpoint not defined`);
  if (!constant) throw Error(`Constant not defined`);
  return function*(payload) {
    try {
      const params = payload.query;
      if (typeof endpoint === 'object') {
        if (!endpoint.end && !endpoint.domain)
          throw Error('Missing parameters in Endpoint.');
        let url = endpoint.domain + endpoint.end;
        switch (endpoint.method) {
          case 'GET': {
            if (endpoint.param) url += `/${payload.param}`;
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'POST': {
            const data = payload.data;
            if (endpoint.param) url += `/${payload.param}`;
            const response = yield API.post(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'POST_QUERY': {
            const params = payload.query;
            const response = yield API.post(url, {}, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'PUT': {
            const data = payload.data;
            if (endpoint.param) url += `/${payload.param}`;
            const response = yield API.put(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'DELETE': {
            const data = payload.data;
            if (endpoint.param) url += `/${payload.param}`;
            const response = yield API.delete(url, { params, data });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          default: {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
          }
        }
      } else {
        const url = defaultUrl + endpoint;
        const response = yield API.get(url, { params });
        yield put({
          type: constant.SUCCESS,
          id: payload.id,
          data: response,
        });
      }
    } catch (error) {
      yield put({
        type: constant.ERROR,
        id: payload.id,
        error,
      });
    }
  };
}
