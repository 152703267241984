export const DNSLIST = {
  CLICK_INTEGRITY: {
    BASE_URL: process.env.REACT_APP_DASHBOARD_APP + '/Click_Integrity',
  },
  IMPRESSION_INTEGRITY: {
    BASE_URL: process.env.REACT_APP_DASHBOARD_APP + '/Impression_Integrity',
  },
  ADAO: {
    BASE_URL: process.env.REACT_APP_DOMAIN + '/api/v2/adao',
  },
  BRANDHYGENE: {
    BASE_URL: process.env.REACT_APP_DOMAIN + '/api/v2/bh',
    SAFETY: 'https://brandsafetyhygiene.mfilterit.net',
    AUTHORIZATION:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im1hc3RlcmFwaV90ZXN0In0.BdWqS9bOYbGVicU-uRJsYXKeff6wR0wR3wjkh6aNKUo',
  },
  APP_DASHBOARD: process.env.REACT_APP_DASHBOARD_APP + '/',
  APP_DASHBOARD_IMPRESSION:
    'https://dev2portal-bi2.mfilterit.net/Impression_Integrity',
  APP_DASHBOARD_CLICK: 'https://dev2portal-bi2.mfilterit.net/Click_Integrity',
};
