import React, { useEffect, useState } from 'react';
import './style.scss';

function FingerPrintDemo() {


  return (
    <div className='fingerPrintDash'>
      <iframe title='QS-embedded' src={"https://deviceid.mfilter.net/"}></iframe>
  </div>
  );
}

export default FingerPrintDemo;