import React from 'react';
import Select from 'react-select';

export const MultiSelectField = React.forwardRef(props => {
  const {
    label,
    placeholder,
    option,
    setFieldValue,
    field,
    FieldValue,
    values,
    disabled,
    components,
    form: { touched, errors },
  } = props;
  const handleOnchange = e => {
    setFieldValue(field.name, e);
    if (props.FieldValue) {
      props.FieldValue(e);
    }
  };
  return (
    <>
      <label>{label}</label>
      <Select
        options={option}
        className='basic-multi-select'
        classNamePrefix='select'
        isMulti
        required='required'
        placeholder={placeholder}
        name={field.name}
        onChange={handleOnchange}
        value={field.value}
        isDisabled={disabled}
        components={components}
      />
      <span style={{ color: 'red' }}>
        {touched[field.name] && errors[field.name]}
      </span>
    </>
  );
});

// export default MultiSelectField;
