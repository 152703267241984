export const PUBLISHER_ADD = 'PUBLISHER_ADD';
export const PUBLISHER_ADD_SUCCESS = 'PUBLISHER_ADD_SUCCESS';
export const PUBLISHER_ADD_ERROR = 'PUBLISHER_ADD_ERROR';
export const PUBLISHER_ADD_CLEAR = 'PUBLISHER_ADD_CLEAR';

export const PUBLISHER_GET = 'PUBLISHER_GET';
export const PUBLISHER_GET_SUCCESS = 'PUBLISHER_GET_SUCCESS';
export const PUBLISHER_GET_ERROR = 'PUBLISHER_GET_ERROR';
export const PUBLISHER_GET_CLEAR = 'PUBLISHER_GET_CLEAR';

export const PUBLISHER_GET_MTRACKIT = 'PUBLISHER_GET_MTRACKIT';
export const PUBLISHER_GET_SUCCESS_MTRACKIT = 'PUBLISHER_GET_SUCCESS_MTRACKIT';
export const PUBLISHER_GET_ERROR_MTRACKIT = 'PUBLISHER_GET_ERROR_MTRACKIT';
export const PUBLISHER_GET_CLEAR_MTRACKIT = 'PUBLISHER_GET_CLEAR_MTRACKIT';

export const PUBLISHER_UPDATE = 'PUBLISHER_UPDATE';
export const PUBLISHER_UPDATE_SUCCESS = 'PUBLISHER_UPDATE_SUCCESS';
export const PUBLISHER_UPDATE_ERROR = 'PUBLISHER_UPDATE_ERROR';
export const PUBLISHER_UPDATE_CLEAR = 'PUBLISHER_UPDATE_CLEAR';

export const PUBLISHER_DELETE = 'PUBLISHER_DELETE';
export const PUBLISHER_DELETE_SUCCESS = 'PUBLISHER_DELETE_SUCCESS';
export const PUBLISHER_DELETE_ERROR = 'PUBLISHER_DELETE_ERROR';
export const PUBLISHER_DELETE_CLEAR = 'PUBLISHER_DELETE_CLEAR';

//publisher config
export const PUB_CONFIG_DASHBOARD_INIT = 'PUB_CONFIG_DASHBOARD_INIT';
export const PUB_CONFIG_DASHBOARD_INIT_SUCCESS =
  'PUB_CONFIG_DASHBOARD_INIT_SUCCESS';
export const PUB_CONFIG_DASHBOARD_INIT_ERROR =
  'PUB_CONFIG_DASHBOARD_INIT_ERROR';

export const PUB_CONFIG_LIST_INIT = 'PUB_CONFIG_LIST_INIT';
export const PUB_CONFIG_LIST_INIT_SUCCESS = 'PUB_CONFIG_LIST_INIT_SUCCESS';
export const PUB_CONFIG_LIST_INIT_ERROR = 'PUB_CONFIG_LIST_INIT_ERROR';

export const PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT =
  'PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT';
export const PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT_SUCCESS =
  'PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT_SUCCESS';
export const PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT_ERROR =
  'PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT_ERROR';

export const PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT =
  'PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT';
export const PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT_SUCCESS =
  'PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT_SUCCESS';
export const PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT_ERROR =
  'PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT_ERROR';

export const PUB_CONFIG_VARIATION_LIST_INIT = 'PUB_CONFIG_VARIATION_LIST_INIT';
export const PUB_CONFIG_VARIATION_LIST_INIT_SUCCESS =
  'PUB_CONFIG_VARIATION_LIST_INIT_SUCCESS';
export const PUB_CONFIG_VARIATION_LIST_INIT_ERROR =
  'PUB_CONFIG_VARIATION_LIST_INIT_ERROR';

export const PUB_CONFIG_VARIATION_LIST_EVENT_INIT =
  'PUB_CONFIG_VARIATION_LIST_EVENT_INIT';
export const PUB_CONFIG_VARIATION_LIST_EVENT_INIT_SUCCESS =
  'PUB_CONFIG_VARIATION_LIST_EVENT_INIT_SUCCESS';
export const PUB_CONFIG_VARIATION_LIST_EVENT_INIT_ERROR =
  'PUB_CONFIG_VARIATION_LIST_EVENT_INIT_ERROR';

export const PUB_CONFIG_EVENT_TYPE_LIST_INIT =
  'PUB_CONFIG_EVENT_TYPE_LIST_INIT';
export const PUB_CONFIG_EVENT_TYPE_LIST_INIT_SUCCESS =
  'PUB_CONFIG_EVENT_TYPE_LIST_INIT_SUCCESS';
export const PUB_CONFIG_EVENT_TYPE_LIST_INIT_ERROR =
  'PUB_CONFIG_EVENT_TYPE_LIST_INIT_ERROR';

export const PUB_CONFIG_SAVE_INIT = 'PUB_CONFIG_SAVE_INIT';
export const PUB_CONFIG_SAVE_SUCCESS = 'PUB_CONFIG_SAVE_SUCCESS';
export const PUB_CONFIG_SAVE_ERROR = 'PUB_CONFIG_SAVE_ERROR';
export const PUB_CONFIG_SAVE_CLEAR = 'PUB_CONFIG_SAVE_CLEAR';

//publisher config tabel

export const CONFIG_REPORT_LIST_INIT = 'CONFIG_REPORT_LIST_INIT';
export const CONFIG_REPORT_LIST_INIT_SUCCESS =
  'CONFIG_REPORT_LIST_INIT_SUCCESS';
export const CONFIG_REPORT_LIST_INIT_ERROR = 'CONFIG_REPORT_LIST_INIT_ERROR';

export const CONFIG_EDIT_LIST_INIT = 'CONFIG_EDIT_LIST_INIT';
export const CONFIG_EDIT_LIST_INIT_SUCCESS = 'CONFIG_EDIT_LIST_INIT_SUCCESS';
export const CONFIG_EDIT_LIST_INIT_ERROR = 'CONFIG_EDIT_LIST_INIT_ERROR';

export const PUBLISHER_CONFIG_DELETE = 'PUBLISHER_CONFIG_DELETE';
export const PUBLISHER_CONFIG_DELETE_SUCCESS =
  'PUBLISHER_CONFIG_DELETE_SUCCESS';
export const PUBLISHER_CONFIG_DELETE_ERROR = 'PUBLISHER_CONFIG_DELETE_ERROR';
export const PUBLISHER_CONFIG_DELETE_CLEAR = 'PUBLISHER_CONFIG_DELETE_CLEAR';
