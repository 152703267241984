export const PACKAGE_DETAILS_INIT = 'PACKAGE_DETAILS_INIT';
export const PACKAGE_DETAILS_INIT_SUCCESS = 'PACKAGE_DETAILS_INIT_SUCCESS';
export const PACKAGE_DETAILS_INIT_ERROR = 'PACKAGE_DETAILS_INIT_ERROR';

export const PACKAGE_SAVE_INIT = 'PACKAGE_SAVE_INIT';
export const PACKAGE_SAVE_INIT_SUCCESS = 'PACKAGE_SAVE_INIT_SUCCESS';
export const PACKAGE_SAVE_INIT_ERROR = 'PACKAGE_SAVE_INIT_ERROR';

export const PACKAGE_UPDATE_INIT = 'PACKAGE_UPDATE_INIT';
export const PACKAGE_UPDATE_INIT_SUCCESS = 'PACKAGE_UPDATE_INIT_SUCCESS';
export const PACKAGE_UPDATE_INIT_ERROR = 'PACKAGE_UPDATE_INIT_ERROR';

export const PACKAGE_DELETE_INIT = 'PACKAGE_DELETE_INIT';
export const PACKAGE_DELETE_INIT_SUCCESS = 'PACKAGE_DELETE_INIT_SUCCESS';
export const PACKAGE_DELETE_INIT_ERROR = 'PACKAGE_DELETE_INIT_ERROR';

export const GETONE_PACKAGE_INIT = 'GETONE_PACKAGE_INIT';
export const GETONE_PACKAGE_INIT_SUCCESS = 'GETONE_PACKAGE_INIT_SUCCESS';
export const GETONE_PACKAGE_INIT_ERROR = 'GETONE_PACKAGE_INIT_ERROR';
