import React, { createContext, useContext } from 'react';
import useDateReducer from './reducer';

const DateContext = createContext();

export function useDate() {
  return useContext(DateContext);
}

export default function DateProvider({ children }) {
  const [state, dispatch] = useDateReducer();
  return (
    <DateContext.Provider value={{ state, dispatch }}>
      {children}
    </DateContext.Provider>
  );
}
