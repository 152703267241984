import { combineReducers } from 'redux';
// import Reducer from '../../Utils/Reducer';
import CONST from './const';

function BrandRelevancyReducer(state = {}, action) {
  return Reducer(CONST.BRAND_RELEVANCY[action.id], state, action);
}
export default combineReducers({
 BrandRelevancyReducer,
});


function Reducer(key, state, action) {
  switch (action.type) {
    case key?.INIT: {
      const data = { loading: true, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.SUCCESS: {
      const data = { loading: false, data: action.data, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.ERROR: {
      const data = { loading: false, data: null, error: action.error };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.CLEAR: {
      const data = { loading: false, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    default:
      return state;
  }
}