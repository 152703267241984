import { takeLatest, put } from 'redux-saga/effects';
import * as constant from '../constants/IncentDetailsConstant';
import { API, API_DOWNLOAD_CALL } from '../setupAxios';
import { EndPoints, ApiUrl } from '../helpers/Endpoints';

export function* FetchIncentDetailsSaga({ payload }) {
  try {
    let url = ApiUrl + EndPoints.incentDetaild;
    const response = yield API.get(url, { params: payload });
    yield put({ type: constant.INCENT_DETAILS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.INCENT_DETAILS_ERROR, error });
  }
}
export function* DownloadIncentDetailsSaga({ payload }) {
  try {
    let url = ApiUrl + EndPoints.incentDetaildDownload;
    const response = yield API_DOWNLOAD_CALL.get(url, { params: payload });
    yield put({ type: constant.INCENT_DOWNLOAD_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.INCENT_DOWNLOAD_ERROR, error });
  }
}

export default function* IncentDetailsSaga() {
  yield takeLatest(constant.INCENT_DETAILS_INIT, FetchIncentDetailsSaga);
  yield takeLatest(constant.INCENT_DOWNLOAD_INIT, DownloadIncentDetailsSaga);
}
