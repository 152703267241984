export default function downloadFile({
  contentType = 'text/csv',
  data,
  filename = 'report.csv',
}) {
  if (!data) return;
  const csvFile = new Blob([data], { type: contentType });
  var a = document.createElement('a');
  a.download = filename;
  a.href = window.URL.createObjectURL(csvFile);
  document.body.appendChild(a);
  a.click();
  setTimeout(() => document.body.removeChild(a), 10000);
}
