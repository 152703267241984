import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function handleKeyDown(event) {
  if (event.shiftKey && event.key === '!') {
    const menu = document.getElementById('hiddenMenu');
    menu.style.display = menu.style.display === 'none' ? 'block' : 'none';
  }
}
function handleKeyUp(event) {
  if (event.shiftKey || event.key === '!') {
    const menu = document.getElementById('hiddenMenu');
    menu.style.display = 'none';
  }
}

export default function HiddenMenu() {
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    // document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      //   document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    <div id='hiddenMenu'>
      <ul>
        <li>
          <Link to='/docs'>Docs</Link>
        </li>
      </ul>
    </div>
  );
}
