import AnalyticsDashboardConst from '../constants/AnalyticsDashboardConst';

const init = {
  loading: false,
  error: null,
  data: null,
};

function AnalyticsDashboardReducer(state = init, { type, response, error }) {
  switch (type) {
    case AnalyticsDashboardConst.GETURL_INIT: {
      return { loading: true, error: null, data: null };
    }
    case AnalyticsDashboardConst.GETURL_SUCCESS: {
      return { loading: false, error: null, data: response };
    }
    case AnalyticsDashboardConst.GETURL_ERROR: {
      return { loading: false, error: error, data: null };
    }
    case AnalyticsDashboardConst.GETURL_CLEAR: {
      return { loading: false, error: null, data: null };
    }
    default:
      return state;
  }
}

export default AnalyticsDashboardReducer;
