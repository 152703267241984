export default Object.freeze({
  DASHBOARD: {
    INIT: 'INIT_CLICKINTEGRITY_DASHBOATD',
    SUCCESS: 'SUCCESS_CLICKINTEGRITY_DASHBOATD',
    ERROR: 'INIT_CLICKINTEGRITY_DASHBOATD',
  },
  GENERATE_URL: {
    INIT: 'INIT_CLICKINTEGRITY',
    SUCCESS: 'SUCCESS_CLICKINTEGRITY',
    ERROR: 'INIT_CLICKINTEGRITY',
  },
});
