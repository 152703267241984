import * as constant from './constants';

const initialState = {
  mtrackit_report: [],
  mtrackit_report_loading: true,
  mtrackit_report_error: null,
  download: { loading: false, data: null, error: null },
// mtrack event report page 
  mtrackit_event_report: [],
  mtrackit_event_report_loading: true,
  mtrackit_event_report_error: null,
  event_download: { loading: false, data: null, error: null },
  
  
  campaign_mtrackit_report: [],
  campaign_mtrackit_report_loading: true,
  campaign_mtrackit_report_error: null,
  campaign_download: { loading: false, data: null, error: null },

  postback_mtrackit_report: [],
  postback_mtrackit_report_loading: true,
  postback_mtrackit_report_error: null,
  postback_download: { loading: false, data: null, error: null },
  
};

const MreportReducer = (state = initialState, { type, response, error }) => {
  switch (type) {
    case constant.MTRACKREPORT_INIT:
      return {
        ...state,
        mtrackit_report_loading: true,
      };

    case constant.MTRACKREPORT_INIT_SUCCESS:
      return {
        ...state,
        mtrackit_report_loading: false,
        mtrackit_report: response,
      };
    // console.log("data in report")

    case constant.MTRACKREPORT_INIT_ERROR:
      return {
        ...state,
        mtrackit_report_loading: false,
        error: response,
      };
// mtreack event report pages 

case constant.MTRACKEVENTREPORT_INIT:
  return {
    ...state,
    mtrackit_event_report_loading: true,
  };

case constant.MTRACKEVENTREPORT_INIT_SUCCESS:
  return {
    ...state,
    mtrackit_event_report_loading: false,
    mtrackit_event_report: response,
    
  };

case constant.MTRACKEVENTREPORT_INIT_ERROR:
  return {
    ...state,
    mtrackit_event_report_loading: false,
    error: response,
  };
    case constant.MTRACKREPORT_EVENT_DOWNLOAD_INIT:
      return {
        ...state,
        download: { loading: true, data: null, error: null },
      };
case constant.MTRACKREPORT_DOWNLOAD_INIT:
      return {
        ...state,
        download: { loading: true, data: null, error: null },
      };
    case constant.MTRACKREPORT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        download: { loading: false, data: response, error: null },
      };
    case constant.MTRACKREPORT_DOWNLOAD_ERROR:
      return {
        ...state,
        download: { loading: false, data: null, error: error },
      };
    case constant.MTRACKREPORT_DOWNLOAD_CLEAR:
      return {
        ...state,
        download: { loading: false, data: null, error: null },
      };
      
      //campaign 
      case constant.MTRACKCAMPAIGNREPORT_INIT:
      return {
        ...state,
        mtrackit_campaign_report_loading: true,
      };

    case constant.MTRACKCAMPAIGNREPORT_INIT_SUCCESS:
      return {
        ...state,
        mtrackit_campaign_report_loading: false,
        campaign_mtrackit_report: response,
      };
    // console.log("data in report")
    case constant.MTRACKCAMPAIGNREPORT_INIT_ERROR:
      return {
        ...state,
        mtrackit_campaign_report_loading: false,
        error: response,
      };

    case constant.MTRACKCAMPAIGNREPORT_DOWNLOAD_INIT:
      return {
        ...state,
        campaign_download: { loading: true, data: null, error: null },
      };
    case constant.MTRACKCAMPAIGNREPORT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        campaign_download: { loading: false, data: response, error: null },
      };
    case constant.MTRACKCAMPAIGNREPORT_DOWNLOAD_ERROR:
      return {
        ...state,
        campaign_download: { loading: false, data: null, error: error },
      };
    case constant.MTRACKCAMPAIGNREPORT_DOWNLOAD_CLEAR:
      return {
        ...state,
        campaign_download: { loading: false, data: null, error: null },
      };

      
      //postback
      case constant.MTRACKPOSTBACKREPORT_INIT:
      return {
        ...state,
        mtrackit_postback_report_loading: true,
      };
    case constant.MTRACKPOSTBACKREPORT_INIT_SUCCESS:
      return {
        ...state,
        mtrackit_postback_report_loading: false,
        postback_mtrackit_report: response,
      };
    case constant.MTRACKPOSTBACKREPORT_INIT_ERROR:
      return {
        ...state,
        mtrackit_postback_report_loading: false,
        error: response,
      };
    case constant.MTRACKPOSTBACKREPORT_DOWNLOAD_INIT:
      return {
        ...state,
        postback_download: { loading: true, data: null, error: null },
      };
    case constant.MTRACKPOSTBACKREPORT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        postback_download: { loading: false, data: response, error: null },
      };
    case constant.MTRACKPOSTBACKREPORT_DOWNLOAD_ERROR:
      return {
        ...state,
        postback_download: { loading: false, data: null, error: error },
      };
    case constant.MTRACKPOSTBACKREPORT_DOWNLOAD_CLEAR:
      return {
        ...state,
        postback_download: { loading: false, data: null, error: null },
      };
    default:
      return state;
  }
};
export default MreportReducer;
