import { takeLatest, put } from 'redux-saga/effects';
import WebDashboardConst from '../../redux/WebFraud/constant';
import { API } from '../setupAxios';
import { ApiUrl, VASTUrl, VastApiUrl , OnboardUrls } from '../helpers/Endpoints';

const DASHBOARD_TYPE = {
  CLICK: 'CLICK',
  EVENT: 'EVENT',
  IMPRESSION: 'IMPRESSION',
  VISIT: 'VISIT',
  REPORT_FILTERS: 'REPORT_FILTERS',
};

const Endpoint = {
  Filters: {
    CLICK: {
      CLICK_FRAUD_CATEGORY: '/api/web-bi/click/filters/fraud_category',
      CLICK_FRAUD_SUB_CATEGORY: '/api/web-bi/click/filters/fraud_sub_category',
      CLICK_PUBLISHER_NAME: '/api/web-bi/click/filters/publisher_name',
      CLICK_CAMPAIGN_ID: '/api/web-bi/click/filters/campaign_id',
      CLICK_DEVICE_TYPE: '/api/web-bi/click/filters/device_type',
      CLICK_FILTER_LIST: '/api/user/filter',
    },
    EVENT: {
      EVENT_CAMPAIGN_LIST: '/api/web-bi/event/lazyfilters/campaign',
      EVENT_PAGE_LIST: '/api/web-bi/event/lazyfilters/page',
      EVENT_PUBLISHER_LIST: '/api/web-bi/event/lazyfilters/publisher',
      EVENT_SUB_PUBLISHER_LIST: '/api/web-bi/event/lazyfilters/sub_publisher',
      // event_nl_publisher_list: "/api/web-bi/event/filters/publisher",
      EVENT_CHANNEL_LIST: '/api/web-bi/event/lazyfilters/channel',
      EVENT_EVENT_LIST: '/api/web-bi/event/lazyfilters/event',
      EVENT_FILTER_LIST: `/api/user/filter`,
    },
    IMPRESSION: {
      IMPRESSION_PUBLISHER_ID_LIST: '/api/web-bi/filters/publisher_id',
      IMPRESSION_CHANNEL_LIST: '/api/web-bi/filters/channel',
      IMPRESSION_CAMPAIGN_LIST: '/api/web-bi/lazyfilters/campaign_id',
      IMPRESSION_CREATIVE_LIST: '/api/web-bi/filters/creative_id',
      IMPRESSION_SYSTEM_DOMAIN_LIST: '/api/web-bi/lazyfilters/system_domain',
      IMPRESSION_PLACEMENT_ID_LIST: '/api/web-bi/lazyfilters/placement_id',
      IMPRESSION_FRAUD_CATEGORY_LIST: '/api/web-bi/filters/fraud_category',
      IMPRESSION_FRAUD_SUB_CATEGORY_LIST:
        '/api/web-bi/filters/fraud_sub_category',
      IMPRESSION_PAGE_ID_LIST: '/api/web-bi/lazyfilters/page_id',
      IMPRESSION_SUB_PUBLISHER_LIST: '/api/web-bi/filters/sub_publisher_name',
      IMPRESSION_FILTER_LIST: '/api/user/filter',
    },
    VISIT: {
      VISIT_FILTER_LIST: '/api/user/filter',
      VISIT_CHANNEL_LIST: '/api/web-bi/filters/channel',
      VISIT_CAMPAIGN_LIST: '/api/web-bi/lazyfilters/campaign_id',
      VISIT_PLACEMENT_ID_LIST: '/api/web-bi/lazyfilters/placement_id',
      VISIT_FRAUD_CATEGORY_LIST: '/api/web-bi/filters/fraud_category',
      VISIT_FRAUD_SUB_CATEGORY_LIST: '/api/web-bi/filters/fraud_sub_category',
      VISIT_PAGE_ID_LIST: '/api/web-bi/lazyfilters/page_id',
      VISIT_SUB_PUBLISHER_LIST: '/api/web-bi/filters/sub_publisher_name',
      VISIT_PLACEMENT_TYPE_LIST: '/api/web-bi/filters/creative_id',
      VISIT_PUBLISHER_LIST: '/api/web-bi/filters/publisher_name',
    },
    VAST: {
      VAST_LOCATION_FILTER_LIST: '/api/filter_options/location',
      VAST_CAMPAIGN_FILTER_LIST: '/api/filter_options/campaign',
      VAST_PUBLISHER_FILTER_LIST: '/api/filter_options/publisher',
    },
    GOOGLE_DASHBOARD: {
      ANALYTICS: '/analytics/',
      TOP_CAMPAIGNS: '/critical_campaigns/',
      FRAUD_GEO: '/fraudulent_geo/',
      FRAUD_CATEGORY: '/fraud_categories/',
    },
    REPORT_FILTERS: {
      FIELD_LIST: '/api/web-bi/event/report/filter/fields',
      FRAUD_CATEGORY: '/api/web-bi/event/lazyreportfilters/fraud_category',
      PUBLISHER_LIST: '/api/web-bi/event/report/filter/publisher',
      REPORT_FILTER_LIST: '/api/user/filter',
    },
  },
  CLICK: {
    TOP_CLEAN_PUBLISHER: '/api/v2/web/click/dashboard/top-publisher/clean',
    TOTAL_CLICK: '/api/v2/web/click/dashboard/total',
    CATEGORYWISE_FRAUD: '/api/v2/web/click/dashboard/fraud-category',
    TOP_INVALID_PUBLISHER: '/api/v2/web/click/dashboard/top-publisher/invalid',
    DAILY_FRAUD_TREND: '/api/v2/web/click/dashboard/date-wise',
    MONTHLY_FRAUD_TREND: '/api/v2/web/click/dashboard/month-wise',
    CAMPAIGN_FRAUD_TREND: '/api/v2/web/click/dashboard/campaign-wise',
    PLACEMENT_FRAUD_TREND: '/api/v2/web/click/dashboard/placement-wise',
    CHANNEL_FRAUD_TREND: '/api/v2/web/click/dashboard/channel-wise',
    WEEKLY_FRAUD_TREND: '/api/v2/web/click/dashboard/week-wise',
    SUB_CATEGORYWISE_FRAUD: '/api/v2/web/click/dashboard/fraud-sub-category',
    CLICK_PUBLISHERWISE_FRAUD_TREND:
      '/api/v2/web/click/dashboard/publisher-wise',
  },
  EVENT: {
    TOTAL_EVENT_COUNT: '/api/v2/web/event/dashboard/total',
    EVENT_CATEGORYWISE_FRAUD: '/api/v2/web/event/dashboard/fraud-category',
    EVENT_CLEAN_TRAFFIC: '/api/v2/web/event/dashboard/top-publisher/clean',
    EVENT_FRAUD_TRAFFIC: '/api/v2/web/event/dashboard/top-publisher/invalid',
    EVENT_MONTHLY_FRAUD_TREND: '/api/v2/web/event/dashboard/month-wise',
    EVENT_DAILY_FRAUD_TREND: '/api/v2/web/event/dashboard/date-wise',
    EVENT_PUBLISHERWISE_FRAUD_TREND:
      '/api/v2/web/event/dashboard/publisher-wise',
    SUB_CATEGORYWISE_FRAUD: '/api/v2/web/event/dashboard/fraud-sub-category',
    CAMPAIGN_FRAUD_TREND: '/api/v2/web/event/dashboard/campaign-wise',
    PLACEMENT_FRAUD_TREND: '/api/v2/web/event/dashboard/placement-wise',
    CHANNEL_FRAUD_TREND: '/api/v2/web/event/dashboard/channel-wise',
    WEEKLY_FRAUD_TREND: '/api/v2/web/event/dashboard/week-wise',
  },
  IMPRESSION: {
    SUB_CATEGORYWISE_FRAUD:
      '/api/v2/web/impression/dashboard/fraud-sub-category',
    TOTAL_IMPRESSION: '/api/v2/web/impression/dashboard/total',
    DAILY_FRAUD_TREND: '/api/v2/web/impression/dashboard/date-wise',
    PLACEMENT_FRAUD_TREND: '/api/v2/web/impression/dashboard/placement-wise',
    MONTHLY_FRAUD_TREND: '/api/v2/web/impression/dashboard/month-wise',
    CHANNEL_FRAUD_TREND: '/api/v2/web/impression/dashboard/channel-wise',
    PUBLISHERWISE_FRAUD_TREND:
      '/api/v2/web/impression/dashboard/publisher-wise',
    CAMPAIGN_FRAUD_TREND: '/api/v2/web/impression/dashboard/campaign-wise',
    WEEKLY_FRAUD_TREND: '/api/v2/web/impression/dashboard/week-wise',
    CATEGORYWISE_FRAUD: '/api/v2/web/impression/dashboard/fraud-category',
    TOP_FRAUD_PUBLISHER:
      '/api/v2/web/impression/dashboard/top-publisher/invalid',
    TOP_CLEAN_PUBLISHER: '/api/v2/web/impression/dashboard/top-publisher/clean',
    CREATIVE_ID: '/api/v2/web/impression/dashboard/creative-id',
    //
    FRAUD_REASON_TREND: '/api/web-bi/plots/fraud_reason_trend',
    FRAUD_DISTRIBUTION: '/api/web-bi/plots/fraud_impression',
    FRAUD_CLEAN_IMPRESSION: '/api/web-bi/plots/cumulative_impression_trend',
    TOP_EXCHANGE_TRAFFIC: '/api/web-bi/plots/top_exchange_traffic',
    TOP_CONTRIBUTING_PLACEMENT:
      '/api/web-bi/plots/placement_high_fraud_traffic',
    TOP_PLACEMENT: '/api/web-bi/plots/top_placement',
  },
  VISIT: {
    SUB_CATEGORYWISE_FRAUD: '/api/v2/web/visit/dashboard/fraud-sub-category',
    TOP_FRAUD_PUBLISHER: '/api/v2/web/visit/dashboard/top-publisher/invalid',
    TOTAL_VISIT_CARDS: '/api/v2/web/visit/dashboard/total',
    CATEGORYWISE_FRAUD: '/api/v2/web/visit/dashboard/fraud-category',
    DAILY_FRAUD_TREND: '/api/v2/web/visit/dashboard/date-wise',
    MONTHLY_FRAUD_TREND: '/api/v2/web/visit/dashboard/month-wise',
    PUBLISHERWISE_FRAUD_TREND: '/api/v2/web/visit/dashboard/publisher-wise',
    TOP_CLEAN_PUBLISHER: '/api/v2/web/visit/dashboard/top-publisher/clean',
    CAMPAIGN_FRAUD_TREND: '/api/v2/web/visit/dashboard/campaign-wise',
    PLACEMENT_FRAUD_TREND: '/api/v2/web/visit/dashboard/placement-wise',
    CHANNEL_FRAUD_TREND: '/api/v2/web/visit/dashboard/channel-wise',
    WEEKLY_FRAUD_TREND: '/api/v2/web/visit/dashboard/week-wise',
  },
  VAST: {
    TOTAL_COUNTS: '/api/total_counts',
    COMULATIVE_CAMPAIGN_SUMMARY: '/api/campaign_summary',
    IMPRESSION_DISTRIBUTION: '/api/impression_distribution',
    VAST_TRENDS_BY_DATE: '/api/trends_graph',
    VAST_TRENDS: '/api/counts_funnel',
    VAST_TABLE_DOWNLOAD: '/api/export_campaign_summary',
  },
  PUBLISHER_REPORT: {
    CONFIG_DASHBOARD_TYPE: '/api/user/dashboard_type',
    configListClick: '/api/web-bi/click/filters/publisher_name',
    CONFIG_LIST: '/api/web-bi/event/filters/publisher',
    configListPublisherId: `/api/web-bi/filters/publisher_id`,
    configListPublisherName: `/api/web-bi/filters/publisher_name`,
    CONFIG_FRAUD_CATEGORY: '/api/web-bi/publisher_config/fraud_categories',
    CONFIG_FRAUD_SUB_CATEGORY:
      '/api/web-bi/publisher_config/fraud_sub_categories',
    CONFIG_PUB_VARIATION: {
      domain: ApiUrl,
      end: '/api/web-bi/publisher_config/variations/Web_Event',
      method: 'VARIATION',
    },
    CONFIG_SAVE: {
      domain: ApiUrl,
      end: '/api/web-bi/publisher_config',
      method: 'POST',
    },
    SASS_ACCOUNT: {
      domain: OnboardUrls,
      end: '/analytics_click/',
      method: 'POST',
    },
    CONFIG_TABEL: '/api/web-bi/publisher_config',
    CONFIG_UPDATE: '/api/web-bi/publisher_config/getone',
    CONFIG_DELETE: {
      domain: ApiUrl,
      end: '/api/web-bi/publisher_config',
      method: 'DELETE',
    },
    EVENT_REPORT: {
      GET_TABLE: '/api/web-bi/event/report',
      GENERATE_REPORT: {
        domain: ApiUrl,
        end: '/api/web-bi/event/report/generate',
        method: 'POST_QUERY',
      },
    },
  },
  // VAST_TRACKER: {
  //   // VAST_TABEL: "/api/web-bi/vast_tracker/",
  //   VAST_TABEL: "/api/list_vast_trackers",
  //   // CONFIG_UPDATE: "/api/web-bi/publisher_config/getone",
  //   VAST_DELETE: {
  //     // domain: ApiUrl,
  //     domain: VASTUrl,
  //     end: "/api/web-bi/vast_tracker/",
  //     method: "DELETE",
  //   },
  // },
  REPORT: {
    REPORT_LIST: `/api/web-bi/event/report`,
    GENERATE_REPORT: {
      domain: ApiUrl,
      end: '/api/web-bi/event/report/generate',
      method: 'POST',
    },
  },
  COLORS: {
    colors: '/api/config/color',
  },
};

// export function* getGoogleDashboardData(payload) {
//   try {
//     if (!Endpoint.Filters.GOOGLE_DASHBOARD[payload.id])
//       throw Error(`Endpoint not defined, Type:${payload.type}`);
//     const params = payload.dashboarddata;
//     const url = OnboardUrls + Endpoint.Filters.GOOGLE_DASHBOARD[payload.id];
//     const response = yield API.get(url, { params });
//     yield put({
//       type: WebDashboardConst.DASHBOARD.GOOGLE_DASHBOARD[payload.id].SUCCESS,
//       id: payload.id,
//       data: response,
//     });
//   } catch (error) {
//     console.error(payload.id, error);
//     yield put({
//       type: WebDashboardConst.DASHBOARD.GOOGLE_DASHBOARD[payload.id].ERROR,
//       id: payload.id,
//       error,
//     });
//   }
// }

export function* getGoogleDashboardData(payload) {
  try {
    console.log(payload , "02020202");
    if (!Endpoint.Filters.GOOGLE_DASHBOARD[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.dashboarddata;
    const url = OnboardUrls + Endpoint.Filters.GOOGLE_DASHBOARD[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: WebDashboardConst.DASHBOARD.GOOGLE_DASHBOARD[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type:  WebDashboardConst.DASHBOARD.GOOGLE_DASHBOARD[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}


function getDashboardData(type) {
  return function*(payload) {
    try {
      if (!Endpoint[type][payload.id]) throw Error('Endpoint not defined');
      const params = payload.dashboarddata;
      const url = ApiUrl + Endpoint[type][payload.id];
      const response = yield API.get(url, { params });
      yield put({
        type: WebDashboardConst.DASHBOARD[type][payload.id].SUCCESS,
        id: payload.id,
        data: response,
      });
    } catch (error) {
      yield put({
        type: WebDashboardConst.DASHBOARD[type][payload.id].ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

function getFilterData(type) {
  return function*(payload) {
    try {
      if (!Endpoint.Filters[type][payload.id])
        throw Error('Endpoint not defined');
      const params = payload.query;
      const url = ApiUrl + Endpoint.Filters[type][payload.id];
      const response = yield API.get(url, { params });
      yield put({
        type: WebDashboardConst.FILTER[type][payload.id].SUCCESS,
        id: payload.id,
        data: response,
      });
    } catch (error) {
      yield put({
        type: WebDashboardConst.FILTER[type][payload.id].ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

export function* getVastFilterData(payload) {
  try {
    if (!Endpoint.Filters.VAST[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = VastApiUrl + Endpoint.Filters.VAST[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: WebDashboardConst.FILTER.VAST[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: WebDashboardConst.FILTER.VAST[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

function getImpressionFilterData(type) {
  return function*(payload) {
    try {
      if (!Endpoint.Filters[type][payload.id])
        throw Error('Endpoint not defined');
      const params = payload.query;
      // console.log(params,'impressioparann')
      const url = ApiUrl + Endpoint.Filters[type][payload.id];
      // console.log(url,'hfhfhghf')
      const response = yield API.get(url, { params });
      // console.log(response,'respospopsosbcbcbbcpo111')
      yield put({
        type: WebDashboardConst.FILTER[type][payload.id].SUCCESS,
        id: payload.id,
        data: response,
      });
    } catch (error) {
      yield put({
        type: WebDashboardConst.FILTER[type][payload.id].ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

export function* ViewColors(payload) {
  try {
    const params = payload.dashboarddata;
    const url = ApiUrl + Endpoint.COLORS.colors;
    const response = yield API.get(url, { params });
    yield put({
      type: WebDashboardConst.DASHBOARD.COLORS.colors.SUCCESS,
      response,
      id: 'colors',
    });
  } catch (error) {
    yield put({
      type: WebDashboardConst.DASHBOARD.COLORS.colors.ERROR,
      error,
      id: 'colors',
    });
  }
}

export function* getPublisherReportData(payload) {
  try {
    if (!Endpoint.PUBLISHER_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.PUBLISHER_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: WebDashboardConst.DASHBOARD.PUBLISHER_REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: WebDashboardConst.DASHBOARD.PUBLISHER_REPORT[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getVastDashboardData(payload) {
  try {
    if (!Endpoint.VAST[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);

    const url = VastApiUrl + Endpoint.VAST[payload.id];

    // Use API.post instead of API.get and pass payload as the data in the body
    const response = yield API.post(url, payload.dashboarddata, {
      headers: { 'Content-Type': 'application/json' },
    });

    yield put({
      type: WebDashboardConst.DASHBOARD.VAST[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: WebDashboardConst.DASHBOARD.VAST[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

// export function* getVASTTrackersData(payload) {
//   console.log(Endpoint);
//   try {
//     if (!Endpoint.VAST_TRACKER[payload.id])
//       throw Error(`Endpoint not defined, Type:${payload.type}`);
//     const params = payload.query;
//     const url = VASTUrl + Endpoint.VAST_TRACKER[payload.id];
//     const response = yield API.get(url, { params });
//     yield put({
//       // type: WebDashboardConst.DASHBOARD.PUBLISHER_REPORT[payload.id].SUCCESS,
//       type: WebDashboardConst.VAST[payload.id].SUCCESS,
//       id: payload.id,
//       data: response,
//     });
//   } catch (error) {
//     console.error(payload.id, error);
//     yield put({
//       type: WebDashboardConst.VAST[payload.id].ERROR,
//       id: payload.id,
//       error,
//     });
//   }
// }

export function* getReportData(payload) {
  try {
    if (!Endpoint.REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: WebDashboardConst.DASHBOARD.REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: WebDashboardConst.DASHBOARD.REPORT[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

function sagaGenerator(endpoint, constant) {
  if (!endpoint) throw Error(`Endpoint not defined`);
  if (!constant) throw Error(`Constant not defined`);
  return function*(payload) {
    try {
      const params = payload.query;
      if (typeof endpoint === 'object') {
        if (!endpoint.end && !endpoint.domain)
          throw Error('Missing parameters in Endpoint.');
        const url = endpoint.domain + endpoint.end;
        switch (endpoint.method) {
          case 'VARIATION': {
            const response = yield API.get(url);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'GET': {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'POST': {
            const data = payload.query;
            const response = yield API.post(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'DELETE': {
            const response = yield API.delete(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          default: {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
          }
        }
      } else {
        const url = ApiUrl + endpoint;
        const response = yield API.get(url, { params });
        yield put({
          type: constant.SUCCESS,
          id: payload.id,
          data: response,
        });
      }
    } catch (error) {
      console.error(payload.id, error);
      yield put({
        type: constant.ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

export default function* WebDashboardSaga() {
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.TOTAL_EVENT_COUNT.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.EVENT_CATEGORYWISE_FRAUD.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.EVENT_CLEAN_TRAFFIC.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.EVENT_FRAUD_TRAFFIC.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.EVENT_MONTHLY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.EVENT_DAILY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.EVENT_PUBLISHERWISE_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.CAMPAIGN_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.CHANNEL_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.PLACEMENT_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.WEEKLY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.EVENT.SUB_CATEGORYWISE_FRAUD.INIT,
    getDashboardData(DASHBOARD_TYPE.EVENT)
  );

  // Event dashboard saga end

  // Vast dashboard saga starts

  yield takeLatest(
    WebDashboardConst.DASHBOARD.VAST.VAST_TABLE_DOWNLOAD.INIT,
    getVastDashboardData
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.VAST.TOTAL_COUNTS.INIT,
    getVastDashboardData
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.VAST.COMULATIVE_CAMPAIGN_SUMMARY.INIT,
    getVastDashboardData
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.VAST.IMPRESSION_DISTRIBUTION.INIT,
    getVastDashboardData
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.VAST.VAST_TRENDS_BY_DATE.INIT,
    getVastDashboardData
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.VAST.VAST_TRENDS.INIT,
    getVastDashboardData
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.TOTAL_IMPRESSION.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.DAILY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.SUB_CATEGORYWISE_FRAUD.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.MONTHLY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.PUBLISHERWISE_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.CAMPAIGN_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.PLACEMENT_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.CHANNEL_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.WEEKLY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.TOP_FRAUD_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.TOP_CLEAN_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.CREATIVE_ID.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.CATEGORYWISE_FRAUD.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );

  // //////old ///////
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.FRAUD_REASON_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.FRAUD_DISTRIBUTION.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.FRAUD_CLEAN_IMPRESSION.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.TOP_EXCHANGE_TRAFFIC.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.TOP_CONTRIBUTING_PLACEMENT.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.IMPRESSION.TOP_PLACEMENT.INIT,
    getDashboardData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.TOP_CLEAN_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.TOP_FRAUD_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.DAILY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.SUB_CATEGORYWISE_FRAUD.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.MONTHLY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.PUBLISHERWISE_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.CAMPAIGN_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.CHANNEL_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.PLACEMENT_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.WEEKLY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.TOTAL_VISIT_CARDS.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.VISIT.CATEGORYWISE_FRAUD.INIT,
    getDashboardData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.TOTAL_CLICK.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.CATEGORYWISE_FRAUD.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.TOP_CLEAN_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.SUB_CATEGORYWISE_FRAUD.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.TOP_INVALID_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.DAILY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.CLICK_PUBLISHERWISE_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.MONTHLY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.CAMPAIGN_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.PLACEMENT_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.CHANNEL_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.CLICK.WEEKLY_FRAUD_TREND.INIT,
    getDashboardData(DASHBOARD_TYPE.CLICK)
  );

  yield takeLatest(WebDashboardConst.DASHBOARD.COLORS.colors.INIT, ViewColors);

  yield takeLatest(
    WebDashboardConst.DASHBOARD.REPORT.REPORT_LIST.INIT,
    getReportData
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.REPORT.REFETCH_LIST.REFETCH,
    getReportData
  );
  // yield takeLatest(WebDashboardConst.DASHBOARD.REPORT.GENERATE_REPORT.INIT,
  //   getReportData
  // );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.REPORT.GENERATE_REPORT.INIT,
    sagaGenerator(
      Endpoint.REPORT.GENERATE_REPORT,
      WebDashboardConst.DASHBOARD.REPORT.GENERATE_REPORT
    )
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_DASHBOARD_TYPE.INIT,
    getPublisherReportData
  );

  yield takeLatest(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_FRAUD_CATEGORY.INIT,
    getPublisherReportData
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_FRAUD_SUB_CATEGORY.INIT,
    getPublisherReportData
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_TABEL.INIT,
    getPublisherReportData
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_UPDATE.INIT,
    getPublisherReportData
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_LIST.INIT,
    getPublisherReportData
  );

  // yield takeLatest(
  //   WebDashboardConst.VAST.CONFIG_TABEL.INIT,
  //   getVASTTrackersData
  // )
  yield takeLatest(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_DELETE.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_REPORT.CONFIG_DELETE,
      WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_DELETE
    )
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_SAVE.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_REPORT.CONFIG_SAVE,
      WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_SAVE
    )
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.SASS_ACCOUNT.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_REPORT.SASS_ACCOUNT,
      WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.SASS_ACCOUNT
    )
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_PUB_VARIATION.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_REPORT.CONFIG_PUB_VARIATION,
      WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_PUB_VARIATION
    )
  );

  // yield takeLatest(WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.configDashboardType.INIT, getPublisherReportData);
  // yield takeLatest(WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.configFRAUD_CATEGORY.INIT, getPublisherReportData);
  // yield takeLatest(WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.configFraudSubCategory.INIT, getPublisherReportData);
  // yield takeLatest(WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.congifPubVariation.INIT, getPublisherReportData);
  // yield takeLatest(WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.CONFIG_SAVE.INIT, getPublisherReportData);
  // yield takeLatest(WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.configTabel.INIT, getPublisherReportData);
  // yield takeLatest(WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.configUpdate.INIT, getPublisherReportData);
  // yield takeLatest(WebDashboardConst.DASHBOARD.PUBLISHER_REPORT.confiDelete.INIT, getPublisherReportData);

  // vast filter saga

  yield takeLatest(
    WebDashboardConst.FILTER.VAST.VAST_LOCATION_FILTER_LIST.INIT,
    getVastFilterData
  );

  yield takeLatest(
    WebDashboardConst.FILTER.VAST.VAST_CAMPAIGN_FILTER_LIST.INIT,
    getVastFilterData
  );

  yield takeLatest(
    WebDashboardConst.FILTER.VAST.VAST_PUBLISHER_FILTER_LIST.INIT,
    getVastFilterData
  );
// google dashboard 
  yield takeLatest(
    WebDashboardConst.DASHBOARD.GOOGLE_DASHBOARD.ANALYTICS.INIT,
    getGoogleDashboardData
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.GOOGLE_DASHBOARD.FRAUD_CATEGORY.INIT,
    getGoogleDashboardData
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.GOOGLE_DASHBOARD.FRAUD_GEO.INIT,
    getGoogleDashboardData
  );
  yield takeLatest(
    WebDashboardConst.DASHBOARD.GOOGLE_DASHBOARD.FRAUD_CATEGORY.INIT,
    getGoogleDashboardData
  );
  // google dash ends


  yield takeLatest(
    WebDashboardConst.FILTER.CLICK.CLICK_DEVICE_TYPE.INIT,
    getFilterData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.CLICK.CLICK_FRAUD_CATEGORY.INIT,
    getFilterData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.CLICK.CLICK_FRAUD_SUB_CATEGORY.INIT,
    getFilterData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.CLICK.CLICK_PUBLISHER_NAME.INIT,
    getFilterData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.CLICK.CLICK_CAMPAIGN_ID.INIT,
    getFilterData(DASHBOARD_TYPE.CLICK)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.CLICK.CLICK_FILTER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.CLICK)
  );

  yield takeLatest(
    WebDashboardConst.FILTER.EVENT.EVENT_CAMPAIGN_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.EVENT.EVENT_PAGE_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.EVENT.EVENT_PUBLISHER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.EVENT.EVENT_SUB_PUBLISHER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.EVENT.EVENT_CHANNEL_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.EVENT.EVENT_EVENT_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.EVENT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.EVENT.EVENT_FILTER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.EVENT)
  );

  yield takeLatest(
    WebDashboardConst.FILTER.REPORT_FILTERS.FIELD_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.REPORT_FILTERS)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.REPORT_FILTERS.FRAUD_CATEGORY.INIT,
    getFilterData(DASHBOARD_TYPE.REPORT_FILTERS)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.REPORT_FILTERS.PUBLISHER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.REPORT_FILTERS)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.REPORT_FILTERS.REPORT_FILTER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.REPORT_FILTERS)
  );

  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_PUBLISHER_ID_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_CHANNEL_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_CAMPAIGN_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_CREATIVE_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_SYSTEM_DOMAIN_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_PLACEMENT_ID_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_FRAUD_CATEGORY_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_FRAUD_SUB_CATEGORY_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_PAGE_ID_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_SUB_PUBLISHER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.IMPRESSION.IMPRESSION_FILTER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.IMPRESSION)
  );

  yield takeLatest(
    WebDashboardConst.FILTER.VISIT.VISIT_FILTER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.VISIT.VISIT_CHANNEL_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.VISIT.VISIT_CAMPAIGN_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.VISIT.VISIT_PLACEMENT_ID_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.VISIT.VISIT_FRAUD_CATEGORY_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.VISIT.VISIT_FRAUD_SUB_CATEGORY_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.VISIT.VISIT_PAGE_ID_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.VISIT)
  );
  yield takeLatest(
    WebDashboardConst.FILTER.VISIT.VISIT_SUB_PUBLISHER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.VISIT)
  );
  // yield takeLatest(WebDashboardConst.FILTER.VISIT.VISIT_PLACEMENT_TYPE_LIST.INIT, getFilterData(DASHBOARD_TYPE.VISIT));
  yield takeLatest(
    WebDashboardConst.FILTER.VISIT.VISIT_PUBLISHER_LIST.INIT,
    getFilterData(DASHBOARD_TYPE.VISIT)
  );
}
