export const TOTAL_INCIDENTS = 'TOTAL_INCIDENTS';
export const TOTAL_INCIDENTS_SUCCESS = 'TOTAL_INCIDENTS_SUCCESS';
export const TOTAL_INCIDENTS_ERROR = 'TOTAL_INCIDENTS_ERROR';

export const INCIDENT_VOLUMES = 'INCIDENT_VOLUMES';
export const INCIDENT_VOLUMES_SUCCESS = 'INCIDENT_VOLUMES_SUCCESS';
export const INCIDENT_VOLUMES_ERROR = 'INCIDENT_VOLUMES_ERROR';

export const ACTIVECASE_CHANNEL = 'ACTIVECASE_CHANNEL';
export const ACTIVECASE_CHANNEL_SUCCESS = 'ACTIVECASE_CHANNEL_SUCCESS';
export const ACTIVECASE_CHANNEL_ERROR = 'ACTIVECASE_CHANNEL_ERROR';

export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS';

export const SUBCHANNEL_INIT = 'SUBCHANNEL_INIT';
export const SUBCHANNEL_SUCCESS = 'SUBCHANNEL_SUCCESS';
export const SUBCHANNEL_ERROR = 'SUBCHANNEL_ERROR';

// export const TOPTEN_LOCATION_INIT = 'TOPTEN_LOCATION_INIT';
// export const TOPTEN_LOCATION_SUCCESS = 'TOPTEN_LOCATION_SUCCESS';
// export const TOPTEN_LOCATION_ERROR = 'TOPTEN_LOCATION_ERROR';

export const CATEGORY_LEVEL_COUNT = 'CATEGORY_LEVEL_COUNT';
export const CATEGORY_LEVEL_COUNT_SUCCESS = 'CATEGORY_LEVEL_COUNT_SUCCESS';
export const CATEGORY_LEVEL_COUNT_ERROR = 'CATEGORY_LEVEL_COUNT_ERROR';

// export const PUBLISHER_LEVEL_COUNT = 'PUBLISHER_LEVEL_COUNT';
// export const PUBLISHER_LEVEL_COUNT_SUCCESS = 'PUBLISHER_LEVEL_COUNT_SUCCESS';
// export const PUBLISHER_LEVEL_COUNT_ERROR = 'PUBLISHER_LEVEL_COUNT_ERROR';

export const SET_SIDE_DRAWER = 'SET_SIDE_DRAWER';

//web
export const WEB_TOTAL_INCIDENTS = 'WEB_TOTAL_INCIDENTS';
export const WEB_TOTAL_INCIDENTS_SUCCESS = 'WEB_TOTAL_INCIDENTS_SUCCESS';
export const WEB_TOTAL_INCIDENTS_ERROR = 'WEB_TOTAL_INCIDENTS_ERROR';

export const FRAUD_REASON_TREND = 'FRAUD_REASON_TREND';
export const FRAUD_REASON_TREND_SUCCESS = 'FRAUD_REASON_TREND_SUCCESS';
export const FRAUD_REASON_TREND_ERROR = 'FRAUD_REASON_TREND_ERROR';

export const FRAUD_VISIT = 'FRAUD_VISIT';
export const FRAUD_VISIT_SUCCESS = 'FRAUD_VISIT_SUCCESS';
export const FRAUD_VISIT_ERROR = 'FRAUD_VISIT_ERROR';

export const CUMULATIVE_IMPRESSION_TREND_INIT =
  'CUMULATIVE_IMPRESSION_TREND_INIT';
export const CUMULATIVE_IMPRESSION_TREND_SUCCESS =
  'CUMULATIVE_IMPRESSION_TREND_SUCCESS';
export const CUMULATIVE_IMPRESSION_TREND_ERROR =
  'CUMULATIVE_IMPRESSION_TREND_ERROR';

export const TOPTEN_LOCATION_INIT = 'TOPTEN_LOCATION_INIT';
export const TOPTEN_LOCATION_SUCCESS = 'TOPTEN_LOCATION_SUCCESS';
export const TOPTEN_LOCATION_ERROR = 'TOPTEN_LOCATION_ERROR';

export const TOPTEN_WEB_LOCATION_INIT = 'TOPTEN_WEB_LOCATION_INIT';
export const TOPTEN_WEB_LOCATION_SUCCESS = 'TOPTEN_WEB_LOCATION_SUCCESS';
export const TOPTEN_WEB_LOCATION_ERROR = 'TOPTEN_WEB_LOCATION_ERROR';

export const TOP_EXCHANGE_TRAFFIC_COUNT = 'TOP_EXCHANGE_TRAFFIC_COUNT';
export const TOP_EXCHANGE_TRAFFIC_SUCCESS = 'TOP_EXCHANGE_TRAFFIC_SUCCESS';
export const TOP_EXCHANGE_TRAFFIC_ERROR = 'TOP_EXCHANGE_TRAFFIC_ERROR';

export const PUBLISHER_LEVEL_COUNT = 'PUBLISHER_LEVEL_COUNT';
export const PUBLISHER_LEVEL_COUNT_SUCCESS = 'PUBLISHER_LEVEL_COUNT_SUCCESS';
export const PUBLISHER_LEVEL_COUNT_ERROR = 'PUBLISHER_LEVEL_COUNT_ERROR';

//yeah hai high tarffic
export const HIGH_FRAUD_TRAFFIC_COUNT = 'HIGH_FRAUD_TRAFFIC_COUNT';
export const HIGH_FRAUD_TRAFFIC_COUNT_SUCCESS =
  'HIGH_FRAUD_TRAFFIC_COUNT_SUCCESS';
export const HIGH_FRAUD_TRAFFIC_COUNT_ERROR = 'HIGH_FRAUD_TRAFFIC_COUNT_ERROR';

// yeah hai top 10 placement

export const TOP_TEN_PLACEMENT_COUNT = 'TOP_TEN_PLACEMENT_COUNT';
export const TOP_TEN_PLACEMENT_COUNT_SUCCESS =
  'TOP_TEN_PLACEMENT_COUNT_SUCCESS';
export const TOP_TEN_PLACEMENT_COUNT_ERROR = 'TOP_TEN_PLACEMENT_COUNT_ERROR';

//VISIT PLOTS

export const TOP_FIVE_CLEAN_PUBLISHER = 'TOP_FIVE_CLEAN_PUBLISHER';
export const TOP_FIVE_CLEAN_PUBLISHER_SUCCESS =
  'TOP_FIVE_CLEAN_PUBLISHER_SUCCESS';
export const TOP_FIVE_CLEAN_PUBLISHER_ERROR = 'TOP_FIVE_CLEAN_PUBLISHER_ERROR';

export const TOP_FIVE_FRAUD_PUBLISHER = 'TOP_FIVE_FRAUD_PUBLISHER';
export const TOP_FIVE_FRAUD_PUBLISHER_SUCCESS =
  'TOP_FIVE_FRAUD_PUBLISHER_SUCCESS';
export const TOP_FIVE_FRAUD_PUBLISHER_ERROR = 'TOP_FIVE_FRAUD_PUBLISHER_ERROR';

export const FRAUD_TREND = 'FRAUD_TREND';
export const FRAUD_TREND_SUCCESS = 'FRAUD_TREND_SUCCESS';
export const FRAUD_TREND_ERROR = 'FRAUD_TREND_ERROR';

export const FRAUD_TREND_MONTHLY = 'FRAUD_TREND_MONTHLY';
export const FRAUD_TREND_MONTHLY_SUCCESS = 'FRAUD_TREND_MONTHLY_SUCCESS';
export const FRAUD_TREND_MONTHLY_ERROR = 'FRAUD_TREND_MONTHLY_ERROR';

//Click

export const CLICK_TOTAL_INCIDENTS = 'CLICK_TOTAL_INCIDENTS';
export const CLICK_TOTAL_INCIDENTS_SUCCESS = 'CLICK_TOTAL_INCIDENTS_SUCCESS';
export const CLICK_TOTAL_INCIDENTS_ERROR = 'CLICK_TOTAL_INCIDENTS_ERROR';

export const CLICK_TOP_FIVE_CLEAN_PUBLISHER = 'CLICK_TOP_FIVE_CLEAN_PUBLISHER';
export const CLICK_TOP_FIVE_CLEAN_PUBLISHER_SUCCESS =
  'CLICK_TOP_FIVE_CLEAN_PUBLISHER_SUCCESS';
export const CLICK_TOP_FIVE_CLEAN_PUBLISHER_ERROR =
  'CLICK_TOP_FIVE_CLEAN_PUBLISHER_ERROR';

export const CLICK_TOP_FIVE_FRAUD_PUBLISHER = 'CLICK_TOP_FIVE_FRAUD_PUBLISHER';
export const CLICK_TOP_FIVE_FRAUD_PUBLISHER_SUCCESS =
  'CLICK_TOP_FIVE_FRAUD_PUBLISHER_SUCCESS';
export const CLICK_TOP_FIVE_FRAUD_PUBLISHER_ERROR =
  'CLICK_TOP_FIVE_FRAUD_PUBLISHER_ERROR';

export const CLICK_FRAUD_TREND = 'CLICK_FRAUD_TREND';
export const CLICK_FRAUD_TREND_SUCCESS = 'CLICK_FRAUD_TREND_SUCCESS';
export const CLICK_FRAUD_TREND_ERROR = 'CLICK_FRAUD_TREND_ERROR';

export const CLICK_FRAUD_TREND_MONTHLY = 'CLICK_FRAUD_TREND_MONTHLY';
export const CLICK_FRAUD_TREND_MONTHLY_SUCCESS =
  'CLICK_FRAUD_TREND_MONTHLY_SUCCESS';
export const CLICK_FRAUD_TREND_MONTHLY_ERROR =
  'CLICK_FRAUD_TREND_MONTHLY_ERROR';

export const CLICK_FRAUD_VISIT = 'CLICK_FRAUD_VISIT';
export const CLICK_FRAUD_VISIT_SUCCESS = 'CLICK_FRAUD_VISIT_SUCCESS';
export const CLICK_FRAUD_VISIT_ERROR = 'CLICK_FRAUD_VISIT_ERROR';

// Event dashboard constant----------------------------------------------------------------------- //

// export const MONTHWISE_FRAUD_TREND_INIT= 'MONTHWISE_FRAUD_TREND_INIT';
// export const MONTHWISE_FRAUD_TREND_INIT_SUCCESS = 'MONTHWISE_FRAUD_TREND_INIT_SUCCESS';
// export const MONTHWISE_FRAUD_TREND_INIT_ERROR = 'MONTHWISE_FRAUD_TREND_INIT_ERROR';

// export const TOTAL_EVENT_LIST_INIT= 'TOTAL_EVENT_LIST_INIT';
// export const TOTAL_EVENT_LIST_INIT_SUCCESS = 'TOTAL_EVENT_LIST_INIT_SUCCESS';
// export const TOTAL_EVENT_LIST_INIT_ERROR = 'TOTAL_EVENT_LIST_INIT_ERROR';

// export const FRAUD_EVENT_LIST_INIT= 'FRAUD_EVENT_LIST_INIT';
// export const FRAUD_EVENT_LIST_INIT_SUCCESS = 'FRAUD_EVENT_LIST_INIT_SUCCESS';
// export const FRAUD_EVENT_LIST_INIT_ERROR = 'FRAUD_EVENT_LIST_INIT_ERROR';

// export const FRAUD_PERCENT_EVENT_LIST_INIT= 'FRAUD_PERCENT_EVENT_LIST_INIT';
// export const FRAUD_PERCENT_EVENT_LIST_INIT_SUCCESS = 'FRAUD_PERCENT_EVENT_LIST_INIT_SUCCESS';
// export const FRAUD_PERCENT_EVENT_LIST_INIT_ERROR = 'FRAUD_PERCENT_EVENT_LIST_INIT_ERROR';

// export const EVENT_FRAUD_CATEGORY_INIT= 'EVENT_FRAUD_CATEGORY_INIT';
// export const EVENT_FRAUD_CATEGORY_INIT_SUCCESS = 'EVENT_FRAUD_CATEGORY_INIT_SUCCESS';
// export const EVENT_FRAUD_CATEGORY_INIT_ERROR = 'EVENT_FRAUD_CATEGORY_ERROR';

// export const EVENT_CLEAN_PUBLISHER_NAME_INIT= 'EVENT_CLEAN_PUBLISHER_NAME_INIT';
// export const EVENT_CLEAN_PUBLISHER_NAME_INIT_SUCCESS = 'EVENT_CLEAN_PUBLISHER_NAME_INIT_SUCCESS';
// export const EVENT_CLEAN_PUBLISHER_NAME_INIT_ERROR = 'EVENT_CLEAN_PUBLISHER_NAME_INIT_ERROR';

// export const EVENT_FRAUD_PUBLISHER_NAME_INIT= 'EVENT_FRAUD_PUBLISHER_NAME_INIT';
// export const EVENT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS = 'EVENT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS';
// export const EVENT_FRAUD_PUBLISHER_NAME_INIT_ERROR = 'EVENT_FRAUD_PUBLISHER_NAME_INIT_ERROR';

// export const EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT= 'EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT';
// export const EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS = 'EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS';
// export const EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT_ERROR = 'EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT_ERROR';

// export const TOTAL_EVENT_FRAUD_EVENT_INIT= 'TOTAL_EVENT_FRAUD_EVENT_INIT';
// export const TOTAL_EVENT_FRAUD_EVENT_INIT_SUCCESS = 'TOTAL_EVENT_FRAUD_EVENT_INIT_SUCCESS';
// export const TOTAL_EVENT_FRAUD_EVENT_INIT_ERROR = 'TOTAL_EVENT_FRAUD_EVENT_INIT_ERROR';

// export const FRAUD_EVENT_BY_DATE_INIT= 'FRAUD_EVENT_BY_DATE_INIT';
// export const FRAUD_EVENT_BY_DATE_INIT_SUCCESS = 'FRAUD_EVENT_BY_DATE_INIT_SUCCESS';
// export const FRAUD_EVENT_BY_DATE_INIT_ERROR = 'FRAUD_EVENT_BY_DATE_INIT_ERROR';

export const FRAUD_PUBLISHER_WISE = 'FRAUD_PUBLISHER_WISE';
export const FRAUD_PUBLISHER_WISE_SUCCESS = 'FRAUD_PUBLISHER_WISE_SUCCESS';
export const FRAUD_PUBLISHER_WISE_ERROR = 'FRAUD_PUBLISHER_WISE_ERROR';

// export const EVENT_TABEL_INIT= 'FRAUD_EVENT_BY_DATE_INIT';
// export const EVENT_TABEL_INIT_SUCCESS = 'EVENT_TABEL_INIT_SUCCESS';
// export const EVENT_TABEL_INIT_ERROR = 'EVENT_TABEL_INIT_ERROR';

// App Install Dashboard

export const INSTALL_TOTAL_INSTALL_INIT = 'INSTALL_TOTAL_INSTALL_INIT';
export const INSTALL_TOTAL_INSTALL_INIT_SUCCESS =
  'INSTALL_TOTAL_INSTALL_INIT_SUCCESS';
export const INSTALL_TOTAL_INSTALL_INIT_ERROR =
  'INSTALL_TOTAL_INSTALL_INIT_ERROR';

export const INSTALL_FRAUDULENT_INSTALL_INIT =
  'INSTALL_FRAUDULENT_INSTALL_INIT';
export const INSTALL_FRAUDULENT_INSTALL_INIT_SUCCESS =
  'INSTALL_FRAUDULENT_INSTALL_INIT_SUCCESS';
export const INSTALL_FRAUDULENT_INSTALL_INIT_ERROR =
  'INSTALL_FRAUDULENT_INSTALL_INIT_ERROR';

export const INSTALL_FRAUD_CATEGORY_INIT = 'INSTALL_FRAUD_CATEGORY_INIT_OLD';
export const INSTALL_FRAUD_CATEGORY_INIT_SUCCESS =
  'INSTALL_FRAUD_CATEGORY_INIT_OLD_SUCCESS';
export const INSTALL_FRAUD_CATEGORY_INIT_ERROR =
  'INSTALL_FRAUD_CATEGORY_INIT_OLD_ERROR';

export const INSTALL_FRAUD_SUB_CATEGORY_INIT =
  'INSTALL_FRAUD_SUB_CATEGORY_INIT';
export const INSTALL_FRAUD_SUB_CATEGORY_INIT_SUCCESS =
  'INSTALL_FRAUD_SUB_CATEGORY_INIT_SUCCESS';
export const INSTALL_FRAUD_SUB_CATEGORY_INIT_ERROR =
  'INSTALL_FRAUD_SUB_CATEGORY_INIT_ERROR';

export const INSTALL_FRAUD_CATEGORY_LIST_INIT =
  'INSTALL_FRAUD_CATEGORY_LIST_INIT';
export const INSTALL_FRAUD_CATEGORY_LIST_INIT_SUCCESS =
  'INSTALL_FRAUD_CATEGORY_LIST_INIT_SUCCESS';
export const INSTALL_FRAUD_CATEGORY_LIST_INIT_ERROR =
  'INSTALL_FRAUD_CATEGORY_LIST_INIT_ERROR';

export const INSTALL_CLEAN_EVENT_INIT = 'INSTALL_CLEAN_EVENT_INIT';
export const INSTALL_CLEAN_EVENT_INIT_SUCCESS =
  'INSTALL_CLEAN_EVENT_INIT_SUCCESS';
export const INSTALL_CLEAN_EVENT_INIT_ERROR = 'INSTALL_CLEAN_EVENT_INIT_ERROR';

export const INSTALL_CLEAN_DEVICE_COUNT_INIT =
  'INSTALL_CLEAN_DEVICE_COUNT_INIT';
export const INSTALL_CLEAN_DEVICE_COUNT_INIT_SUCCESS =
  'INSTALL_CLEAN_DEVICE_COUNT_INIT_SUCCESS';
export const INSTALL_CLEAN_DEVICE_COUNT_INIT_ERROR =
  'INSTALL_CLEAN_DEVICE_COUNT_INIT_ERROR';

export const INSTALL_FRAUDULENT_EVENT_INIT = 'INSTALL_FRAUDULENT_EVENT_INIT';
export const INSTALL_FRAUDULENT_EVENT_INIT_SUCCESS =
  'INSTALL_FRAUDULENT_EVENT_INIT_SUCCESS';
export const INSTALL_FRAUDULENT_EVENT_INIT_ERROR =
  'INSTALL_FRAUDULENT_EVENT_INIT_ERROR';

export const INSTALL_CLICK_FRAUD_DATE_INIT = 'INSTALL_CLICK_FRAUD_DATE_INIT';
export const INSTALL_CLICK_FRAUD_DATE_INIT_SUCCESS =
  'INSTALL_CLICK_FRAUD_DATE_INIT_SUCCESS';
export const INSTALL_CLICK_FRAUD_DATE_INIT_ERROR =
  'INSTALL_CLICK_FRAUD_DATE_INIT_ERROR';

export const INSTALL_CLICK_FRAUD_PUBLISHER_INIT =
  'INSTALL_CLICK_FRAUD_PUBLISHER_INIT';
export const INSTALL_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS =
  'INSTALL_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS';
export const INSTALL_CLICK_FRAUD_PUBLISHER_INIT_ERROR =
  'INSTALL_CLICK_FRAUD_PUBLISHER_INIT_ERROR';

export const INSTALL_FRAUD_DATE_INIT = 'INSTALL_FRAUD_DATE_INIT';
export const INSTALL_FRAUD_DATE_INIT_SUCCESS =
  'INSTALL_FRAUD_DATE_INIT_SUCCESS';
export const INSTALL_FRAUD_DATE_INIT_ERROR = 'INSTALL_FRAUD_DATE_INIT_ERROR';

export const INSTALL_FRAUD_PUBLISHER_INIT = 'INSTALL_FRAUD_PUBLISHER_INIT';
export const INSTALL_FRAUD_PUBLISHER_INIT_SUCCESS =
  'INSTALL_FRAUD_PUBLISHER_INIT_SUCCESS';
export const INSTALL_FRAUD_PUBLISHER_INIT_ERROR =
  'INSTALL_FRAUD_PUBLISHER_INIT_ERROR';

export const INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT =
  'INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT';
export const INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS =
  'INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS';
export const INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR =
  'INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR';

export const INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT =
  'INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT';
export const INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS =
  'INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS';
export const INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR =
  'INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR';

export const INSTALL_SUB_PUBLISHER_DATE_INIT =
  'INSTALL_SUB_PUBLISHER_DATE_INIT';
export const INSTALL_SUB_PUBLISHER_DATE_INIT_SUCCESS =
  'INSTALL_SUB_PUBLISHER_DATE_INIT_SUCCESS';
export const INSTALL_SUB_PUBLISHER_DATE_INIT_ERROR =
  'INSTALL_SUB_PUBLISHER_DATE_INIT_ERROR';

export const INSTALL_SUB_PUBLISHER_INIT = 'INSTALL_SUB_PUBLISHER_INIT';
export const INSTALL_SUB_PUBLISHER_INIT_SUCCESS =
  'INSTALL_SUB_PUBLISHER_INIT_SUCCESS';
export const INSTALL_SUB_PUBLISHER_INIT_ERROR =
  'INSTALL_SUB_PUBLISHER_INIT_ERROR';

export const COLORS_INIT = 'COLORS_INIT';
export const COLORS_INIT_SUCCESS = 'COLORS_INIT_SUCCESS';
export const COLORS_INIT_ERROR = 'COLORS_INIT_ERROR';

//App Event Dashboard

export const APP_EVENT_TOTAL_EVENT_INIT = 'APP_EVENT_TOTAL_EVENT_INIT';
export const APP_INSTALL_TOTAL_INSTALL_INIT_SUCCESS =
  'APP_INSTALL_TOTAL_INSTALL_INIT_SUCCESS';
export const APP_INSTALL_TOTAL_INSTALL_INIT_ERROR =
  'APP_INSTALL_TOTAL_INSTALL_INIT_ERROR';

export const APP_EVENT_FRAUDULENT_EVENTS_INIT =
  'APP_EVENT_FRAUDULENT_EVENTS_INIT';
export const APP_EVENT_FRAUDULENT_EVENT_INIT_SUCCESS =
  'APP_EVENT_FRAUDULENT_EVENT_INIT_SUCCESS';
export const APP_EVENT_FRAUDULENT_EVENT_INIT_ERROR =
  'APP_EVENT_FRAUDULENT_EVENT_INIT_ERROR';

export const APP_EVENT_FRAUD_CATEGORY_INIT = 'APP_EVENT_FRAUD_CATEGORY_INIT';
export const APP_EVENT_FRAUD_CATEGORY_INIT_SUCCESS =
  'APP_EVENT_FRAUD_CATEGORY_INIT_SUCCESS';
export const APP_EVENT_FRAUD_CATEGORY_INIT_ERROR =
  'APP_EVENT_FRAUD_CATEGORY_INIT_ERROR';

export const APP_EVENT_FRAUD_SUB_CATEGORY_INIT =
  'APP_EVENT_FRAUD_SUB_CATEGORY_INIT';
export const APP_EVENT_FRAUD_SUB_CATEGORY_INIT_SUCCESS =
  'APP_EVENT_FRAUD_SUB_CATEGORY_INIT_SUCCESS';
export const APP_EVENT_FRAUD_SUB_CATEGORY_INIT_ERROR =
  'APP_EVENT_FRAUD_SUB_CATEGORY_INIT_ERROR';

export const APP_EVENT_CLEAN_EVENT_INIT = 'APP_EVENT_CLEAN_EVENT_INIT';
export const APP_EVENT_CLEAN_EVENT_INIT_SUCCESS =
  'APP_EVENT_CLEAN_EVENT_INIT_SUCCESS';
export const APP_EVENT_CLEAN_EVENT_INIT_ERROR =
  'APP_EVENT_CLEAN_EVENT_INIT_ERROR';

export const APP_EVENT_CLEAN_DEVICE_COUNT_INIT =
  'APP_EVENT_CLEAN_DEVICE_COUNT_INIT';
export const APP_EVENT_CLEAN_DEVICE_COUNT_INIT_SUCCESS =
  'APP_EVENT_CLEAN_DEVICE_COUNT_INIT_SUCCESS';
export const APP_EVENT_CLEAN_DEVICE_COUNT_INIT_ERROR =
  'APP_EVENT_CLEAN_DEVICE_COUNT_INIT_ERROR';

export const APP_EVENT_FRAUDULENT_EVENT_INIT =
  'APP_EVENT_FRAUDULENT_EVENT_INIT';
export const APP_EVENT_FRAUDULENT_EVENTS_INIT_SUCCESS =
  'APP_EVENT_FRAUDULENT_EVENTS_INIT_SUCCESS';
export const APP_EVENT_FRAUDULENT_EVENTS_INIT_ERROR =
  'APP_EVENT_FRAUDULENT_EVENTS_INIT_ERROR';

export const APP_EVENT_CLICK_FRAUD_DATE_INIT =
  'APP_EVENT_CLICK_FRAUD_DATE_INIT';
export const APP_EVENT_CLICK_FRAUD_DATE_INIT_SUCCESS =
  'APP_EVENT_CLICK_FRAUD_DATE_INIT_SUCCESS';
export const APP_EVENT_CLICK_FRAUD_DATE_INIT_ERROR =
  'APP_EVENT_CLICK_FRAUD_DATE_INIT_ERROR';

export const APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT =
  'APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT';
export const APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS =
  'APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS';
export const APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR =
  'APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR';

export const APP_EVENT_FRAUD_DATE_INIT = 'APP_EVENT_FRAUD_DATE_INIT';
export const APP_EVENT_FRAUD_DATE_INIT_SUCCESS =
  'APP_EVENT_FRAUD_DATE_INIT_SUCCESS';
export const APP_EVENT_FRAUD_DATE_INIT_ERROR =
  'APP_EVENT_FRAUD_DATE_INIT_ERROR';

export const APP_EVENT_FRAUD_PUBLISHER_INIT = 'APP_EVENT_FRAUD_PUBLISHER_INIT';
export const APP_EVENT_FRAUD_PUBLISHER_INIT_SUCCESS =
  'APP_EVENT_FRAUD_PUBLISHER_INIT_SUCCESS';
export const APP_EVENT_FRAUD_PUBLISHER_INIT_ERROR =
  'APP_EVENT_FRAUD_PUBLISHER_INIT_ERROR';

export const APP_EVENT_FRAUD_SUB_PUBLISHER_INIT =
  'APP_EVENT_FRAUD_SUB_PUBLISHER_INIT';
export const APP_EVENT_FRAUD_SUB_PUBLISHER_INIT_SUCCESS =
  'APP_EVENT_FRAUD_SUB_PUBLISHER_INIT_SUCCESS';
export const APP_EVENT_FRAUD_SUB_PUBLISHER_INIT_ERROR =
  'APP_EVENT_FRAUD_SUB_PUBLISHER_INIT_ERROR';

export const APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT =
  'APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT';
export const APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS =
  'APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS';
export const APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR =
  'APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR';

export const APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT =
  'APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT';
export const APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS =
  'APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS';
export const APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR =
  'APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR';

//App Reengagement Event

export const APP_REENGAGEMENT_EVENT_TOTAL_EVENT_INIT =
  'APP_REENGAGEMENT_EVENT_TOTAL_EVENT_INIT';
export const APP_REENGAGEMENT_EVENT_TOTAL_INSTALL_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_TOTAL_INSTALL_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_TOTAL_INSTALL_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_TOTAL_INSTALL_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT =
  'APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT';
export const APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT =
  'APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT';
export const APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT =
  'APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT';
export const APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT_ERROR';

// export const APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT= 'APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT';
// export const APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT_SUCCESS = 'APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT_SUCCESS';
// export const APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT_ERROR = 'APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT =
  'APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT';
export const APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT =
  'APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT';
export const APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT =
  'APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT';
export const APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT =
  'APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT';
export const APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT =
  'APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT';
export const APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT =
  'APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT';
export const APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT =
  'APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT';
export const APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_INIT =
  'APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_INIT';
export const APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_SUCCESS =
  'APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_SUCCESS';
export const APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_ERROR =
  'APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_ERROR';

export const APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT =
  'APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT';
export const APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR';

export const APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT =
  'APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT';
export const APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS =
  'APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS';
export const APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR =
  'APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR';

//APP Publisher summary

export const PUBLISHER_APP_GET_INIT = 'PUBLISHER_APP_GET_INIT';
export const PUBLISHER_APP_GET_INIT_SUCCESS = 'PUBLISHER_APP_GET_INIT_SUCCESS';
export const PUBLISHER_APP_GET_INIT_ERROR = 'PUBLISHER_APP_GET_INIT_ERROR';

export const PUBLISHER_APP_GET_DOWNLOAD_INIT =
  'PUBLISHER_APP_GET_DOWNLOAD_INIT';
export const PUBLISHER_APP_GET_DOWNLOAD_INIT_SUCCESS =
  'PUBLISHER_APP_GET_DOWNLOAD_INIT_SUCCESS';
export const PUBLISHER_APP_GET_DOWNLOAD_INIT_ERROR =
  'PUBLISHER_APP_GET_DOWNLOAD_INIT_ERROR';

//App Re-engagement Clicks Dashboard =-=-=-=-=-=-=-=-=-=-=-==---=-=-=-=-=-=-=-=-=-=

export const APP_REENGAGEMENT_CLICK_TOTAL_CLICK_INIT =
  'APP_REENGAGEMENT_CLICK_TOTAL_CLICK_INIT';
export const APP_REENGAGEMENT_CLICK_TOTAL_INSTALL_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_TOTAL_INSTALL_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_TOTAL_INSTALL_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_TOTAL_INSTALL_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT =
  'APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT';
export const APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT =
  'APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT';
export const APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT =
  'APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT';
export const APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT_ERROR';

//5
export const APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT =
  'APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT';
export const APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT =
  'APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT';
export const APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT =
  'APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT';
export const APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT =
  'APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT';
export const APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT =
  'APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT';
export const APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT =
  'APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT';
export const APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_FRAUD_PUBLISHER_INIT =
  'APP_REENGAGEMENT_CLICK_FRAUD_PUBLISHER_INIT';
export const APP_REENGAGEMENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT =
  'APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT';
export const APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR';

export const APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT =
  'APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT';
export const APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR';

//sub publisher

export const APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT =
  'APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT';
export const APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT_SUCCESS =
  'APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT_SUCCESS';
export const APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT_ERROR =
  'APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT_ERROR';

export const PUBLISHER_APP_GET_DOWNLOAD_INIT_CLEAR =
  'PUBLISHER_APP_GET_DOWNLOAD_INIT_CLEAR';
