import { takeLatest, put, call } from 'redux-saga/effects';
import * as constant from '../constants/CampaignConstants';
import { API } from '../setupAxios';
import { omit } from 'lodash';
import { EndPoints, MtrackitApiUrl, PythonApiUrl } from '../helpers/Endpoints';

// export function* ViewCampaignIncidentsSaga(payload) {
// console.log('payload  --------', payload)
//     try{
//         const response = yield call(CampaignServices.ViewCampaignincidents ,payload.campaigndata);
//         yield put({ type: constant.CAMPAIGN_INCIDENTS_SUCCESS,response })
//     } catch (error) {
//         yield put({ type: constant.CAMPAIGN_INCIDENTS_ERROR,error })
//     }
// }

export function* ViewCampaignSaga(payload, packageName) {
  try {
    const url = PythonApiUrl + EndPoints.campaign_add;
    const response = yield API.post(url, payload.campaignAddData);
    // const response = yield call(CampaignServices.ViewCampaign ,payload.campaignData);
    yield put({ type: constant.CAMPAIGN_ADD_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CAMPAIGN_ADD_ERROR, error: '400' });
  }
}



export function* ViewGetCampaignSaga(payload) {
  try {
    let url = PythonApiUrl + EndPoints.campaign;
    const params = payload.campaignData;
    const response = yield API.get(url, { params });
    yield put({ type: constant.CAMPAIGN_GET_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CAMPAIGN_GET_ERROR, error });
  }
}



export function* ViewUpdateCampaignSaga(data) {
  try {
    const id = data && data.campaignUpdatetData.id;
    const formData = data && data.campaignUpdatetData;
    const response = yield API.put(
      `${MtrackitApiUrl}${EndPoints.campaign}/${id}`,
      omit(formData, ['id', 'customer_name', 'agency_name', 'campaign_name'])
    );
    // const response = yield call(CampaignServices.ViewCampaignUpdate ,payload.campaignUpdatetData);
    yield put({ type: constant.CAMPAIGN_UPDATE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CAMPAIGN_UPDATE_ERROR, error });
  }
}

export function* ViewDeleteCampaignSaga(payload) {
  try {
    const { id } = payload;
    // const url = MtrackitApiUrl + EndPoints.publisher
    const response = yield API.delete(`${MtrackitApiUrl}${EndPoints.campaign}/${id}`);
    // const response = yield call(CampaignServices.ViewCampaignDelete ,payload);
    yield put({ type: constant.CAMPAIGN_DELETE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CAMPAIGN_DELETE_ERROR, error });
  }
}

export default function* CampaignSaga() {
  yield takeLatest(constant.CAMPAIGN_ADD, ViewCampaignSaga);
  yield takeLatest(constant.CAMPAIGN_GET, ViewGetCampaignSaga);
  yield takeLatest(constant.CAMPAIGN_UPDATE, ViewUpdateCampaignSaga);
  yield takeLatest(constant.CAMPAIGN_DELETE, ViewDeleteCampaignSaga);
}
