import React, { useMemo, useState, useEffect } from 'react';
import { AsideMenuList } from '../../../Components/Common/Menu/AsideMenuList';
import { useHtmlClassService } from '../../../../src/_metronic/layout/_core/MetronicLayout';
import Select from 'react-select';
import {
  FetchPackagename,
  FetchMenulist,
} from '../../../redux/actions/CommonActions';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import * as constant from '../../../redux/constants/CommonConstants';

export function AsideMenu() {
  const package_name = useSelector(state => state.common.package_name);

  const [defValue, setDefValue] = useState(null);
  const [packages, setPackges] = useState(null);

  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes('aside_menu'),
      ulClasses: uiService.getClasses('aside_menu_nav', true),
      asideClassesFromConfig: uiService.getClasses('aside_menu', true),
    };
  }, [uiService]);

  const getLocalPackname = localStorage.getItem('selectedPackage');
  const getPackname =
    getLocalPackname !== 'undefined' ? JSON.parse(getLocalPackname) : '';

  const handleOnchange = e => {
    setDefValue(e);
    localStorage.setItem('dpackage', e.value);
    localStorage.setItem('selectedPackage', JSON.stringify(e));
    dispatch({
      type: constant.SET_GLOBAL_PACKAGE_NAME,
      setGlobalPackageName: e.value,
    });
    dispatch(FetchMenulist(e.value));
  };

  useEffect(() => {
    dispatch(FetchPackagename());
  }, []);

  useEffect(() => {
    if (package_name && package_name.length > 0) {
      const tempPack = package_name.map(pack => ({
        value: pack.package_name,
        label: pack.package_title,
      }));
      setPackges(tempPack);
      if (getLocalPackname) {
        localStorage.setItem('dpackage', getPackname?.value);
        dispatch(FetchMenulist(getPackname?.value)); // will remove
        setDefValue(JSON.parse(getLocalPackname));
      } else {
        localStorage.setItem('dpackage', tempPack[0] && tempPack[0]?.value);
        dispatch(FetchMenulist(tempPack[0] && tempPack[0]?.value)); // will remove
        setDefValue(tempPack[0]);
      }
    }
  }, [package_name]);

  const dropdownStyles = {
    option: (base, state) => ({
      ...base,
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
      backgroundColor: state.isSelected ? '#A21094' : 'inherit'
    }),
  };
  // console.log(packages,'packages')
  return (
    <>
      <div
        id='kt_aside_menu'
        data-menu-vertical='1'
        className={`aside-menu ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}>
        <li style={{ listStyle: 'none' }} className='pr-5 pl-5'>
          <Select
            value={defValue}
            onChange={handleOnchange}
            options={packages}
            styles={dropdownStyles}
          />
        </li>
        <AsideMenuList layoutProps={layoutProps} />
      </div>
    </>
  );
}

const mapStateToProps = state => {
  const { common } = state;
};
export default connect(mapStateToProps)(AsideMenu);
