import * as constant from "./constants";
const initialState = {
  upload_list: [],
  upload_list_loading: true,
  upload_list_error: null,

  upload_page_list: [],
  upload_page_list_loading: true,
  upload_page_list_error: null,

  brand_list: [],
  brand_list_loading: true,
  brand_list_error: null,
};

const UploadReducer = (state = initialState, { type, response,error }) => {
  switch (type) {
    case constant.UPLOADS_INIT:
      return {
        ...state,
        upload_list_loading: true,
      };

    case constant.UPLOADS_INIT_SUCCESS:
      return {
        ...state,
        upload_list_loading: false,
        upload_list: response.result,
      };

    case constant.UPLOADS_INIT_ERROR:
      return {
        ...state,
        upload_list_loading: false,
        error: error,
      };
      case constant.UPLOADS_PAGE_INIT:
      return {
        ...state,
        upload_page_list_loading: true,
      };

    case constant.UPLOADS_PAGE_INIT_SUCCESS:
      return {
        ...state,
        upload_page_list_loading: false,
        upload_page_list: response.result,
      };

    case constant.UPLOADS_PAGE_INIT_ERROR:
      return {
        ...state,
        upload_page_list_loading: false,
        error: error,
      };
   
      return {
        ...state,
        brand_list_loading: false,
        error: error,
      };

      
    default:
      return state;
  }
};
export default UploadReducer;
