import { combineReducers } from "redux";
import CONST from "./Const";

function ReconcilationReducer(state = {}, action) {
  return Reducer(CONST.RECONCILATION.PAYMENT_CONFIG[action.id], state, action);
}
function ReconcilationPublisherReducer(state = {}, action) {
  return Reducer(
    CONST.RECONCILATION.PUBLISHER_CONFIGURATION[action.id],
    state,
    action
  );
}
function InvoicePerformaReconcilationReducer(state = {}, action) {

  return Reducer(
    CONST.RECONCILATION.INVOICE_PERFORMA[action.id],
    state,
    action
  );
}
function ReconcilationReportReducer(state = {}, action) {
  return Reducer(CONST.RECONCILATION.REPORT[action.id], state, action);
}

function ReattributionInstallReportReducer(state = {}, action) {
  return Reducer(
    CONST.RECONCILATION.REATTRIBUTION_SUMMARY.INSTALL[action.id],
    state,
    action
  );
}

function ReattributionEventReportReducer(state = {}, action) {

  return Reducer(
    CONST.RECONCILATION.REATTRIBUTION_SUMMARY.EVENT[action.id],
    state,
    action
  );
}

function InvoiceConfigReducer(state = {}, action) {

  return Reducer(
    CONST.RECONCILATION.INVOICE_CONFIG[action.id],
    state,
    action
  );
}

export default combineReducers({
  RecocilationPaymentConfig: ReconcilationReducer,
  RecocilationPublisherConfig: ReconcilationPublisherReducer,
  ReconcilationReport: ReconcilationReportReducer,
  InvoicePerformaReconcilation: InvoicePerformaReconcilationReducer,
  ReattributionInstallReport: ReattributionInstallReportReducer,
  ReattributionEventReport: ReattributionEventReportReducer,
  InvoiceConfig:InvoiceConfigReducer,

});

function Reducer(key, state, action) {
  switch (action.type) {
    case key?.INIT: {
      const data = { loading: true, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.SUCCESS: {
      const data = { loading: false, data: action.data, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.ERROR: {
      const data = { loading: false, data: null, error: action.error };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.CLEAR: {
      const data = { loading: false, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    default:
      return state;
  }
}
