import * as constant from "../constants/WhitelistingConst";

const initialState = {
  //White List

  whitelistfraudcategory: [],
  whitelistfraudcategory_loading: true,
  whitelistfraudcategory_error: null,

  whitelistFields: [],
  whitelistFields_loading: true,
  whitelistFields_error: null,

  white_listing_table: [],
  white_listing_table_loading: true,
  white_listing_table_error: null,
  total_count: 0,


  white_list_add_data: '',
  white_list_add_loading: true,
  white_list_add_error : null ,
  // white_list_add_clear : 

  white_list_edit_data: '',
  white_list_edit_loading: true,
  white_list_edit_error : null ,

  
  white_list_delete_data: '',
  white_list_delete_loading: true,
  white_list_delete_error : null ,

};

const WhiteListingReducer = (
  state = initialState,
  { type, response, setSideDrawer, error }
) => {
  switch (type) {
    case constant.WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT:
      return {
        ...state,
        whitelistfraudcategory_loading: true,
      };
    case constant.WHITE_LISTING_FIELDS_LIST_INIT:
      return {
        ...state,
        whitelistFields_loading: true,
      };

    case constant.WHITE_LISTING_TABLE_INIT:
      return {
        ...state,
        white_listing_table_loading: true,
       
      };

    // success

    case constant.WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT_SUCCESS:
      return {
        ...state,
        whitelistfraudcategory_loading: false,
        whitelistfraudcategory: response.data,
      };

    case constant.WHITE_LISTING_FIELDS_LIST_INIT_SUCCESS:
      return {
        ...state,
        whitelistFields_loading: false,
        whitelistFields: response.data,
      };

    case constant.WHITE_LISTING_TABLE_INIT_SUCCESS:
      return {
        ...state,
        white_listing_table_loading: false,
        white_listing_table: response.data,
        total_count: response.total,
      };

    // error

    case constant.WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT_ERROR:
      return {
        ...state,
        whitelistfraudcategory_loading: false,
        whitelistfraudcategory_error: response,
      };

    case constant.WHITE_LISTING_FIELDS_LIST_INIT_ERROR:
      return {
        ...state,
        whitelistFields_loading: false,
        whitelistFields_error: response,
      };

    case constant.WHITE_LISTING_TABLE_INIT_ERROR:
      return {
        ...state,
        white_listing_table_loading: false,
        white_listing_table_error: response,
      };

    //add white list

    case constant.WHITE_LIST_ADD_INIT:
      return {
        ...state,
        white_list_add_loading: true,
      };
    case constant.WHITE_LIST_ADD_INIT_SUCCESS:
      return {
        ...state,
        event_app_generate_data_loading: false,
        white_list_add_data: response,
      };
    case constant.WHITE_LIST_ADD_INIT_ERROR:
      return {
        ...state,
        white_list_add_loading: false,
        white_list_add_error: error,
      };

    case constant.WHITE_LIST_ADD_INIT_CLEAR:
      return {
        ...state,
        white_list_add_error: response,
        white_list_add_data: [],
      };


      //edit 

      case constant.WHITE_LIST_EDIT_INIT:
      return {
        ...state,
        white_list_edit_loading: true,
      };
    case constant.WHITE_LIST_EDIT_INIT_SUCCESS:
      return {
        ...state,
        event_app_generate_data_loading: false,
        white_list_edit_data: response,
      };
    case constant.WHITE_LIST_EDIT_INIT_ERROR:
      return {
        ...state,
        white_list_edit_loading: false,
        white_list_edit_error: error,
      };

      case constant.DELETE_USER_INIT:
        return {
          ...state,
          white_list_delete_loading: true,
        };
      case constant.DELETE_USER_SUCCESS:
        return {
          ...state,
          white_list_delete_loading: false,
          white_list_delete_data: response,
        };
      case constant.DELETE_USER_ERROR:
        return {
          ...state,
          white_list_delete_loading: false,
          white_list_delete_error: error,
        };
  

    default:
      return state;
  }
};
export default WhiteListingReducer;
