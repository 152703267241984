const AppPubConfigConst = {
  getTable: {
    INIT: 'AppPubConfigConst_GET_TABLE',
    SUCCESS: 'AppPubConfigConst_GET_TABLE_success',
    ERROR: 'AppPubConfigConst_GET_TABLE_ERROR',
    CLEAR: 'AppPubConfigConst_GET_TABLE_CLEAR',
  },
  addConfgi: {
    INIT: 'AppPubConfigConst_GET_ADD',
    SUCCESS: 'AppPubConfigConst_GET_ADD_success',
    ERROR: 'AppPubConfigConst_GET_ADD_ERROR',
    CLEAR: 'AppPubConfigConst_GET_ADD_CLEAR',
  },
  delete: {
    INIT: 'AppPubConfigConst_DELETE',
    SUCCESS: 'AppPubConfigConst_DELETE_success',
    ERROR: 'AppPubConfigConst_DELETE_ERROR',
    CLEAR: 'AppPubConfigConst_DELETE_CLEAR',
  },
  filters: {
    publisher: {
      INIT: 'AppPubConfigConst_GET_FILTER_PUBLISHER',
      SUCCESS: 'AppPubConfigConst_GET_FILTER_PUBLISHER_success',
      ERROR: 'AppPubConfigConst_GET_FILTER_PUBLISHER_ERROR',
      CLEAR: 'AppPubConfigConst_GET_FILTER_PUBLISHER_CLEAR',
    },
    fraudType: {
      INIT: 'AppPubConfigConst_GET_FILTER_FRAUDTYPE',
      SUCCESS: 'AppPubConfigConst_GET_FILTER_FRAUDTYPE_success',
      ERROR: 'AppPubConfigConst_GET_FILTER_FRAUDTYPE_ERROR',
      CLEAR: 'AppPubConfigConst_GET_FILTER_FRAUDTYPE_CLEAR',
    },
    excludeFraud: {
      INIT: 'AppPubConfigConst_GET_FILTER_EXCLUDEFRAUD',
      SUCCESS: 'AppPubConfigConst_GET_FILTER_EXCLUDEFRAUD_success',
      ERROR: 'AppPubConfigConst_GET_FILTER_EXCLUDEFRAUD_ERROR',
      CLEAR: 'AppPubConfigConst_GET_FILTER_EXCLUDEFRAUD_CLEAR',
    },
    eventType:{
      INIT: 'AppPubConfigConst_GET_FILTER_EVENT_TYPE',
      SUCCESS: 'AppPubConfigConst_GET_FILTER_EVENT_TYPE_success',
      ERROR: 'AppPubConfigConst_GET_FILTER_EVENT_TYPE_ERROR',
      CLEAR: 'AppPubConfigConst_GET_FILTER_EVENT_TYPE_CLEAR',
    }
  },
};

export default AppPubConfigConst;
