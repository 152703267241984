export default Object.freeze({
  DASHBOARD: {
    INIT: 'INIT_CLICK_INTEGRITY_DASHBOARD',
    SUCCESS: 'SUCCESS_CLICK_INTEGRITY_DASHBOARD',
    ERROR: 'INIT_CLICK_INTEGRITY_DASHBOARD',
  },
  GENERATE_URL: {
    INIT: 'INIT_CLICK_INTEGRITY',
    SUCCESS: 'SUCCESS_CLICK_INTEGRITY',
    ERROR: 'INIT_CLICK_INTEGRITY',
  },
});
