import React from 'react';
// import { useIdleTimer } from 'react-idle-timer';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm';
import useJWTSessionTimeOut from './useJWTSessionTimeOut';

/**
 * Keeps track of current session (JWT expiry)
 */
export default function Session() {
  useJWTSessionTimeOut();
  //   useUserIdleTimeOut();
  useIdleTimer({
    timeout: 15 * 60 * 1000,
    // promptTimeout: 0.5 * 60 * 1000,
    // onPrompt: function() {
    //   console.log('Propmpt');
    // },
    onIdle: function() {
      if (!localStorage.getItem('token')) return;
      console.log('IDLE timeout');
      localStorage.clear();
      window.location = '/';
    },
  });
  return <></>;
}
