import { getLocalStorage } from '../../app/utils/helpers';
import { AUTH_DATA } from '../../app/utils/const';

const getauthvalue = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));

const id = getauthvalue && getauthvalue?.id;

const EndPoints = {
  counts_data: `/api/bi/plots/counts`,
  package_name: `/api/user/package`,
  menu_list: `/api/user/menu`,
  menu_list2: `/api/mtrackit/menu`,
  filter_list: `/api/user/filter`,
  incident_volume: `/api/bi/plots/incident_volume`,
  activecases_channel: `/api/bi/plots/active_cases_by_channel`,
  sub_channel: `/api/bi/plots/sub_channel`,
  topten_location: `/api/bi/plots/top_ten_location`,
  category_level_count: `/api/bi/plots/category_level_count`,
  publisher_level_count: `/api/bi/plots/publisher_level_count`,
  channel_list: `/api/bi/filters/channel`,
  country_list: `/api/bi/filters/country`,
  category_list: `/api/bi/filters/category`,
  brand_list: `/api/bi/filters/brand`,
  priority_list: `/api/bi/filters/priority`,
  publisher_list: `/api/bi/filters/publisher`,
  status_list: `/api/bi/filters/status`,
  report_list: `/api/bi/lazyreport`,
  create_ticket: `/api/bi/ticket/`,
  create_multiple_ticket: `/api/bi/report/bulk_ticket`,
  ticket_detail: `/api/ticket/single/`,
  ticket_customer: `/api/bi/ticket-customer/${id}`,
  priority: `/api/bi/ticket-priority`,
  ticket: `/api/ticket/all/tickets`,
  search_ticket: `/api/ticket/single`,
  download_report: `/api/bi/report/download`,
  status_ticket: `/api/bi/ticket-status`,
  ticket_update: `/api/ticket`,
  login: `/api/user/login`,
  LOGOUT: `/api/user/logout`,
  change: `/api/user/change_password`,
  close_ticket: `/api/bi/report/bulk_ticket`,
  save_ticket: `/api/bi/report/update_priority_and_status_bulk`,
  THREESIXTY_UPLOAD: `/api/bi/360`,
  DIGITAL_FOOT_PRINT: '/api/digitalfootprint',
  campaign: `/api/mtrackit/v2campaign`,
  campaign_add: `/api/mtrackit/v2campaign_add_details`,
  publisher: `/api/mtrackit/publisher`,
  publisher_add: `/api/mtrackit/publisher_add`,
  revenue: `/api/mtrackit/dashboard/total_revenue`,
  cost: `/api/mtrackit/dashboard/total_cost`,
  events: `/api/mtrackit/dashboard/total_events`,
  total_fraud_counts: `/api/mtrackit/dashboard/total_fraud_count`,
  total_fraud_perc: `/api/mtrackit/dashboard/total_fraud_percent`,
  clicks: `/api/mtrackit/dashboard/total_clicks`,
  pubevents: `/api/mtrackit/dashboard/top_publisher_event`,
  pubcosts: `/api/mtrackit/dashboard/top_publisher_cost`,
  pubover: `/api/mtrackit/dashboard/click_event_publisher`,
  pubfraud: `/api/mtrackit/dashboard/event_frauds_publishers`,
  pubevent: `/api/mtrackit/dashboard/revenue_cost_publishers`,
  DEEP_DIVE_ANALYSIS: `/api/user/deepdive?package_name=PACKAGE`,
  VERIFICATION_URL: `https://merchantonboarding.mfilterit.net/api/v1/master?api_key=KEY`,
  BULK_BANK_VERIFICATION_URL: `https://merchantonboarding.mfilterit.net/api/v1/bulk_process`,
  THREESIXTY_GET_TICKET: `/api/bi/360`,
  DIGITAL_FOOT_PRINT_DOWNLOAD: '/api/digitalfootprint/download',
  VAST_IFRAME_URL: '/api/user/vast_iframe_url',

  // mtrackit filter
  campaign_id: `/api/mtrackit/dashboard/filter/campaign_id`,
  event_type: `/api/mtrackit/dashboard/filter/event_type`,
  publisher_name: `/api/mtrackit/dashboard/filter/publisher`,
  fraud_type: `/api/mtrackit/dashboard/filter/fraud_status`,

  //mtrackit report
  mTrackit_report: `/api/mtrackit/report`,
  mTrackit_report_download: `/api/mtrackit/report/download`,
  mTrackit_event_report_download: `/api/mtrackit/event_report_download`,

  // mTrackit_report: `/api/mtrackit/report`,
  mTrackit_event_report: '/api/mtrackit/event_report',
  // mTrackit_report_download: `/api/mtrackit/report/download`,

  mTrackit_event_health_report: '/api/mtrackit/health_report',
  mTrackit_event_health_download: '/api/mtrackit/health_report_download',

  //mtrackit campaign report
  mTrackit_campaign_report: `/api/mtrackit/campaign_report`,
  mTrackit_campaign_report_download: `/api/mtrackit/campaign_report_download`,

  //mTrackit Dashboard report
  mTrackIt_dashboard_report: `/api/mtrackit/dashboard/top_publisher_table`,

  //mtrackit postback report
  mTrackit_postback_report: `/api/mtrackit/postback_report`,
  mTrackit_postback_report_download: `/api/mtrackit/postback_report_download`,

  //Landing Page Report
  landing_report: `/api/mtrackit/landing_page`,
  landing_page_add_details: `/api/mtrackit/landing_page_add_details`,

  //MTRACKIT campaign report Filter
  campaign_report_campaign_id:
    '/api/mtrackit/campaign_report/filter/campaign_id',
  campaign_report_publisher: '/api/mtrackit/campaign_report/filter/publisher',

  //MTRACKIT Postback report Filter
  postback_report_event: '/api/mtrackit/postback_report/filter/event_type',
  postback_report_pb_status:
    '/api/mtrackit/postback_report/filter/pb_data_type',
  postback_report_publisher: '/api/mtrackit/postback_report/filter/publisher',

  //mtrackit report filter
  report_campaign_id: `/api/mtrackit/report/filters/campaign_id`,
  report_event_type: `/api/mtrackit/report/filters/event_type`,
  report_publisher_name: `/api/mtrackit/report/filters/publisher_name`,
  report_fraud_type: `/api/mtrackit/report/filters/fraud_status`,

  //web
  web_channel_list: `/api/web-bi/filters/channel`,
  web_campaign_list: `/api/web-bi/lazyfilters/campaign_id`,
  web_creative_list: `/api/web-bi/filters/creative_id`,
  web_systemdomain_list: `/api/web-bi/lazyfilters/system_domain`,
  web_placementid_list: `/api/web-bi/lazyfilters/placement_id`,
  web_fraudcategory_list: `/api/web-bi/filters/fraud_category`,
  web_fraudsubcategory_list: `/api/web-bi/filters/fraud_sub_category`,
  web_placementtype_list: `/api/web-bi/filters/placement_type`,
  web_publisherid_list: `/api/web-bi/filters/publisher_id`,
  web_publisher_name_list: `/api/web-bi/filters/publisher_name`,
  web_dashboard_type: `/api/user/dashboard_type`,
  web_publisher_list: `/api/web-bi/filters/publisher_name`,
  web_clean_publisher: `/api/web-bi/plots/top_five_clean_publisher`,
  web_fraud_publisher: `/api/web-bi/plots/top_five_not_clean_publisher`,
  web_sub_publisher_list: `/api/web-bi/filters/sub_publisher_name`,
  web_fraud_trend_day: `/api/web-bi/plots/fraud_trend/date`,
  web_fraud_trend_month: `/api/web-bi/plots/fraud_trend/month`,
  web_page_id_list: `/api/web-bi/lazyfilters/page_id`,
  web_counts_data: `/api/web-bi/plots/counts`,
  web_fraud_reason_trend: `/api/web-bi/plots/fraud_reason_trend`,
  web_fraud_visit: `/api/web-bi/plots/fraud_impression`,
  web_cumulative_impression_trend: `/api/web-bi/plots/cumulative_impression_trend`,
  web_topten_location: `/api/bi/plots/top_ten_location`,
  web_topten_placement: '/api/web-bi/plots/top_placement',
  web_top_exchange_traffic: `/api/web-bi/plots/top_exchange_traffic`,
  web_high_fraud_traffic: '/api/web-bi/plots/placement_high_fraud_traffic',
  web_fraud__trend_publisher_wise: '/api/web-bi/plots/fraud_trend/publisher',

  //click
  web_click_clean_publisher: '/api/web-bi/click/plots/top_five_clean_publisher',
  web_click_fraud_publisher:
    '/api/web-bi/click/plots/top_five_not_clean_publisher',
  web_click_fraud_trend_day: '/api/web-bi/click/plots/fraud_trend/date',
  web_click_fraud_trend_month: '/api/web-bi/click/plots/fraud_trend/month',
  web_click_fraud_visit: '/api/web-bi/click/plots/click_fraud_percentage',
  web_click_counts_data: '/api/web-bi/click/plots/click_counts',

  //click Filter
  web_click_fraud_category: '/api/web-bi/click/filters/fraud_category',
  web_click_fraud_sub_category: '/api/web-bi/click/filters/fraud_sub_category',
  web_click_publisher: '/api/web-bi/click/filters/publisher_name',
  web_click_campaign: '/api/web-bi/click/filters/campaign_id',
  web_click_device: '/api/web-bi/click/filters/device_type',

  //events filter
  event_campaign_list: '/api/web-bi/event/lazyfilters/campaign',
  event_page_list: '/api/web-bi/event/lazyfilters/page',
  event_publisher_list: '/api/web-bi/event/lazyfilters/publisher',
  event_nl_publisher_list: '/api/web-bi/event/filters/publisher',
  event_channel_list: '/api/web-bi/event/lazyfilters/channel',
  event_event_list: '/api/web-bi/event/lazyfilters/event',

  //event dashboard
  events_count: '/api/web-bi/event/plots/counts',
  event_fraud_category: '/api/web-bi/event/plots/fraud_category',
  event_clean_publisher_name: '/api/web-bi/event/plots/top_clean_publisher',
  event_fraud_publisher_name: '/api/web-bi/event/plots/top_not_clean_publisher',
  event_fraud_month: '/api/web-bi/event/plots/fraud_trend/month',
  event_fraud_date: '/api/web-bi/event/plots/fraud_trend/date',
  event_fraud_publisher: '/api/web-bi/event/plots/fraud_trend/publisher',

  //event report

  web_event_report: `/api/web-bi/event/report`,
  app_event_reports: `/api/web-bi/appfraud/report_config`,
  app_install_reports: `/api/web-bi/appfraud/report_config`,
  app_report_download: `/api/web-bi/appfraud/download_attachment`,
  web_event_report_download: `/api/web-bi/event/report/download_attachment`,
  // web event report filters
  event_report_publisher_list: '/api/web-bi/event/report/filter/publisher',
  event_report_fraud_category_list:
    '/api/web-bi/event/lazyreportfilters/fraud_category',
  event_report_feilds_list: '/api/web-bi/event/report/filter/fields',

  generate_report: '/api/web-bi/event/report/generate',

  //app install generate report
  install_generate_report: '/reports',
  event_generate_report: '/reports',

  // report web event download
  event_report_download: '/api/web-bi/event/report/download',

  //-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-

  //app  re-engagement click
  app_total_re_engagement_click: '/RPT_re_click_Total_click_latest',
  app_fraudulent_re_engagement_click:
    '/RPT_re_click_Total_click_percentage_latest',
  app_clean_re_engagement_click: '/RPT_re_click_Clean_Traffic_latest',
  app_fraud_re_engagement_click: '/RPT_re_click_Contributing_Fraud_latest',
  app_fraud_category_re_engagement_click:
    '/RPT_re_click_Device_Fraud_Display_latest',
  app_fraud_sub_category_re_engagement_click:
    '/RPT_re_click_Fraud_Display_latest',
  device_fraud_count_re_engagement_click:
    '/RPT_re_click_Device_Fraud_Count_percentage_latest',
  app_click_fraud_date_re_engagement_click:
    '/RPT_re_click_Device_Fraud_Date_latest',
  app_click_fraud_publisher_re_engagement_click:
    '/RPT_re_click_Device_Fraud_Publisher_latest',
  app_fraud_date_re_engagement_click: '/RPT_re_click_Fraud_Date_count_latest',
  app_fraud_publisher_re_engagement_click:
    '/RPT_re_click_Fraud_Publisher_count_latest',
  app_sub_fraud_category_date_re_engagement_click:
    '/RPT_re_click_Device_Fraud_Date_latest',
  app_sub_fraud_category_publisher_re_engagement_click:
    '/RPT_re_click_Fraud_Display_latest',
  app_fraud_sub_publisher_re_engagement_click: '/sub_publisher',
  app_fraud_sub_publisher_date_re_engagement_click:
    '/RPT_Install_Fraud_Cur_Publisher_count_latest',

  //-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-

  //app install dashboard
  app_total_install: '/RPT_Total_Installs_latest',
  app_fraudulent_install: '/RPT_Total_Installs_percentage_latest',
  app_clean_install: '/RPT_Clean_Traffic_latest',
  app_fraud_install: '/RPT_Contributing_Fraud_latest',
  app_fraud_category: '/RPT_Device_Fraud_Display_latest',
  app_fraud_sub_category: '/RPT_Fraud_Display_latest',
  device_fraud_count: '/RPT_Device_Fraud_Count_percentage_latest',
  app_click_fraud_date: '/RPT_Device_Fraud_Date_latest',
  app_click_fraud_publisher: '/RPT_Device_Fraud_Publisher_latest',
  app_install_fraud_date: '/RPT_Install_Fraud_Date_count_latest',
  app_install_fraud_publisher: '/RPT_Install_Fraud_Publisher_count_latest',
  app_sub_fraud_category_date: '/RPT_Device_Fraud_Date_latest',
  app_sub_fraud_category_publisher: '/RPT_Device_Fraud_Publisher_latest',
  app_fraud_sub_publisher: '/sub_publisher',
  app_fraud_sub_publisher_date: '/RPT_Install_Fraud_Cur_Publisher_count_latest',

  //app event dashboard
  app_event_total_event: '/RPT_Total_Installs_latest_event',
  app_event_fraudulent_event: '/RPT_Total_Installs_percentage_latest_event',
  app_event_clean_event: '/RPT_Clean_Traffic_latest_event',
  app_event_fraud_event: '/RPT_Contributing_Fraud_latest_event',
  app_event_fraud_category: '/RPT_Device_Fraud_Display_latest_event',
  app_event_fraud_sub_category: '/RPT_Fraud_Display_latest_event',
  app_event_click_fraud_date: '/RPT_Device_Fraud_Date_latest_event',
  app_event_click_fraud_publisher: '/RPT_Device_Fraud_Publisher_latest_event',
  app_event_fraud_date: '/RPT_Install_Fraud_Date_count_latest_event',
  app_event_fraud_publisher: '/RPT_Install_Fraud_Publisher_count_latest_event',
  app_event_device_fraud_count:
    '/RPT_Device_Fraud_Count_percentage_latest_event',
  //
  app_Fraud_Sub_Category: '/fraud_sub_category',
  //

  //app reengagement-event dashboard
  app_reengagement_event_total_event: '/RPT_re_event_Total_event_latest',
  app_reengagement_event_fraudulent_event:
    '/RPT_re_event_Total_event_percentage_latest',
  app_reengagement_event_clean_event: '/RPT_re_event_Clean_Traffic_latest',
  app_reengagement_event_fraud_event: '/RPT_re_event_Contributing_Fraud_latest',
  app_reengagement_event_fraud_category:
    '/RPT_re_event_Device_Fraud_Display_latest',
  app_reengagement_event_fraud_sub_category:
    '/RPT_re_event_Fraud_Display_latest',
  app_reengagement_event_click_fraud_date:
    '/RPT_re_event_Device_Fraud_Date_latest',
  app_reengagement_event_click_fraud_publisher:
    '/RPT_re_event_Device_Fraud_Publisher_latest',
  app_reengagement_event_fraud_date: '/RPT_re_event_Fraud_Date_count_latest',
  app_reengagement_event_fraud_publisher:
    '/RPT_re_event_Fraud_Publisher_count_latest',
  app_reengagement_event_device_fraud_count:
    '/RPT_re_event_Device_Fraud_Count_percentage_latest',

  //UPLOADS
  uploads: '/api/mtrackit/uploads',

  //Download Reports
  fetch_downloads: '/api/mtrackit/fetch_downloads',

  //app publisher summary
  app_publisher: '/table',
  app_publisher_download: '/download_publisher_report',

  // APP Filters
  app_F_publisher: '/publisher_list',
  app_F_country: '/country_list',
  app_F_campaign: '/campaign_list',
  app_F_vendor: '/vendor_list',
  app_F_event: '/event_list',
  app_F_sub_publisher: '/sub_publisher',
  app_F_fields: '/columns',
  app_F_Install_fraud: '/RPT_Device_Fraud_Display_latest',
  app_F_Event_fraud: '/RPT_Device_Fraud_Display_latest_event',
  app_F_REEvent_fraud: '/RPT_re_event_Device_Fraud_Display_latest',
  app_F_REClick_fraud: '/RPT_re_click_Device_Fraud_Display_latest',
  incentDetaild: '/api/web-bi/appfraud/incent_details',
  incentDetaildDownload: '/api/web-bi/appfraud/incent_details/download',
  ANALYTICS_DASHBOARD: '/api/quicksight/generate2',
  // App Publisher Config
  APP_PUBLISHER_CONFIG: '/api/web-bi/appfraud/publisherConfig',

  ADD_PUBLISHER_CONFIG: '/api/web-bi/appfraud/publisherConfig/v1',

  //white_listing_page

  white_list_tabel: '/api/web-bi/whitelist',
  add_white_list: '/api/web-bi/whitelist',
  edit_white_list: '/api/web-bi/whitelist',
  whitelisting_Fields: '/api/web-bi/whitelist/fields',
  whitelisting_Fraud_category: '/api/web-bi/whitelist/fraud_category',

  //publisher report

  configuration_dashboard_type: '/api/user/dashboard_type',
  publisher_config_list: '/api/web-bi/publisher_config/all',
  publisher_fraud_categories: '/api/web-bi/publisher_config/fraud_categories',
  publisher_fraud_sub_categories:
    '/api/web-bi/publisher_config/fraud_sub_categories',
  varation: '/api/web-bi/publisher_config/variations',
  save_config: '/api/web-bi/publisher_config',
  config_tabel: '/api/web-bi/publisher_config/',
  pubconfig_edit: '/api/web-bi/publisher_config/getone',
  pubDelete: '/api/web-bi/publisher_config/',

  //vast trackers
  // VAST_tabel: '/api/web-bi/vast_tracker/',
  // VASTDelete: '/api/web-bi/vast_tracker/',

  // VAST_tabel: '/api/list_vast_trackers',

  // user management
  ALL_USER: '/api/user',
  ADD_USER: '/api/user/signup',
  EDIT_USER: '/api/user/',
  DELETE_USER: '/api/user/',
  GET_ONE_USER: '/api/user/getone/',
  //Package apis
  getpackage: `/api/config/package`,
  savepackage: `/api/config/package`,
  updatepackage: `/api/config/package`,
  deletepackage: `/api/config/package`,
  getonepackage: `/api/config/package`,
  //USER MAPPING
  getusermapping: '/api/user/UPM',
  postusermapping: '/api/user/UPM',
  updateusermapping: '/api/user/UPM',
  deleteusermapping: '/api/user/UPM',

  menuselection: '/api/config/menu',
  filterselection: '/api/config/filter',
  userpackagemapping: `/api/config/package/all`,
  usergetoneapi: `/api/user/UPM`,
  getuser: '/api/user/all',
  // External embeded
  EXTERNAL_EMBEDED: '/api/user/external_embedded_url',

  //reconcilation
  payment_model_table: '/api/reconciliation/payment',
  publisher_payment_model_table: '/api/reconciliation/publisher_payment',
  settlement_setting: '/api/reconciliation/settlement',
  currency: '/api/reconciliation/currency',
  payment_model: '/api/reconciliation/payment/all',
  order_status: '/event_list',

  //Alert page
  alert_page: '/api/web-bi/appfraud/alert_config',

  //Payout Report
  payout_report: '/api/reconciliation/payout_report',
  invoice_genration: '/api/reconciliation/generate_invoice',
};

const PublisheURL = process.env.REACT_APP_FILTERS_APP;

const ApiUrl = process.env.REACT_APP_DOMAIN;
const OnboardUrls = 'http://api-onboard.mfilterit.net';
const VastApiUrl = 'https://vastapis.mfilterit.net';
const BrandRelevancy = process.env.REACT_APP_DOMAIN + '/api/v2/br';
const RelevancyPost =
  'https://emuvoeibci67i7pblbxgokequi0yiurp.lambda-url.us-west-2.on.aws/';
// const VASTUrl = 'https://kfun4u7xnl.execute-api.ap-south-1.amazonaws.com'
// const VASTUrl = 'https://vastapis.mfilterit.net'
// const ApiUrl = 'https://infringementapi.mfilterit.net';
// const ApiUrl = process.env.REACT_APP_DOMAIN;

// const AppInstall = process.env.REACT_APP_DASHBOARD_APP;
// Api domain for reengagement click/event
const AppInstall = process.env.REACT_APP_DASHBOARD_APP;
// const AppInstall = process.env.REACT_APP_DASHBOARD_APP;
const AppFilterDomain = process.env.REACT_APP_FILTERS_APP;
const AppFilterDomainTest = process.env.REACT_APP_FILTERS_APP;
const UserAcquisitionDomain = process.env.REACT_APP_FILTERS_APP;

// const MTRACKITAPIURL = 'https://api.mtrackit.com';
// const PythonApiUrl = "https://api.mtrackit.com";
// const MTRACKITAPIURL = "https://tao66iuof4.execute-api.us-west-2.amazonaws.com"
// const PythonApiUrl = 'https://tao66iuof4.execute-api.us-west-2.amazonaws.com';
// const urlendpoint = 'https://tao66iuof4.execute-api.us-west-2.amazonaws.com';

const MTRACKITAPIURL = 'https://api.mtrackit.com';
const PythonApiUrl = 'https://api.mtrackit.com';
const urlendpoint = 'https://api.mtrackit.com';

const MtrackitApiUrl = process.env.REACT_APP_DOMAIN;

// const PythonApiUrl2 = 'https://8tshda6f7k.execute-api.us-west-2.amazonaws.com';
const Brandsafetyapi = 'https://test-brandsafety-bi2.mfilterit.net';

// const Brandsafetydashapi =
//   'https://8ado0dl367.execute-api.us-west-2.amazonaws.com';
const Brandsafetydashapi =
  'https://cpkw6dbgbc.execute-api.ap-south-1.amazonaws.com';

// const Brandsafetyapi ='http://brandsafety-bi.mfilterit.net/BS';
//const PythonApiUrl = 'https://8gk4t01qo3.execute-api.us-west-2.amazonaws.com';

const generate = process.env.REACT_APP_FILTERS_APP;

export {
  EndPoints,
  ApiUrl,
  PublisheURL,
  // VASTUrl,
  OnboardUrls,
  BrandRelevancy,
  RelevancyPost,
  VastApiUrl,
  AppInstall,
  MtrackitApiUrl,
  AppFilterDomain,
  generate,
  PythonApiUrl,
  Brandsafetydashapi,
  Brandsafetyapi,
  MTRACKITAPIURL,
  urlendpoint,
  AppFilterDomainTest,
  UserAcquisitionDomain,
};
