import * as constant from './constants';
const initialState = {
  landing_report: [],
  landing_report_loading: true,
  landing_report_error: null,

  landingAdd_data: "",
  landingAdd_loading: true,
  landingAdd_error: null,
};

const LandingReportReducer = (
  state = initialState,
  { type, response, error }
) => {
  switch (type) {
    case constant.LANDINGURL_ADD:
      return {
        ...state,
        landingAdd_loading: true,
      };
    case constant.LANDINGURL_ADD_SUCCESS:
      return {
        ...state,
        landingAdd_loading: false,
        landingAdd_data: response,
      };
    case constant.LANDINGURL_ADD_ERROR:
      return {
        ...state,
        landingAdd_loading: false,
        landingAdd_error: error,
      };
    case constant.LANDINGURL_ADD_CLEAR:
      return {
        ...state,
        landingAdd_loading: false,
        landingAdd_data: [],
      };

    case constant.LANDINGREPORT_INIT:
      return {
        ...state,
        landing_report_loading: true,
      };

    case constant.LANDINGREPORT_INIT_SUCCESS:
      return {
        ...state,
        landing_report_loading: false,
        landing_report: response.result,
      };
    // console.log("data in report")

    case constant.LANDINGREPORT_INIT_ERROR:
      return {
        ...state,
        landing_report_loading: false,
        error: response,
      };
    default:
      return state;
  }
};
export default LandingReportReducer;
