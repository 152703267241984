export default {
  BRAND_RELEVANCY: {

    // Dashboard 
    BR_DASHBOARD_DAY_WISE: {
      INIT: 'INIT_BR_DASHBOARD_DAY_WISE',
      SUCCESS: 'SUCCESS_BR_DASHBOARD_DAY_WISE',
      ERROR: 'ERROR_BR_DASHBOARD_DAY_WISE',
      CLEAR: 'CLEAR_BR_DASHBOARD_DAY_WISE',
    },

    BR_DASHBOARD_UPLOAD_DATE_WISE: {
      INIT: 'INIT_BR_DASHBOARD_UPLOAD_DATE_WISE',
      SUCCESS: 'SUCCESS_BR_DASHBOARD_UPLOAD_DATE_WISE',
      ERROR: 'ERROR_BR_DASHBOARD_UPLOAD_DATE_WISE',
      CLEAR: 'CLEAR_BR_DASHBOARD_UPLOAD_DATE_WISE',
    },

    BR_DASHBOARD_SLAB_WISE: {
      INIT: 'INIT_BR_DASHBOARD_SLAB_WISE',
      SUCCESS: 'SUCCESS_BR_DASHBOARD_SLAB_WISE',
      ERROR: 'ERROR_BR_DASHBOARD_SLAB_WISE',
      CLEAR: 'CLEAR_BR_DASHBOARD_SLAB_WISE',
    },

    BR_DASHBOARD_CAMPAIGN_WISE: {
      INIT: 'INIT_BR_DASHBOARD_CAMPAIGN_WISE',
      SUCCESS: 'SUCCESS_BR_DASHBOARD_CAMPAIGN_WISE',
      ERROR: 'ERROR_BR_DASHBOARD_CAMPAIGN_WISE',
      CLEAR: 'CLEAR_BR_DASHBOARD_CAMPAIGN_WISE',
    },

    BR_DASHBOARD_TOTAL_VIDEOS: {
      INIT: 'INIT_BR_DASHBOARD_TOTAL_VIDEOS',
      SUCCESS: 'SUCCESS_BR_DASHBOARD_TOTAL_VIDEOS',
      ERROR: 'ERROR_BR_DASHBOARD_TOTAL_VIDEOS',
      CLEAR: 'CLEAR_BR_DASHBOARD_TOTAL_VIDEOS',
    },
    BR_DASHBOARD_TOTAL_CAMPAIGN: {
      INIT: 'INIT_BR_DASHBOARD_TOTAL_CAMPAIGN',
      SUCCESS: 'SUCCESS_BR_DASHBOARD_TOTAL_CAMPAIGN',
      ERROR: 'ERROR_BR_DASHBOARD_TOTAL_CAMPAIGN',
      CLEAR: 'CLEAR_BR_DASHBOARD_TOTAL_CAMPAIGN',
    },
    // dashboard END
    INSERT_CONFIG: {
      INIT: 'INIT_BRAND_RELEVANCY_INSERT_CONFIG',
      SUCCESS: 'SUCCESS_BRAND_RELEVANCY_INSERT_CONFIG',
      ERROR: 'ERROR_BRAND_RELEVANCY_INSERT_CONFIG',
      CLEAR: 'CLEAR_BRAND_RELEVANCY_INSERT_CONFIG',
    },
    GET_CONFIG: {
      INIT: 'INIT_BRAND_RELEVANCY_GET_CONFIG',
      SUCCESS: 'SUCCESS_BRAND_RELEVANCY_GET_CONFIG',
      ERROR: 'ERROR_BRAND_RELEVANCY_GET_CONFIG',
      CLEAR: 'CLEAR_BRAND_RELEVANCY_GET_CONFIG',
    },
    GET_ALL_CONFIG: {
      INIT: 'INIT_BRAND_RELEVANCY_GET_ALL_CONFIG',
      SUCCESS: 'SUCCESS_BRAND_RELEVANCY_GET_ALL_CONFIG',
      ERROR: 'ERROR_BRAND_RELEVANCY_GET_ALL_CONFIG',
      CLEAR: 'CLEAR_BRAND_RELEVANCY_GET_ALL_CONFIG',
    },
    EDIT_CONFIG: {
      INIT: 'INIT_BRAND_RELEVANCY_EDIT_CONFIG',
      SUCCESS: 'SUCCESS_BRAND_RELEVANCY_EDIT_CONFIG',
      ERROR: 'ERROR_BRAND_RELEVANCY_EDIT_CONFIG',
      CLEAR: 'CLEAR_BRAND_RELEVANCY_EDIT_CONFIG',
    },
    DELETE_CONFIG: {
      INIT: 'INIT_BRAND_RELEVANCY_DELETE_CONFIG',
      SUCCESS: 'SUCCESS_BRAND_RELEVANCY_DELETE_CONFIG',
      ERROR: 'ERROR_BRAND_RELEVANCY_DELETE_CONFIG',
      CLEAR: 'CLEAR_BRAND_RELEVANCY_DELETE_CONFIG',
    },
    INSERT_DV_CONFIG: {
      INIT: 'INIT_BRAND_RELEVANCY_INSERT_DV_CONFIG',
      SUCCESS: 'SUCCESS_BRAND_RELEVANCY_INSERT_DV_CONFIG',
      ERROR: 'ERROR_BRAND_RELEVANCY_INSERT_DV_CONFIG',
      CLEAR: 'CLEAR_BRAND_RELEVANCY_INSERT_DV_CONFIG',
    },
    INSERT_360_OUTPUT: {
      INIT: 'INIT_BRAND_RELEVANCY_INSERT_360_OUTPUT',
      SUCCESS: 'SUCCESS_BRAND_RELEVANCY_INSERT_360_OUTPUT',
      ERROR: 'ERROR_BRAND_RELEVANCY_INSERT_360_OUTPUT',
      CLEAR: 'CLEAR_BRAND_RELEVANCY_INSERT_360_OUTPUT',
    },
    INSERT_ADD_KEYWORD: {
      INIT: 'INIT_BRAND_RELEVANCY_INSERT_ADD_KEYWORD',
      SUCCESS: 'SUCCESS_BRAND_RELEVANCY_INSERT_ADD_KEYWORD',
      ERROR: 'ERROR_BRAND_RELEVANCY_INSERT_ADD_KEYWORD',
      CLEAR: 'CLEAR_BRAND_RELEVANCY_INSERT_ADD_KEYWORD',
    },
    EDIT_DV_CONFIG: {
      INIT: 'INIT_BRAND_RELEVANCY_EDIT_DV_CONFIG',
      SUCCESS: 'SUCCESS_BRAND_RELEVANCY_DV_EDIT_CONFIG',
      ERROR: 'ERROR_BRAND_RELEVANCY_EDIT_DV_CONFIG',
      CLEAR: 'CLEAR_BRAND_RELEVANCY_EDIT_DV_CONFIG',
    },
  },

};
