import USERMANAGEMENT from '../constants/userManagementConst';

const initialState = {
  get: { loading: false },
  getOneUser: { loading: false },
  add: { loading: false },
  update: { loading: false },
  del: { loading: false },
  //..........................USER MAPPING............................

  //USER PACKAGE MAPPING
  getusermapping: [],
  getusermapping_loading: true,
  getusermapping_error: null,

  //save  update delete
  usermappingsave: [],
  usermappingupdate: [],
  usermappingdelete: [],
  //user ADD PAGE DROPDOWN
  getmenuselection: [],
  getmenuselection_loading: true,
  getmenuselection_error: null,

  getfilterselection: [],
  getfilterselection_loading: true,
  getfilterselection_error: null,

  getpackagedropdown: [],
  getpackagedropdown_loading: true,
  getpackagedropdown_error: null,

  usergetoneapi: [],
  usergetoneapi_loading: true,
  usergetoneapi_error: null,
  //.................................
};

const UserManagementReducer = (
  state = initialState,
  { type, data, error, response }
) => {
  switch (type) {
    // GET ALL USER
    case USERMANAGEMENT.GET_USER_INIT: {
      const get = { loading: true, data: null, error: null };
      return { ...state, get };
    }
    case USERMANAGEMENT.GET_USER_SUCCESS: {
      const get = { loading: false, data, error: null };
      return { ...state, get };
    }
    case USERMANAGEMENT.GET_USER_ERROR: {
      const get = { loading: false, data: null, error };
      return { ...state, get };
    }
    // GET ALL USER
    case USERMANAGEMENT.GET_ONE_USER_INIT: {
      const getOneUser = { loading: true, data: null, error: null };
      return { ...state, getOneUser };
    }
    case USERMANAGEMENT.GET_ONE_USER_SUCCESS: {
      const getOneUser = { loading: false, data, error: null };
      return { ...state, getOneUser };
    }
    case USERMANAGEMENT.GET_ONE_USER_ERROR: {
      const getOneUser = { loading: false, data: null, error };
      return { ...state, getOneUser };
    }
    // ADD USER
    case USERMANAGEMENT.ADD_USER_INIT: {
      const add = { loading: true, data: null, error: null };
      return { ...state, add };
    }
    case USERMANAGEMENT.ADD_USER_SUCCESS: {
      const add = { loading: false, data, error: null };
      return { ...state, add };
    }
    case USERMANAGEMENT.ADD_USER_ERROR: {
      const add = { loading: false, data: null, error };
      return { ...state, add };
    }
    // Edit USER
    case USERMANAGEMENT.EDIT_USER_INIT: {
      const update = { loading: true, data: null, error: null };
      return { ...state, update };
    }
    case USERMANAGEMENT.EDIT_USER_SUCCESS: {
      const update = { loading: false, data, error: null };
      return { ...state, update };
    }
    case USERMANAGEMENT.EDIT_USER_ERROR: {
      const update = { loading: false, data: null, error };
      return { ...state, update };
    }
    // Delete USER
    case USERMANAGEMENT.DELETE_USER_INIT: {
      const del = { loading: true, data: null, error: null };
      return { ...state, del };
    }
    case USERMANAGEMENT.DELETE_USER_SUCCESS: {
      const del = { loading: false, data, error: null };
      return { ...state, del };
    }
    case USERMANAGEMENT.DELETE_USER_ERROR: {
      const del = { loading: false, data: null, error };
      return { ...state, del };
    }
    //
    //
    case USERMANAGEMENT.GET_ALL_USER_INIT:
      return {
        ...state,
        getuser_loading: true,
        getuser: [],
      };
    case USERMANAGEMENT.GET_ALL_USER_INIT_SUCCESS:
      return {
        ...state,
        getuser_loading: false,
        getuser: response.data,
      };
    case USERMANAGEMENT.GET_ALL_USER_INIT_ERROR:
      return {
        ...state,
        getuser_loading: false,
        getuser: null,
      };
    //..............USER  MAPPING...............
    case USERMANAGEMENT.USERMAPPING_DETAILS_INIT_SUCCESS:
      return {
        ...state,
        getusermapping_loading: false,
        getusermapping: response.result,
      };
    //post
    case USERMANAGEMENT.USERMAPPING_SAVE_INIT_SUCCESS:
      return {
        ...state,
        // package_loading: false,
        usermappingsave: response,
      };

    case USERMANAGEMENT.USERMAPPING_UPDATE_INIT_SUCCESS:
      return {
        ...state,
        usermappingupdate: response,
      };
    case USERMANAGEMENT.USERMAPPING_UPDATE_INIT_CLEAR:
      return {
        ...state,
        usermappingupdate: null,
      };

    case USERMANAGEMENT.USERMAPPING_DELETE_INIT_SUCCESS:
      return {
        ...state,
        usermappingdelete: response,
      };

    // USER ADD PAGE GET API

    case USERMANAGEMENT.GET_MENU_SELECTION_INIT_SUCCESS:
      return {
        ...state,
        getmenuselection_loading: false,
        getmenuselection: response,
      };

    case USERMANAGEMENT.GET_FILTER_SELECTION_INIT_SUCCESS:
      return {
        ...state,
        getfilterselection_loading: false,
        getfilterselection: response,
      };

    case USERMANAGEMENT.USER_PACKAGE_DROPDOWN_INIT_SUCCESS:
      return {
        ...state,
        getpackagedropdown_loading: false,
        getpackagedropdown: response.packages,
      };

    case USERMANAGEMENT.USER_GETONEAPI_INIT:
      return {
        ...state,
        usergetoneapi_loading: true,
        usergetoneapi: null,
      };
    case USERMANAGEMENT.USER_GETONEAPI_INIT_SUCCESS:
      return {
        ...state,
        usergetoneapi_loading: false,
        usergetoneapi: response.data,
      };
    case USERMANAGEMENT.USER_GETONEAPI_INIT_CLEAR:
      return {
        ...state,
        usergetoneapi_loading: false,
        usergetoneapi: null,
      };
    //.................................................
    //.............error...............
    case USERMANAGEMENT.REDMINEMAPPING_DETAILS_INIT_ERROR:
    case USERMANAGEMENT.REDMINEMAPPING_DELETE_INIT_ERROR:
      return {
        ...state,
        getredminemapping_loading: false,
        getredminemapping_error: response,
      };

    case USERMANAGEMENT.REDMINEMAPPING_SAVE_INIT_ERROR:
      return {
        ...state,
        getredminemapping_loading: false,
        redminemappingsave: error.response.data,
      };

    case USERMANAGEMENT.REDMINEMAPPING_UPDATE_INIT_ERROR:
      return {
        ...state,
        getredminemapping_loading: false,
        redminemappingupdate: error.response.data,
      };
    //Redmine add page get api
    case USERMANAGEMENT.GET_PROJECT_INIT_ERROR:
      return {
        ...state,
        getproject_loading: false,
        getproject_error: response,
      };

    case USERMANAGEMENT.GET_USER_INIT_ERROR:
      return {
        ...state,
        getuser_loading: false,
        getuser_error: response,
      };

    case USERMANAGEMENT.GET_REDMINE_ID_INIT_ERROR:
      return {
        ...state,
        getredmineid_loading: false,
        getredmineid_error: response,
      };

    case USERMANAGEMENT.GET_EDIT_PAGE_INIT_ERROR:
      return {
        ...state,
        geteditid_loading: false,
        geteditid_error: response,
      };

    //USER PAGE ERROE.....................
    case USERMANAGEMENT.USERMAPPING_DETAILS_INIT_ERROR:
    case USERMANAGEMENT.USERMAPPING_DELETE_INIT_ERROR:
      return {
        ...state,
        getusermapping_loading: false,

        getusermapping_error: error,
      };

    case USERMANAGEMENT.USERMAPPING_UPDATE_INIT_ERROR:
      return {
        ...state,
        getusermapping_loading: false,
        usermappingupdate: error.response.data,
      };
    case USERMANAGEMENT.USERMAPPING_SAVE_INIT_ERROR:
      return {
        ...state,
        getusermapping_loading: false,
        usermappingsave: error.response.data,
      };
    //ADD PAGE GET APIS
    case USERMANAGEMENT.GET_MENU_SELECTION_INIT_ERROR:
      return {
        ...state,
        getmenuselection_loading: false,
        getmenuselection_error: response,
      };
    case USERMANAGEMENT.GET_FILTER_SELECTION_INIT_ERROR:
      return {
        ...state,
        getfilterselection_loading: false,
        getfilterselection_error: response,
      };
    case USERMANAGEMENT.USER_PACKAGE_DROPDOWN_INIT_ERROR:
      return {
        ...state,
        getpackagedropdown_loading: false,
        getpackagedropdown_error: response,
      };
    case USERMANAGEMENT.USER_GETONEAPI_INIT_ERROR:
      return {
        ...state,
        usergetoneapi_loading: false,
        usergetoneapi_error: response,
      };

    //
    //
    default:
      return state;
  }
};
export default UserManagementReducer;
