import { useReducer } from 'react';
import moment from 'moment';

function Save(data) {
  localStorage.setItem('dates', JSON.stringify(data));
}
function Read() {
  const dataRaw = localStorage.getItem('dates') ?? '{}';
  return JSON.parse(dataRaw);
}

const CONSTANT = {
  SETFROMDATE: 'SETFROMDATE',
  SETTODATE: 'SETTODATE',
  SETDATE: 'SETDATE',
};
function Reducer(state, action) {
  switch (action.type) {
    case CONSTANT.SETDATE: {
      const nState = {
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
      };
      Save(nState);
      return nState;
    }
    case CONSTANT.SETFROMDATE: {
      const nState = { ...state, fromDate: action.payload };
      Save(nState);
      return nState;
    }
    case CONSTANT.SETTODATE: {
      const nState = { ...state, toDate: action.payload };
      Save(nState);
      return nState;
    }
    default:
      return state;
  }
}

function initFn() {
  const data = Read();
  let fromDate, toDate;
  if (data.fromDate) fromDate = new Date(moment(data.fromDate));
  else fromDate = new Date(moment().subtract(7, 'days'));
  if (!data.toDate) {
    const to = moment(data.fromDate).add(1, 'month');
    toDate = to.isBefore(moment()) ? new Date(to) : new Date();
  } else toDate = new Date(data.toDate);
  return { fromDate, toDate };
}

const init = {
  fromDate: new Date(
    moment()
      .subtract(7, 'days')
      .format('YYYY-MM-DD')
  ),
  toDate: new Date(moment().format('YYYY-MM-DD')),
};

export const Actions = {
  setDate: function setDate(from, to) {
    return { type: CONSTANT.SETDATE, payload: { fromDate: from, toDate: to } };
  },
  setFromDate: function setFromDate(from) {
    return { type: CONSTANT.SETFROMDATE, payload: from };
  },
  setToDate: function setToDate(to) {
    return { type: CONSTANT.SETTODATE, payload: to };
  },
};

export default function useDateReducer() {
  return useReducer(Reducer, init, initFn);
}
