import { takeLatest, put } from 'redux-saga/effects';
import * as constant from '../constants/WhitelistingConst';
// import DashboardServices from "../services/DashboardServices";
import swal from 'sweetalert';

import { API } from '../setupAxios';
import { EndPoints, ApiUrl } from '../helpers/Endpoints';

export function* ViewWhiteListingFraudCategory(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.whitelisting_Fraud_category;
    const response = yield API.get(url, { params });

    yield put({
      type: constant.WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT_ERROR,
      error,
    });
  }
}

export function* ViewWhitelistingFields(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.whitelisting_Fields;
    const response = yield API.get(url, { params });

    yield put({
      type: constant.WHITE_LISTING_FIELDS_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.WHITE_LISTING_FIELDS_LIST_INIT_ERROR, error });
  }
}

export function* ViewWhitelistingTable(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.white_list_tabel;
    const response = yield API.get(url, { params });

    yield put({ type: constant.WHITE_LISTING_TABLE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WHITE_LISTING_TABLE_INIT_ERROR, error });
  }
}

export function* ViewWhiteListAdd(payload) {
  try {
    const url = ApiUrl + EndPoints.add_white_list;
    const response = yield API.post(url, payload.generatedate);
    // modifiedCount
    if (response?.RES?.lastErrorObject.updatedExisting === true) {
      //
      if (response[0]?.data?.modifiedCount === 1) {
        swal('', 'Updated successfully !', 'success');
      } else {
        swal('', 'Created successfully !', 'success');
      }
    } else {
      swal('', 'Something Went wrong!', 'error');
    }

    yield put({ type: constant.WHITE_LIST_ADD_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WHITE_LIST_ADD_INIT_ERROR, error: '400' });
  }
}

export function* ViewWhiteListEdit(payload) {
  try {
    const url = ApiUrl + EndPoints.edit_white_list;
    const response = yield API.put(url, payload.generatedate);
    yield put({ type: constant.WHITE_LIST_EDIT_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WHITE_LIST_EDIT_INIT_ERROR, error: '400' });
  }
}

export function* DeleteUserSaga(payload) {
  const package_name = payload.package_name;
  const analysis = payload.analysis;
  const rule_name = payload.rule_name;
  const params = {
    package_name: package_name,
    analysis: analysis,
    rule_name: rule_name,
  };
  try {
    // const id = payload.id;
    let url = ApiUrl + EndPoints.edit_white_list;
    const response = yield API.delete(url, { data: params });
    if (response?.data?.lastErrorObject?.updatedExisting === true) {
      swal('', 'Deleted  successfully !', 'success');
    } else {
      swal('', 'Something Went wrong!', 'error');
    }
    yield put({
      type: constant.DELETE_USER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({ type: constant.DELETE_USER_ERROR, error: '400' });
  }
}

export default function* WhiteListSaga() {
  yield takeLatest(
    constant.WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT,
    ViewWhiteListingFraudCategory
  );
  yield takeLatest(
    constant.WHITE_LISTING_FIELDS_LIST_INIT,
    ViewWhitelistingFields
  );
  yield takeLatest(constant.WHITE_LISTING_TABLE_INIT, ViewWhitelistingTable);
  yield takeLatest(constant.WHITE_LIST_ADD_INIT, ViewWhiteListAdd);
  yield takeLatest(constant.WHITE_LIST_EDIT_INIT, ViewWhiteListEdit);
  yield takeLatest(constant.DELETE_USER_INIT, DeleteUserSaga);
}
