import { takeLatest, put } from 'redux-saga/effects';
import CONST from '../constants/ExternalEmbededConst';
// import DashboardServices from "../services/DashboardServices";
import swal from 'sweetalert';

import { API } from '../setupAxios';
import { EndPoints, ApiUrl } from '../helpers/Endpoints';

export function* getExternalEmbededUrl({ payload }) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = payload;
    const url = ApiUrl + EndPoints.EXTERNAL_EMBEDED;
    const response = yield API.get(url, { params });

    yield put({ type: CONST.SUCCESS, response });
  } catch (error) {
    yield put({ type: CONST.ERROR, error });
  }
}

export default function* EventSaga() {
  yield takeLatest(CONST.INIT, getExternalEmbededUrl);
}
