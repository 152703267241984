import { EndPoints, ApiUrl } from "../helpers/Endpoints";
import { TOKEN } from "../../app/utils/const";
const PackageService = {};
const headers = {
  // Accept: '*/*',
  // authorization: token,
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  authorization: TOKEN.token,
};

PackageService.ViewPackageDetails = function(payload, page, limit) {
  let url =
    ApiUrl +
    EndPoints.getpackage +
    "?page=" +
    payload.page +
    "&limit=" +
    payload.limit;
  return fetch(url, { method: "GET", mode: "cors", headers })
    .then(async (response) => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      // console.error("There was an error!", error);
    });
};

PackageService.ViewGetonePackage = function(payload) {
  let url = ApiUrl + EndPoints.getpackage + "/" + payload.id;
  // console.log(url, "hopeso");
  // console.log(payload,"89808088")
  // console.log(url, 'packageurl');
  return fetch(url, { method: "GET", mode: "cors", headers })
    .then(async (response) => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

PackageService.ViewPackageSave = function(values) {
  // console.log(values, "see");
  let url = ApiUrl + EndPoints.savepackage;
  // console.log(url, "save----");

  return fetch(url, {
    method: "POST",
    body: JSON.stringify(values),

    mode: "cors",
    headers,
  })
    .then(async (response) => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

PackageService.ViewPackageUpdate = function(params, id, values) {
  let url = ApiUrl + EndPoints.updatepackage + "/" + params.id;
  //   console.log(url, "update");
  // console.log(params,"servicepackageservice1111111")
  // console.log(params.id,"servicepackageservice222222222")
  // console.log(params.values,"servicepackageservice3333333")
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(params.values),

    mode: "cors",
    headers,
  })
    .then(async (response) => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

PackageService.ViewPackageDelete = function(id) {
  let url = ApiUrl + EndPoints.deletepackage + "/" + id;
  return fetch(url, {
    method: "DELETE",

    mode: "cors",
    headers,
  })
    .then(async (response) => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};
export default PackageService;
