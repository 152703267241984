export const MTRACKREPORT_INIT = 'MTRACKREPORT_INIT';
export const MTRACKREPORT_INIT_SUCCESS = 'MTRACKREPORT_INIT_SUCCESS';
export const MTRACKREPORT_INIT_ERROR = 'MTRACKREPORT_INIT_ERROR';

export const MTRACKREPORT_EVENT_DOWNLOAD_INIT = 'MTRACKREPORT_EVENT_DOWNLOAD_INIT';

// Event


export const MTRACKEVENTREPORT_INIT = 'MTRACKEVENTREPORT_INIT';
export const MTRACKEVENTREPORT_INIT_SUCCESS = 'MTRACKEVENTREPORT_INIT_SUCCESS';
export const MTRACKEVENTREPORT_INIT_ERROR = 'MTRACKEVENTREPORT_INIT_ERROR';

export const MTRACKREPORT_DOWNLOAD_INIT = 'MTRACKREPORT_DOWNLOAD_INIT';
export const MTRACKREPORT_DOWNLOAD_SUCCESS = 'MTRACKREPORT_DOWNLOAD_SUCCESS';
export const MTRACKREPORT_DOWNLOAD_ERROR = 'MTRACKREPORT_DOWNLOAD_ERROR';
export const MTRACKREPORT_DOWNLOAD_CLEAR = 'MTRACKREPORT_DOWNLOAD_CLEAR';


//CAMPAIGN

export const MTRACKCAMPAIGNREPORT_INIT = 'MTRACKCAMPAIGNREPORT_INIT';
export const MTRACKCAMPAIGNREPORT_INIT_SUCCESS = 'MTRACKCAMPAIGNREPORT_INIT_SUCCESS';
export const MTRACKCAMPAIGNREPORT_INIT_ERROR = 'MTRACKCAMPAIGNREPORT_INIT_ERROR';
// 
export const MTRACKCAMPAIGNREPORT_DOWNLOAD_INIT = 'MTRACKCAMPAIGNREPORT_DOWNLOAD_INIT';
export const MTRACKCAMPAIGNREPORT_DOWNLOAD_SUCCESS = 'MTRACKCAMPAIGNREPORT_DOWNLOAD_SUCCESS';
export const MTRACKCAMPAIGNREPORT_DOWNLOAD_ERROR = 'MTRACKCAMPAIGNREPORT_DOWNLOAD_ERROR';
export const MTRACKCAMPAIGNREPORT_DOWNLOAD_CLEAR = 'MTRACKCAMPAIGNREPORT_DOWNLOAD_CLEAR';

//Postback

export const MTRACKPOSTBACKREPORT_INIT = 'MTRACKPOSTBACKREPORT_INIT';
export const MTRACKPOSTBACKREPORT_INIT_SUCCESS = 'MTRACKPOSTBACKREPORT_INIT_SUCCESS';
export const MTRACKPOSTBACKREPORT_INIT_ERROR = 'MTRACKPOSTBACKREPORT_INIT_ERROR';

export const MTRACKPOSTBACKREPORT_DOWNLOAD_INIT = 'MTRACKPOSTBACKREPORT_DOWNLOAD_INIT';
export const MTRACKPOSTBACKREPORT_DOWNLOAD_SUCCESS = 'MTRACKPOSTBACKREPORT_DOWNLOAD_SUCCESS';
export const MTRACKPOSTBACKREPORT_DOWNLOAD_ERROR = 'MTRACKPOSTBACKREPORT_DOWNLOAD_ERROR';
export const MTRACKPOSTBACKREPORT_DOWNLOAD_CLEAR = 'MTRACKPOSTBACKREPORT_DOWNLOAD_CLEAR';