import React, { useState } from "react";
import { Row, Col, Tab, Nav, Button, Card } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { CircularProgress } from "@material-ui/core";
import "./styles.scss";
import PageLoader from "../../app/PageLoader";
import { uploadFile, scanFile } from "./api";
import VideoPage from "./video";
import AudioUpload from "./audio";
import KeywordPage from "./keyword";
import { AUTH_DATA } from "../../redux/MtrackIt/const";
import { getLocalStorage } from "../../Utils/const";
import { showToast, checkSizeInMB } from "./utils";

const RelevancyDemo = () => {
  const [imageResponse, setImageResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [imageStatus, setImageStatus] = useState(null);
  const [imageDetails, setImageDetails] = useState({});
  const [presignedUrl, setPresignedUrl] = useState("");
  const [selectedFileURL, setSelectedFileURL] = useState(null);

  const submitImage = () => {
    setLoading(true);

    let toastID = toast.loading("File is getting scanned. Please wait!", {
      duration: 3000,
      icon: <CircularProgress size={24} />,
    });

    uploadFile(imageDetails)
      .then((presignedUrl) => {
        if (!presignedUrl) {
          setLoading(false);
          return;
        }

        setPresignedUrl(presignedUrl);

        scanFile(presignedUrl)
          .then((result) => {
            setImageStatus(result?.statusCode);
            if (result?.statusCode === 429) {
              toast.error(result?.body, { duration: 3000 });
              return;
            }
            if (result?.statusCode === 500) {
              toast.error("Something went wrong!", { duration: 3000 });
              return;
            }
            if (result?.statusCode === 422) {
              toast.error(result?.body, { duration: 3000 });
              return;
            }

            setImageResponse(result?.body?.result || {});
          })
          .catch(() => setLoading(false))
          .finally(() => {
            setLoading(false);
            toast.dismiss(toastID);
          });
      })
      .catch(() => setLoading(false));
  };

  const authvalue = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));

  const renderImageOrText = (key, value) => {
    // Exclude rendering if key is "text" or value is an empty object
    if (
      key === "text" ||
      (typeof value === "object" && Object.keys(value).length === 0)
    ) {
      return null;
    }

    if (typeof value === "object") {
      // Check if the inner object has non-empty values
      const hasNonEmptyValues = Object.values(value).some((val) =>
        Array.isArray(val) ? val.length > 0 : val !== null && val !== undefined
      );

      if (!hasNonEmptyValues) {
        return null; // Skip rendering if all inner values are empty
      }

      return (
        <div>
          <b>
            <p>{key}</p>
          </b>
          <ul>
            {Object.entries(value).map(([innerKey, innerValue]) => {
              // Skip rendering if innerValue is an empty array or null/undefined
              if (
                (Array.isArray(innerValue) && innerValue.length === 0) ||
                innerValue === null ||
                innerValue === undefined
              ) {
                return null;
              }

              // Join array elements with commas if there are multiple elements
              if (Array.isArray(innerValue) && innerValue.length > 1) {
                innerValue = innerValue.join(", ");
              }

              return (
                <li key={innerKey}>
                  <b>{innerKey}:</b> {innerValue}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else if (typeof value === "string" && value.startsWith("https://")) {
      // Display the tag before the image
      return (
        <div>
          <b style={{ padding: "5px" }}>
            <p>{key}</p>
          </b>
          <img
            src={value}
            alt={`Image ${key}`}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              height: "auto",
              width: "auto",
            }}
          />
        </div>
      );
    } else {
      return <p>{value}</p>;
    }
  };

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <div
        style={{
          backgroundImage: "linear-gradient(126deg, #5b1689, #8743b4)",
          color: "white",
          textAlign: "center",
          paddingTop: "10px",
          // margin: "5px",
        }}
      >
        <h3>Brand Hygiene: AI FOR UNDERSTANDING CONTEXT AND CONTENT</h3>
        <h6 style={{ textAlign: "left", marginLeft: "20px" }}>
          Enhance your understanding and targeting through content moderation,
          object & scene tagging, logo detection, text identification,
          demographic information and custom tailored AI models as per
          requirement
        </h6>
      </div>

      <Row className="mr-0 ml-0">
        <Col sm={12} md={12} lg={12} xl={12} className="pl-0 pr-0">
          <div className="topbar-hygiene">
            <h1>Brand Hygiene</h1>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="first">
                  Image <i className="fa-solid fa-image ml-2"></i>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">
                  Video <i className="fa-solid fa-video ml-2"></i>{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth">
                  Audio <i className="fa-solid fa-headphones ml-2"></i>{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">
                  Keyword <i className="fa-solid fa-key ml-2"></i>{" "}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
        <Col sm={12} xs={12} md={12} lg={12} xl={12}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div
                className="d-flex mb-0 flex-column flex-md-row"
                style={{
                  borderRadius: "1rem",
                  padding: "1rem",
                  minHeight: "70vh",
                }}
              >
                <Col
                  lg={6}
                  md={6}
                  className="d-flex justify-content-center align-items-center fixed-column"
                >
                  {presignedUrl?.length > 0 ? (
                    <img
                      src={presignedUrl}
                      alt=""
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        height: "auto",
                        width: "auto",
                      }}
                    />
                  ) : (
                    <h3>Please upload image</h3>
                  )}
                </Col>

                <Col lg={6} md={6}>
                  <h6>
                    <b>In-Image content detection :</b>
                    <br />
                  </h6>
                  <p>
                    Identify objects, subjects, scene settings, harmful visual
                    content, demographic information such as age, gender,
                    emotions, brand logos through our AI models in real time.
                    <br />
                    <b>Note : File size should be below 5MB</b>
                  </p>

                  <div className="d-flex p-5 flex-column">
                    <div className="d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="file"
                        style={{ marginRight: "1rem", marginBottom: "0" }}
                      >
                        Choose Image File:
                      </label>
                      <input
                        type="file"
                        id="file"
                        name="file"
                        accept="image/*"
                        onChange={(e) => {
                          setPresignedUrl("");
                          setImageStatus(null);
                          setImageResponse({});
                          const imageDetails = e?.currentTarget?.files[0];
                          setSelectedFileURL(
                            URL.createObjectURL(e.target.files[0])
                          );
                          let sizeOfFile = checkSizeInMB(imageDetails);
                          if (sizeOfFile > 5) {
                            showToast(
                              "Size is too large. upload an image less than 5 mb!"
                            );
                            return;
                          }
                          setImageDetails(imageDetails);
                        }}
                      />
                      <Button
                        variant="primary"
                        type="submit"
                        className="rounded"
                        style={{ width: "18rem" }}
                        onClick={submitImage}
                        disabled={!selectedFileURL}
                      >
                        Upload Image <i className="fa-solid fa-image ml-2"></i>
                      </Button>
                    </div>

                    {loading ? (
                      <PageLoader />
                    ) : imageStatus === 200 ? (
                      <Col xl={12} md={12} lg={12}>
                        <Card className="gutter-b-custom mt-3">
                          <Card.Body className="pt-0 pb-0 InvoiceBody p-6 detailsCardBh ">
                            {Object.entries(imageResponse).map(
                              ([key, value]) => (
                                <div key={key}>
                                  {renderImageOrText(key, value)}
                                </div>
                              )
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <VideoPage />
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <KeywordPage />
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
              <AudioUpload />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
      <Toaster position="top-right" />
    </Tab.Container>
  );
};

export default RelevancyDemo;
