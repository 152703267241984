import React from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Button } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap/';
import { unsetLocalStorage } from '../../../../../app/utils/helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { getLocalStorage } from '../../../../../app/utils/helpers';
import { AUTH_DATA } from '../../../../../app/utils/const';
import { ApiUrl, EndPoints } from '../../../../../redux/helpers/Endpoints';

export function UserProfileDropdown() {
  const signout = async () => {
    unsetLocalStorage();
    console.log('logout is called');
    const url = ApiUrl + EndPoints.LOGOUT;
    await fetch(url, {
      headers: { authorization: localStorage.getItem('token') },
    });
    localStorage.removeItem('auth_data');
    localStorage.removeItem('dpackage');
    localStorage.removeItem('startDateValue');
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    window.location = '/';
  };

  const getauthvalue = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));

  const name = getauthvalue && getauthvalue?.name;

  return (
    // <OverlayTrigger
    //   placement="bottom"
    //   overlay={<Tooltip id="quick-panel-tooltip">User profile</Tooltip>}
    // >
    <Dropdown drop='down' alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id='dropdown-toggle-user-profile'>
        <span
          style={{ cursor: 'pointer' }}
          className='symbol symbol-35 symbol-light-success user-select-none'>
          <span className='symbol-label font-size-h5 font-weight-bold'>
            <i className='fa-sharp fa-regular fa-user userIconOrange fa-lg'></i>
          </span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className='userProfileDropdown p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'>
        <>
          <div
            className='d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top'
            style={{ background: '#A21094' }}>
            <div className='symbol bg-white-o-15 mr-3'>
              {/* <span className="symbol-label text-success font-weight-bold font-size-h3">
                  <i class="fa-sharp fa-regular fa-user userIcon fa-lg"></i>
                </span> */}
              <span className='symbol symbol-35 symbol-light-success'>
                <span className='symbol-label font-size-h5 font-weight-bold'>
                  {name?.charAt(0)}
                </span>
              </span>
            </div>
            <div className='text-white m-0 flex-grow-1 mr-3 font-size-h5'>
              Hi {name}
            </div>
          </div>
        </>

        <div className='navi navi-spacer-x-0 pt-5  d-flex justify-content-start flex-start mb-5'>
          {/* <div className="navi-footer  px-8 py-5"> */}
          <Link to='/changepassword' className='navi-item px-8'>
            <Button className='btn btn-light-primary font-weight-bold d-flex justify-content-start flex-start'>
              Change Password
            </Button>
          </Link>
          <Button
            className='btn btn-light-primary font-weight-bold d-flex justify-content-end flex-end '
            onClick={() => {
              signout();
            }}>
            Sign Out
          </Button>
        </div>
        {/* </div> */}
      </Dropdown.Menu>
    </Dropdown>
    // </OverlayTrigger>
  );
}
