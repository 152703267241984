import { takeLatest, put } from 'redux-saga/effects';
import * as constant from '../constants/Mreportconst';
import { ApiUrl, EndPoints, PythonApiUrl } from "../helpers/Endpoints";
import { API, API_DOWNLOAD_CALL } from '../setupAxios';
import { MTRACKREPORT_EVENT_DOWNLOAD_INIT } from "../constants/Mreportconst";

export function* ViewMreportSaga(payload) {
  try {
    // const response = yield call(
    //   MreportServices.ViewMreportlist,
    //   payload.reportData
    // );
    let url = ApiUrl + EndPoints.mTrackit_report;
    const params = payload.reportData;
    const response = yield API.get(url, { params });
    yield put({ type: constant.MTRACKREPORT_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MTRACKREPORT_INIT_ERROR, error });
  }
}

export function* downloadMreportEventSaga(payload) {
  try {
    // const response = yield call(
    //   MreportServices.ViewMreportlist,
    //   payload.reportData
    // );
    let url = PythonApiUrl + EndPoints.mTrackit_event_report_download;
    const params = payload.reportData;
    const response = yield API_DOWNLOAD_CALL.post(url, { ...params });
    yield put({ type: constant.MTRACKREPORT_DOWNLOAD_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MTRACKREPORT_DOWNLOAD_ERROR, error });
  }
}

export function* downloadMreportSaga(payload) {
  try {
    // const response = yield call(
    //   MreportServices.ViewMreportlist,
    //   payload.reportData
    // );
    let url = ApiUrl + EndPoints.mTrackit_report_download;
    const params = payload.reportData;
    const response = yield API_DOWNLOAD_CALL.get(url, { params });
    yield put({ type: constant.MTRACKREPORT_DOWNLOAD_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MTRACKREPORT_DOWNLOAD_ERROR, error });
  }
}

export function* ViewMreportEventSaga(payload) {
  try {
    let url = PythonApiUrl + EndPoints.mTrackit_event_report;
    const params = payload.reportData;
    const response = yield API.post(url, { ...params });
    yield put({ type: constant.MTRACKEVENTREPORT_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MTRACKEVENTREPORT_INIT_ERROR, error });
  }
}

export function* ViewMreportCampaignSaga(payload) {
  try {
    // const response = yield call(
    //   MreportServices.ViewMreportlist,
    //   payload.reportData
    // );
    let url = PythonApiUrl + EndPoints.mTrackit_campaign_report;
    const params = payload.reportData;
    console.log(params,"CAMP_PASS")
    API.post(url, { ...params }).then(r=>console.log(r,'CAMP_PASS_PROMISE')).catch(err=>{console.log(err,"CAMP_PASS_PROMISE_ERR")})
    console.log(params,"CAMP_PASS")

    const response = yield API.post(url, { ...params });
    yield put({ type: constant.MTRACKCAMPAIGNREPORT_INIT_SUCCESS, response });
  } catch (error) {
    console.log(error,"CAMP_ERR")
    yield put({ type: constant.MTRACKCAMPAIGNREPORT_INIT_ERROR, error });
  }
}

export function* downloadMreportCampaignSaga(payload) {
  try {
    // const response = yield call(
    //   MreportServices.ViewMreportlist,
    //   payload.reportData
    // );
    let url = PythonApiUrl + EndPoints.mTrackit_campaign_report_download;
    const params = payload.reportData;
    const response = yield API_DOWNLOAD_CALL.post(url, { ...params });
    yield put({ type: constant.MTRACKCAMPAIGNREPORT_DOWNLOAD_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MTRACKCAMPAIGNREPORT_DOWNLOAD_ERROR, error });
  }
}

export function* ViewMreportPostbackSaga(payload) {
  try {
    // const response = yield call(
    //   MreportServices.ViewMreportlist,
    //   payload.reportData
    // );
    let url = PythonApiUrl + EndPoints.mTrackit_postback_report;
    const params = payload.reportData;
    const response = yield API.post(url, { ...params });
    yield put({ type: constant.MTRACKPOSTBACKREPORT_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MTRACKPOSTBACKREPORT_INIT_ERROR, error });
  }
}

export function* downloadMreportPostbackSaga(payload) {
  try {
    // const response = yield call(
    //   MreportServices.ViewMreportlist,
    //   payload.reportData
    // );
    let url = PythonApiUrl + EndPoints.mTrackit_postback_report_download;
    const params = payload.reportData;
    const response = yield API_DOWNLOAD_CALL.post(url, { ...params });
    yield put({ type: constant.MTRACKPOSTBACKREPORT_DOWNLOAD_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MTRACKPOSTBACKREPORT_DOWNLOAD_ERROR, error });
  }
}
export default function* MreportSaga() {
  yield takeLatest(constant.MTRACKREPORT_INIT, ViewMreportSaga);
  yield takeLatest(constant.MTRACKEVENTREPORT_INIT, ViewMreportEventSaga);
  yield takeLatest(constant.MTRACKREPORT_EVENT_DOWNLOAD_INIT, downloadMreportEventSaga);
  yield takeLatest(constant.MTRACKREPORT_DOWNLOAD_INIT, downloadMreportSaga);
  yield takeLatest(constant.MTRACKCAMPAIGNREPORT_INIT, ViewMreportCampaignSaga);
  yield takeLatest(constant.MTRACKCAMPAIGNREPORT_DOWNLOAD_INIT, downloadMreportCampaignSaga);
  yield takeLatest(constant.MTRACKPOSTBACKREPORT_INIT, ViewMreportPostbackSaga);
  yield takeLatest(constant.MTRACKPOSTBACKREPORT_DOWNLOAD_INIT, downloadMreportPostbackSaga);
}
