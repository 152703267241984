import { put, takeLatest } from 'redux-saga/effects';
import { ApiUrl } from '../helpers/Endpoints';
import { API } from '../setupAxios';
import CONST from './const';

const Endpoint = {
  BRAND_RELEVANCY: {
    INSERT_CONFIG: {
      domain: ApiUrl,
      end: '/api/v2/br/config/insert',
      method: 'POST',
    },
    INSERT_DV_CONFIG: {
      domain: ApiUrl,
      end: '/api/v2/br/dv360/insert',
      method: 'POST',
    },
    INSERT_360_OUTPUT: {
      domain: ApiUrl,
      end: '/api/v2/br/ack/upload-output?package_name=:id',
      method: 'FORM_DATA',
    },
    INSERT_ADD_KEYWORD: {
      domain: ApiUrl,
      end: '/api/v2/br/config/update-keywords-internal/:id',
      method: 'PUT',
    },
    EDIT_CONFIG: {
      domain: ApiUrl,
      end: '/api/v2/br/config/update/:id',
      method: 'PUT',
    },
    EDIT_DV_CONFIG: {
      domain: ApiUrl,
      end: '/api/v2/br/dv360/update/:id',
      method: 'PUT',
    },
    GET_CONFIG: '/api/v2/br/config/get/',
    GET_ALL_CONFIG: '/api/v2/br/dv360/configuration_list/',
    // dashboard start
    BR_DASHBOARD_DAY_WISE: '/api/v2/br/dashboard/day-wise',
    BR_DASHBOARD_UPLOAD_DATE_WISE: '/api/v2/br/dashboard/upload-date-wise',
    BR_DASHBOARD_SLAB_WISE: '/api/v2/br/dashboard/slab-wise',
    BR_DASHBOARD_CAMPAIGN_WISE: '/api/v2/br/dashboard/campaign-wise',
    BR_DASHBOARD_TOTAL_VIDEOS: '/api/v2/br/dashboard/filter/campaign_name',
    BR_DASHBOARD_TOTAL_CAMPAIGN: '/api/v2/br/dashboard/campaign-total',
        // dashboard ends
  },
};


export function* getData(payload) {
  try {
    if (!Endpoint.BRAND_RELEVANCY[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.BRAND_RELEVANCY[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.BRAND_RELEVANCY[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.BRAND_RELEVANCY[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getDashboardData(payload) {
  try {
    if (!Endpoint.BRAND_RELEVANCY[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.data;
    const url = ApiUrl + Endpoint.BRAND_RELEVANCY[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.BRAND_RELEVANCY[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.BRAND_RELEVANCY[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

function sagaGenerator(endpoint, constant) {
  if (!endpoint) throw Error(`Endpoint not defined`);
  if (!constant) throw Error(`Constant not defined`);
  return function*(payload) {
    try {
      const params = payload.query;
      if (typeof endpoint === 'object') {
        if (!endpoint.end && !endpoint.domain)
          throw Error('Missing parameters in Endpoint.');
        const url = endpoint.domain + endpoint.end;
        switch (endpoint.method) {
          case 'GET': {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'POST': {
            const data = payload.data;
            const response = yield API.post(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'FORM_DATA': {
            const formData = new FormData();
            const id = payload.packagename;
            const putUrl = url.replace(':id', id);
            for (const [key, value] of Object.entries(payload.data)) {
                formData.append(key, value);
            }
            const response = yield API.post(putUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            yield put({
                type: constant.SUCCESS,
                id: payload.id,
                data: response,
            });
            break;
        }
          case 'POST_QUERY': {
            const params = payload.query;
            const response = yield API.post(url, {}, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'PUT': {
            const data = payload.data;
            const id = payload.key;
            const putUrl = url.replace(':id', id);
            const response = yield API.put(putUrl, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'DELETE': {
            const data = payload.data;
            const response = yield API.delete(url, { params, data });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          default: {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
          }
        }
      } else {
        const url = ApiUrl + endpoint;
        const response = yield API.get(url, { params });
        yield put({
          type: constant.SUCCESS,
          id: payload.id,
          data: response,
        });
      }
    } catch (error) {
      console.error(payload.id, error);
      yield put({
        type: constant.ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

export function* getBrandRelevancyData(payload) {
  try {
    if (!Endpoint.BRAND_RELEVANCY[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.BRAND_RELEVANCY[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.BRAND_RELEVANCY[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.BRAND_RELEVANCY[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export default function* BrandRelevancySaga() {
  yield takeLatest(
    CONST.BRAND_RELEVANCY.INSERT_CONFIG.INIT,
    sagaGenerator(
      Endpoint.BRAND_RELEVANCY.INSERT_CONFIG,
      CONST.BRAND_RELEVANCY.INSERT_CONFIG
    )
  );

  yield takeLatest(
    CONST.BRAND_RELEVANCY.INSERT_DV_CONFIG.INIT,
    sagaGenerator(
      Endpoint.BRAND_RELEVANCY.INSERT_DV_CONFIG,
      CONST.BRAND_RELEVANCY.INSERT_DV_CONFIG
    )
  );
  yield takeLatest(
    CONST.BRAND_RELEVANCY.INSERT_360_OUTPUT.INIT,
    sagaGenerator(
      Endpoint.BRAND_RELEVANCY.INSERT_360_OUTPUT,
      CONST.BRAND_RELEVANCY.INSERT_360_OUTPUT
    )
  );
  yield takeLatest(
    CONST.BRAND_RELEVANCY.EDIT_CONFIG.INIT,
    sagaGenerator(
      Endpoint.BRAND_RELEVANCY.EDIT_CONFIG,
      CONST.BRAND_RELEVANCY.EDIT_CONFIG
    )
  );
  yield takeLatest(
    CONST.BRAND_RELEVANCY.INSERT_ADD_KEYWORD.INIT,
    sagaGenerator(
      Endpoint.BRAND_RELEVANCY.INSERT_ADD_KEYWORD,
      CONST.BRAND_RELEVANCY.INSERT_ADD_KEYWORD
    )
  );
  yield takeLatest(
    CONST.BRAND_RELEVANCY.EDIT_DV_CONFIG.INIT,
    sagaGenerator(
      Endpoint.BRAND_RELEVANCY.EDIT_DV_CONFIG,
      CONST.BRAND_RELEVANCY.EDIT_DV_CONFIG
    )
  );
  yield takeLatest(CONST.BRAND_RELEVANCY.GET_ALL_CONFIG.INIT, getData);
  // dashboard Starts 
  yield takeLatest(CONST.BRAND_RELEVANCY.BR_DASHBOARD_DAY_WISE.INIT, getDashboardData);
  yield takeLatest(CONST.BRAND_RELEVANCY.BR_DASHBOARD_UPLOAD_DATE_WISE.INIT, getDashboardData);
  yield takeLatest(CONST.BRAND_RELEVANCY.BR_DASHBOARD_SLAB_WISE.INIT, getDashboardData);
  yield takeLatest(CONST.BRAND_RELEVANCY.BR_DASHBOARD_CAMPAIGN_WISE.INIT, getDashboardData);
  yield takeLatest(CONST.BRAND_RELEVANCY.BR_DASHBOARD_TOTAL_VIDEOS.INIT, getDashboardData);
  yield takeLatest(CONST.BRAND_RELEVANCY.BR_DASHBOARD_TOTAL_CAMPAIGN.INIT, getDashboardData);
}
