import * as constant from '../constants/DashboardConstants';

export const FetchTotalIncidents = dashboarddata => {
  return {
    type: constant.TOTAL_INCIDENTS,
    dashboarddata,
  };
};

export const FetchIncidentVolumes = dashboarddata => {
  return {
    type: constant.INCIDENT_VOLUMES,
    dashboarddata,
  };
};

export const FetchActivecasesbychannel = dashboarddata => {
  return {
    type: constant.ACTIVECASE_CHANNEL,
    dashboarddata,
  };
};

export const FetchSubchannel = dashboarddata => {
  return {
    type: constant.SUBCHANNEL_INIT,
    dashboarddata,
  };
};

export const FetchToptenLocation = dashboarddata => {
  return {
    type: constant.TOPTEN_LOCATION_INIT,
    dashboarddata,
  };
};

export const FetchCategorlevelcount = dashboarddata => {
  return {
    type: constant.CATEGORY_LEVEL_COUNT,
    dashboarddata,
  };
};

export const FetchPublisherlevelcount = dashboarddata => {
  return {
    type: constant.PUBLISHER_LEVEL_COUNT,
    dashboarddata,
  };
};
// export const FetchDASHBOARDChannelData = (dashboarddata) => {
//     console.log('FetchDASHBOARDChannelData s-------',dashboarddata.data.channels);
//     return {
//         type: constant.DASHBOARD_DATA,
//         dashboarddata : dashboarddata.data.channels
//     }
// };

//web

export const FetchWebTotalIncidents = dashboarddata => {
  return {
    type: constant.WEB_TOTAL_INCIDENTS,
    dashboarddata,
  };
};

export const FetchFraudReasonTrend = dashboarddata => {
  return {
    type: constant.FRAUD_REASON_TREND,
    dashboarddata,
  };
};

export const FetchFraudVisit = dashboarddata => {
  return {
    type: constant.FRAUD_VISIT,
    dashboarddata,
  };
};

export const FetchCumulativeimpressiontrend = dashboarddata => {
  return {
    type: constant.CUMULATIVE_IMPRESSION_TREND_INIT,
    dashboarddata,
  };
};

export const FetchWebToptenLocation = dashboarddata => {
  return {
    type: constant.TOPTEN_WEB_LOCATION_INIT,
    dashboarddata,
  };
};

export const FetchHighFraudTraffic = dashboarddata => {
  return {
    type: constant.HIGH_FRAUD_TRAFFIC_COUNT,
    dashboarddata,
  };
};

export const FetchTopTenPlacement = dashboarddata => {
  return {
    type: constant.TOP_TEN_PLACEMENT_COUNT,
    dashboarddata,
  };
};

export const FetchCleanPublisher = dashboarddata => {
  return {
    type: constant.TOP_FIVE_CLEAN_PUBLISHER,
    dashboarddata,
  };
};

export const FetchFraudPublisher = dashboarddata => {
  return {
    type: constant.TOP_FIVE_FRAUD_PUBLISHER,
    dashboarddata,
  };
};

export const FetchFraudTrend = dashboarddata => {
  return {
    type: constant.FRAUD_TREND,
    dashboarddata,
  };
};
export const FetchFraudTrendMonthly = dashboarddata => {
  return {
    type: constant.FRAUD_TREND_MONTHLY,
    dashboarddata,
  };
};
export const FetchVisitPublisher = dashboarddata => {
  return {
    type: constant.FRAUD_PUBLISHER_WISE,
    dashboarddata,
  };
};

//Click

export const FetchClickTotalIncidents = dashboarddata => {
  return {
    type: constant.CLICK_TOTAL_INCIDENTS,
    dashboarddata,
  };
};

export const FetchClickFraudVisit = dashboarddata => {
  return {
    type: constant.CLICK_FRAUD_VISIT,
    dashboarddata,
  };
};

export const FetchClickCleanPublisher = dashboarddata => {
  return {
    type: constant.CLICK_TOP_FIVE_CLEAN_PUBLISHER,
    dashboarddata,
  };
};

export const FetchClickFraudPublisher = dashboarddata => {
  return {
    type: constant.CLICK_TOP_FIVE_FRAUD_PUBLISHER,
    dashboarddata,
  };
};

export const FetchClickFraudTrend = dashboarddata => {
  return {
    type: constant.CLICK_FRAUD_TREND,
    dashboarddata,
  };
};
export const FetchClickFraudTrendMonthly = dashboarddata => {
  return {
    type: constant.CLICK_FRAUD_TREND_MONTHLY,
    dashboarddata,
  };
};

// EVENT

// export const FetchTotalEvent = (dashboarddata) => {
//     return {
//         type: constant.TOTAL_EVENT_LIST_INIT,
//         dashboarddata
//     }
// };

// export const FetchFraudpercent = (dashboarddata) => {
//     return {
//         type: constant.FRAUD_PERCENT_EVENT_LIST_INIT,
//         dashboarddata
//     }
// };

// export const FetchFraudEvent = (dashboarddata) => {
//     return {
//         type: constant.FRAUD_EVENT_LIST_INIT,
//         dashboarddata
//     }
// };
// export const FetchEventFraudCategory = (dashboarddata) => {
//     return {
//         type: constant.EVENT_FRAUD_CATEGORY_INIT,
//         dashboarddata
//     }
// };

// export const FetchEventCleanPublisherName = (dashboarddata) => {
//     return {
//         type: constant.EVENT_CLEAN_PUBLISHER_NAME_INIT,
//         dashboarddata
//     }
// };

// export const FetchMonthwiseFraud = (dashboarddata) => {
//   return {
//     type: constant.MONTHWISE_FRAUD_TREND_INIT,
//     dashboarddata,
//   };
// };

// export const FetchEventFraudPublisherName = (dashboarddata) => {
//     return {
//         type: constant.EVENT_FRAUD_PUBLISHER_NAME_INIT,
//         dashboarddata
//     }
// };

// export const FetchEventNotFraudPublisherName = (dashboarddata) => {
//     return {
//         type: constant.EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT,
//         dashboarddata
//     }
// };

// export const FetchTotalEventFraudEvent = (dashboarddata) => {
//     return {
//         type: constant.TOTAL_EVENT_FRAUD_EVENT_INIT,
//         dashboarddata
//     }
// };

// export const FetchFraudEventByDate = (dashboarddata) => {
//     return {
//         type: constant.FRAUD_EVENT_BY_DATE_INIT,
//         dashboarddata
//     }
// };

// export const FetchEventTable = (dashboarddata) => {
//     return {
//         type: constant.EVENT_TABEL_INIT,
//         dashboarddata
//     }
// };

//Install App Dashboard

export const FetchInstallTotalInstallData = dashboarddata => {
  return {
    type: constant.INSTALL_TOTAL_INSTALL_INIT,
    dashboarddata,
  };
};

export const FetchInstallFraudulentInstallData = dashboarddata => {
  return {
    type: constant.INSTALL_FRAUDULENT_INSTALL_INIT,
    dashboarddata,
  };
};

export const FetchInstallFraudCategoryData = dashboarddata => {
  return {
    type: constant.INSTALL_FRAUD_CATEGORY_INIT,
    dashboarddata,
  };
};

export const FetchInstallFraudSubCategoryData = dashboarddata => {
  return {
    type: constant.INSTALL_FRAUD_SUB_CATEGORY_INIT,
    dashboarddata,
  };
};

export const FetchInstallFraudCategoryListData = dashboarddata => {
  return {
    type: constant.INSTALL_FRAUD_CATEGORY_LIST_INIT,
    dashboarddata,
  };
};

export const FetchInstallCleanEventData = dashboarddata => {
  return {
    type: constant.INSTALL_CLEAN_EVENT_INIT,
    dashboarddata,
  };
};

export const FetchInstallDeviceFraudData = dashboarddata => {
  return {
    type: constant.INSTALL_CLEAN_DEVICE_COUNT_INIT,
    dashboarddata,
  };
};

export const FetchInstallFraudulentEventData = dashboarddata => {
  return {
    type: constant.INSTALL_FRAUDULENT_EVENT_INIT,
    dashboarddata,
  };
};

export const FetchInstallClickFraudDateData = dashboarddata => {
  return {
    type: constant.INSTALL_CLICK_FRAUD_DATE_INIT,
    dashboarddata,
  };
};

export const FetchInstallClickFraudPublisherData = dashboarddata => {
  return {
    type: constant.INSTALL_CLICK_FRAUD_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchInstallFraudDateData = dashboarddata => {
  return {
    type: constant.INSTALL_FRAUD_DATE_INIT,
    dashboarddata,
  };
};

export const FetchInstallFraudPublisherData = dashboarddata => {
  return {
    type: constant.INSTALL_FRAUD_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchInstallSubFraudCategoryDateData = dashboarddata => {
  return {
    type: constant.INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT,
    dashboarddata,
  };
};

export const FetchInstallSubFraudCategoryPublisherData = dashboarddata => {
  return {
    type: constant.INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchInstallSubPublisher = dashboarddata => {
  return {
    type: constant.INSTALL_SUB_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchColors = dashboarddata => {
  return {
    type: constant.COLORS_INIT,
    dashboarddata,
  };
};

//Event Dashboard

export const FetchEventTotalEventData = dashboarddata => {
  return {
    type: constant.APP_EVENT_TOTAL_EVENT_INIT,
    dashboarddata,
  };
};

export const FetchEventFraudulentEventData = dashboarddata => {
  return {
    type: constant.APP_EVENT_FRAUDULENT_EVENTS_INIT,
    dashboarddata,
  };
};

export const FetchEventFraudCategoryData = dashboarddata => {
  return {
    type: constant.APP_EVENT_FRAUD_CATEGORY_INIT,
    dashboarddata,
  };
};

export const FetchEventFraudSubCategoryData = dashboarddata => {
  return {
    type: constant.APP_EVENT_FRAUD_SUB_CATEGORY_INIT,
    dashboarddata,
  };
};

// export const FetchEventFraudCategoryListData = (dashboarddata) => {
//     return {
//         type: constant.APP_EVENT_FRAUD_CATEGORY_LIST_INIT,
//         dashboarddata
//     }
// };

export const FetchEventCleanEventData = dashboarddata => {
  return {
    type: constant.APP_EVENT_CLEAN_EVENT_INIT,
    dashboarddata,
  };
};

export const FetchEventsFraudulentEventData = dashboarddata => {
  return {
    type: constant.APP_EVENT_FRAUDULENT_EVENT_INIT,
    dashboarddata,
  };
};

export const FetchEventDeviceFraudData = dashboarddata => {
  return {
    type: constant.APP_EVENT_CLEAN_DEVICE_COUNT_INIT,
    dashboarddata,
  };
};

export const FetchEventClickFraudDateData = dashboarddata => {
  return {
    type: constant.APP_EVENT_CLICK_FRAUD_DATE_INIT,
    dashboarddata,
  };
};

export const FetchEventClickFraudPublisherData = dashboarddata => {
  return {
    type: constant.APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchEventFraudDateData = dashboarddata => {
  return {
    type: constant.APP_EVENT_FRAUD_DATE_INIT,
    dashboarddata,
  };
};

export const FetchEventFraudPublisherData = dashboarddata => {
  return {
    type: constant.APP_EVENT_FRAUD_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchEventFraudSubPublisherData = dashboarddata => {
  return {
    type: constant.APP_EVENT_FRAUD_SUB_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchEventSubFraudCategoryDateData = dashboarddata => {
  return {
    type: constant.APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT,
    dashboarddata,
  };
};

export const FetchEventSubFraudCategoryPublisherData = dashboarddata => {
  return {
    type: constant.APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT,
    dashboarddata,
  };
};

//Reengagement Event

export const FetchReengagementEventTotalEventData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_TOTAL_EVENT_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventFraudulentEventData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventFraudCategoryData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventFraudSubCategoryData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT,
    dashboarddata,
  };
};

// export const FetchReengagementEventCleanEventData = (dashboarddata) => {
//     return {
//         type: constant.APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT,
//         dashboarddata
//     }
// };

export const FetchReengagementEventNotFraudEventData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventsFraudulentEventData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventDeviceFraudData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventClickFraudDateData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventClickFraudPublisherData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventFraudDateData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventFraudPublisherData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventFraudSubPublisherData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventSubFraudCategoryDateData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT,
    dashboarddata,
  };
};

export const FetchReengagementEventSubFraudCategoryPublisherData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT,
    dashboarddata,
  };
};

//Reengagement Clicks

export const FetchReengagementClickTotalEventData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_TOTAL_CLICK_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickFraudulentEventData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickFraudCategoryData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickFraudSubCategoryData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickCleanClickData = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickCleanDeviceCount = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickfraudulentClick = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickClickFraudDate = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickClickFraudPublisherDate = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickFraudDate = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickFraudPublisher = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_FRAUD_PUBLISHER_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickSubFraudCategoryDate = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT,
    dashboarddata,
  };
};

export const FetchReengagementClickSubFraudCategoryPublisher = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT,
    dashboarddata,
  };
};

//sub publisher

export const FetchReengagementClickSubPublisher = dashboarddata => {
  return {
    type: constant.APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT,
    dashboarddata,
  };
};

//Publisher Summary

export const FetchAppGetPublisher = dashboarddata => {
  return {
    type: constant.PUBLISHER_APP_GET_INIT,
    dashboarddata,
  };
};

export const FetchAppGetDownloadPublisher = dashboarddata => {
  return {
    type: constant.PUBLISHER_APP_GET_DOWNLOAD_INIT,
    dashboarddata,
  };
};
export const ClearAppGetDownloadPublisher = () => {
  return {
    type: constant.PUBLISHER_APP_GET_DOWNLOAD_INIT_CLEAR,
  };
};
