/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector, shallowEqual } from 'react-redux';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { UserProfileDropdown } from './dropdowns/UserProfileDropdown';

export function QuickUserToggler() {
  // const { user } = useSelector((state) => state.auth, shallowEqual);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, 'extras.user.layout') === 'offcanvas',
    };
  }, [uiService]);

  return <>{!layoutProps.offcanvas && <UserProfileDropdown />}</>;
}
