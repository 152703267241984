import { fork } from 'redux-saga/effects';
import DashboardSaga from './DashboardSaga';
import CommonSaga from './CommonSaga';
import ReportSaga from './ReportSaga';
import DownloadReportSaga from './DownloadSaga';
import LoginSaga from './LoginSaga';
import ViewPublisherSaga from './Publisher';
import MtrackSaga from './MtrackSaga';
import CampaignSaga from './CampaignSaga';
import IncentDetailsSaga from './IncentDetailsSaga';
import userManagementSaga from './UserManagementSaga';
import PackageSaga from './PackageSaga';
import EventSaga from './EventSaga';
import AppPublisherSaga from './AppPubConfigSaga';
import MreportSaga from './Mreportsaga';
import UploadsSaga from './UploadsSaga';
import LandingReportSaga from './LandingReportSaga';
import WhiteListingSaga from './WhiteListingSaga';
import ExternalEmbededSaga from './WxternalEmbededSaga';
import ReconcilationSaga from './Reconcilation';
import AnalyticsDashboardSaga from './AnalyticsDashboardSaga';
import AppFraudSaga from '../AppFraud/Saga';
import ClickIntehritySaga from '../ClickIntegrity/Saga';
import RecoSaga from '../Reco/Saga';
import WebDashboardSaga from '../WebFraud/saga';
import { UserManagementSaga } from '../Common/UserManagement';
import ImpressionIntegritySaga from '../ImpressionIntegrity/Saga';
import BrandRelevancySaga from '../BrandRelevancy/Saga';

export default function* rootSaga(getState) {
  yield fork(RecoSaga);
  yield fork(AppFraudSaga);
  yield fork(DashboardSaga);
  yield fork(WebDashboardSaga);
  yield fork(WhiteListingSaga);
  yield fork(CommonSaga);
  yield fork(ReportSaga);
  yield fork(DownloadReportSaga);
  yield fork(LoginSaga);
  yield fork(ViewPublisherSaga);
  yield fork(MtrackSaga);
  yield fork(CampaignSaga);
  yield fork(IncentDetailsSaga);
  yield fork(userManagementSaga);
  yield fork(PackageSaga);
  yield fork(EventSaga);
  yield fork(AppPublisherSaga);
  yield fork(MreportSaga);
  yield fork(UploadsSaga);
  yield fork(LandingReportSaga);
  yield fork(ExternalEmbededSaga);
  yield fork(ReconcilationSaga);
  yield fork(AnalyticsDashboardSaga);
  yield fork(ClickIntehritySaga);
  yield fork(UserManagementSaga);
  yield fork(ImpressionIntegritySaga);
  yield fork(BrandRelevancySaga);
}
