import React, { useState } from "react";
import { Col, Card, Button } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import toast from "react-hot-toast";
import "./styles.scss"
// Custom components
import PageLoader from "../../app/PageLoader";
import { uploadFile, scanFile } from "./api";
import { showToast, checkSizeInMB } from "./utils";

const VideoUpload = () => {
  const [videoResponse, setVideoResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [videoStatus, setVideoStatus] = useState(null);
  const [videoDetails, setVideoDetails] = useState({});
  const [presignedUrl, setPresignedUrl] = useState("");
  const [selectedFileURL, setSelectedFileURL] = useState(null);


  const renderImageOrText = (key, value) => {
    // Exclude rendering if key is "text" or value is an empty object
    if (key === "text" || (typeof value === "object" && Object.keys(value).length === 0)) {
      return null;
    }

    if (typeof value === "object") {
      // Check if the inner object has non-empty values
      const hasNonEmptyValues = Object.values(value).some(val => Array.isArray(val) ? val.length > 0 : val !== null && val !== undefined);

      if (!hasNonEmptyValues) {
        return null; // Skip rendering if all inner values are empty
      }

      return (
        <div>
          <b><p>{key}</p></b>
          <ul>
            {Object.entries(value).map(([innerKey, innerValue]) => {
              // Skip rendering if innerValue is an empty array or null/undefined
              if (Array.isArray(innerValue) && innerValue.length === 0 || innerValue === null || innerValue === undefined) {
                return null;
              }

              // Join array elements with commas if there are multiple elements
              if (Array.isArray(innerValue) && innerValue.length > 1) {
                innerValue = innerValue.join(", ");
              }

              return (
                <li key={innerKey}>
                  <b>{innerKey}:</b> {innerValue}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else if (typeof value === "string" && value.startsWith("https://")) {
      // Display the tag before the image
      return (
        <div>
          <b style={{ padding: "5px" }}><p>{key}</p></b>
          <img
            src={value}
            alt={`Image ${key}`}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              height: "auto",
              width: "auto",
            }}
          />
        </div>
      );
    } else {
      return <p>{value}</p>;
    }
  };


  const submitVideo = () => {
    setLoading(true);

    let toastID = toast.loading("File is getting scanned. Please wait!", {
      duration: 3000,
      icon: <CircularProgress size={24} />,
    });

    uploadFile(videoDetails)
      .then((presignedUrl) => {
        if (!presignedUrl) {
          setLoading(false);
          return;
        }

        setPresignedUrl(presignedUrl);

        scanFile(presignedUrl)
          .then((result) => {
            setVideoStatus(result?.statusCode);
            if (result?.statusCode === 429) {
              showToast(result?.body);
              return;
            }
            if (result?.statusCode === 500) {
              showToast("Something went wrong!");
              return;
            }
            if (result?.statusCode === 422) {
              showToast(result?.body);
              return;
            }

            setVideoResponse(result?.body?.result || {});
          })
          .catch(() => setLoading(false))
          .finally(() => {
            setLoading(false);
            toast.dismiss(toastID);
          });
      })
      .catch(() => setLoading(false));
  };

  return (
<Card
  className="d-flex mb-0 flex-column flex-md-row"
  style={{ borderRadius: "1rem", padding: "1rem", minHeight: "70vh" }}
>
  <Col lg={6} md={6} className="d-flex justify-content-center align-items-center fixed-column">
    {presignedUrl?.length > 0 ? (
      <video
        src={presignedUrl}
        controls
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          height: "auto",
          width: "auto",
        }}
      ></video>
    ) : (
      <h3>Please upload video</h3>
    )}
  </Col>

  <Col lg={6} md={6}>
    <h6>
      <b>In-Video content detection:</b>
      <br />
    </h6>
    <p>
      Our AI powered tool deeply analyzes video to identify the context through visual content and text detection, moderation thereby assisting you through better ad placement options.
      <br />
      <b>Note: Maximum duration of video should be less than 40 seconds and file size should not exceed 39MB</b>
    </p>
    <div className="d-flex p-5 flex-column">
      <div className="d-flex align-items-center justify-content-center">
        <label
          htmlFor="file"
          style={{ marginRight: "1rem", marginBottom: "0" }}
        >
          Choose Video File:
        </label>
        <input
          type="file"
          id="file"
          name="file"
          accept="video/*"
          onChange={(e) => {
            setPresignedUrl("");
            setVideoStatus(null);
            setVideoResponse({});
            const videoDetails = e?.currentTarget?.files[0];
            setSelectedFileURL(URL.createObjectURL(e.target.files[0]));

            const video = document.createElement("video");
            let errorOccurred = false;

            video.onloadedmetadata = function () {
              video.remove();
              if (video.duration > 40) {
                errorOccurred = true;
                showToast("Please upload a video file duration less than 40 seconds");
              }
            };

            video.src = URL.createObjectURL(videoDetails);
            document.body.appendChild(video);

            video.onloadeddata = function () {
              if (errorOccurred) {
                return;
              }

              let sizeOfFile = checkSizeInMB(videoDetails);

              if (sizeOfFile > 50) {
                showToast("Size is too large. upload a video less than 50MB!");
                return;
              }

              setVideoDetails(videoDetails);
            };
          }}
        />
        <Button
          variant="primary"
          type="submit"
          className="rounded"
          style={{ width: "18rem" }}
          onClick={submitVideo}
          disabled={!selectedFileURL} 
        >
          Upload Video <i className="fa-solid fa-video ml-2"></i>
        </Button>
      </div>

      {loading ? (
        <PageLoader />
      ) : videoStatus === 200 ? (
        <Col xl={12} md={12} lg={12}>
          <Card className=" gutter-b-custom mt-3">
            <Card.Body className="pt-0 pb-0 InvoiceBody p-6 detailsCardBh">
              {Object.entries(videoResponse).map(([key, value]) => (
                <div key={key}>
                  {renderImageOrText(key, value)}
                </div>
              ))}
            </Card.Body>
          </Card>
        </Col>
      ) : null}

    </div>
  </Col>
</Card>

  );
};

export default VideoUpload;
