import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import { Input } from '../Components/Common/Form';
import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';
import Axios from 'axios';
import toast from 'react-hot-toast';
import AuthLayout from '../Components/Common/AuthLayout';

export default function SignUpPage() {
  return (
    <AuthLayout>
      <SignUpForm />
    </AuthLayout>
  );
}

const initValue = {
  name: '',
  email: '',
  password: '',
  domain_name: '',
};

const schema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  domain_name: Yup.string()
    .matches(
      /^(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/)?$/,
      'Invalid domain format (e.g., www.example.com)'
    )
    .required('Domain is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 chars required')
    .required('Password is required'),
});
function SignUpForm() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [Error, setError] = useState('');

  const redirectToTerms = () => {
    window.location.href = 'https://www.mfilterit.com/terms-and-conditions/';
  };

  async function handleSubmit(values, { setSubmitting }) {
    if (isLoading) return;

    try {
      await schema.validate(values, { abortEarly: false });
      setIsLoading(true);
      await toast.promise(createUser(values), {
        success: 'User created successfully',
        loading: 'Loading...',
        error: 'Something went wrong!',
      });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach(err => {
        validationErrors[err.path] = err.message;
      });
      toast.error('Please fix the following errors:');
      console.log(validationErrors);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  }

  async function createUser(value) {
    const body = { ...value };
    body.role = 'customer';
    // const u = new URL(value.domain_name);
    body.package_name = 'onb_' + value.domain_name;
    localStorage.setItem('enteredPackage', 'onb_' + value.domain_name);
    const url = process.env.REACT_APP_DOMAIN + '/api/onboarding/signup';
    await Axios.post(url, body)
      .then(() =>
        setTimeout(() => {
          history.push({
            pathname: '/verify-otp',
            state: { from: value.email },
          });
        }, 5000)
      )
      .catch(e => {
        setError(e?.response?.data?.error ?? '');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Formik
      initialValues={initValue}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      {({ errors, touched, handleSubmit }) => (
        <>
          <form
            className='d-flex flex-column flex-center mt-lg-0 gap-2'
            style={{ gap: '1rem', width: '50%' }}
            onSubmit={handleSubmit}>
            <div className='w-100'>
              <Field
                readOnly={false}
                name='name'
                component={Input}
                placeholder='Full Name'
                isSearchable='true'
                required='required'
                type='text'
              />
              {touched.name && errors.name && (
                <p className='text-danger'>{errors.name}</p>
              )}
            </div>
            <div className='w-100'>
              <Field
                readOnly={false}
                name='email'
                component={Input}
                placeholder='E-mail'
                isSearchable='true'
                required='required'
                type='email'
              />
              {touched.email && errors.email && (
                <p className='text-danger'>{errors.email}</p>
              )}
            </div>
            <div className='w-100'>
              <Field
                readOnly={false}
                name='password'
                component={Input}
                placeholder='Password'
                isSearchable='true'
                required='required'
                type='text'
              />
              {touched.password && errors.password && (
                <p className='text-danger'>{errors.password}</p>
              )}
            </div>
            <div className='w-100'>
              <Field
                readOnly={false}
                name='domain_name'
                component={Input}
                placeholder='Domain'
                isSearchable='true'
                required='required'
                type='domain'
              />
              {touched.domain_name && errors.domain_name && (
                <p className='text-danger'>{errors.domain_name}</p>
              )}
            </div>
            <div className='text-danger'>{Error}</div>

            <Form.Group controlId='formBasicCheckbox'>
              <Form.Check
                type='checkbox'
                label='I accept the Terms and Conditions'
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
              />
              <Button
                variant='link'
                target='_blank'
                rel='noopener noreferrer'
                className='ml-4'
                onClick={redirectToTerms}>
                View Terms and Conditions
              </Button>
            </Form.Group>
            <Button
              className='btn btn-primary font-weight-bold px-9 my-3'
              style={{
                backgroundImage: 'linear-gradient(indigo,purple,purple)',
                width: '200px',
              }}
              type='submit'
              disabled={isLoading || !termsAccepted}>
              Sign Up
            </Button>
          </form>
        </>
      )}
    </Formik>
  );
}
